import { Trans } from 'react-i18next';

import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

export function TaskConnectedFieldValue({ ruleValueConnectionField }) {
  const { getFieldByKey } = useObjectHelpers();

  const [connectionFieldKey, connectedFieldKey] = ruleValueConnectionField.split('-');

  const connectionField = getFieldByKey(connectionFieldKey);
  const connectedField = getFieldByKey(connectedFieldKey);

  if (!connectionField || !connectedField) {
    return null;
  }

  return (
    <span className="truncate rounded-md bg-subtle p-2 text-emphasis group-hover:bg-muted">
      <Trans
        i18nKey="components.data_table.right_sidebar.tasks.card.connected_value"
        values={{
          connectionField: connectionField.name,
          connectedTableField: connectedField.name
        }}
      />
    </span>
  );
}
