import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

type FormFooterProps = {
  isTargetFieldSelected: boolean;
  handleCancel: () => void;
};

export function FormFooter(props: FormFooterProps) {
  const { isTargetFieldSelected, handleCancel } = props;

  const [t] = useTranslation();
  const {
    formState: { isValid }
  } = useFormContext();

  return (
    <div className="mt-4 flex justify-end gap-2">
      <Button intent="secondary" data-testid="validation-rule-cancel-button" onClick={handleCancel}>
        {t('actions.cancel')}
      </Button>
      <Button
        type="submit"
        disabled={!isTargetFieldSelected || !isValid}
        data-testid="validation-rule-submit-button"
      >
        {t('actions.save')}
      </Button>
    </div>
  );
}
