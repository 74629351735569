import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Badge,
  Button,
  Dialog,
  Divider,
  Input,
  Label,
  Select,
  Switch,
  Tabs
} from '@knack/asterisk-react';
import { z } from 'zod';

import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { TableColumnSettingsActionButtonSection } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/TableColumnSettingsActionButtonSection';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

const columnSettingsSchema = z.object({
  header: z.string(),
  link_text: z.string().optional(),
  width: z.object({
    type: z.enum(['default', 'custom']),
    amount: z.string(),
    units: z.enum(['px', 'pt'])
  }),
  align: z.enum(['left', 'center', 'right'])
});

export type ColumnSettingsSchemaType = z.infer<typeof columnSettingsSchema>;

interface TableViewColumnSettingsContentProps {
  columnItem: TableViewColumn;
  onApply: (data: ColumnSettingsSchemaType) => void;
}

// TODO: Finish this component when receive an update from Design and Product team
// https://knackhq.slack.com/archives/C049HLP66RK/p1728292997229349?thread_ts=1728049313.128179&cid=C049HLP66RK
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ColumnSettingsOpenLinkTabs({ defaultValue, onChange }) {
  const [t] = useTranslation();

  return (
    <div className="mt-4 space-y-2">
      <Label>
        {t(
          'pages.element_settings.common.categories.data_display.field_management.open_link_new_tab'
        )}
      </Label>
      <Tabs defaultValue={defaultValue} className="w-fit" onValueChange={onChange}>
        <Tabs.List
          items={[
            { value: 'modal', children: t('keywords.modal') },
            { value: 'newPage', children: t('keywords.new_page') }
          ]}
        />
      </Tabs>
      <div className="mt-4">
        <p>This will open a new</p>
      </div>
    </div>
  );
}

function TableViewColumnSettingsContent({
  columnItem,
  onApply
}: TableViewColumnSettingsContentProps) {
  const [t] = useTranslation();
  const { sourceObject } = useActiveViewContext<TableView>();
  const isActionColumn = columnItem.type !== 'field';

  const columnItemInSettingsField = sourceObject.fields.find(
    (field) => field.key === columnItem?.field?.key
  );

  const form = useForm<ColumnSettingsSchemaType>({
    resolver: zodResolver(columnSettingsSchema),
    defaultValues: {
      header: columnItem.header,
      link_text: columnItem.link_text,
      width: columnItem.width,
      align: columnItem.align
    }
  });

  return (
    <form onSubmit={form.handleSubmit(onApply)}>
      <Dialog.MainContent>
        <Dialog.Title>
          {t(
            'pages.element_settings.common.categories.data_display.field_management.field_column_properties'
          )}
        </Dialog.Title>
        <Dialog.Description>
          {columnItemInSettingsField?.name || columnItem.header}
        </Dialog.Description>
        <Divider className="my-4" />
        <BuilderAccordion>
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.common.categories.data_display.field_management.column_label'
            )}
            isDefaultOpen
          >
            <Input className="text-sm" {...form.register('header')} />
          </BuilderAccordion.Item>

          {!isActionColumn && (
            <BuilderAccordion.Item
              label={t(
                'pages.element_settings.common.categories.data_display.field_management.grouping'
              )}
              isDefaultOpen
              className="mt-4"
            >
              <div className="flex items-center gap-2">
                <Label className="flex items-center gap-1 text-subtle">
                  <Switch disabled />
                  {t(
                    'pages.element_settings.common.categories.data_display.field_management.group_field_value'
                  )}
                </Label>
                <Badge>{t('keywords.coming_soon')}</Badge>
              </div>
            </BuilderAccordion.Item>
          )}
          {isActionColumn && (
            <TableColumnSettingsActionButtonSection
              columnItem={columnItem}
              register={form.register}
            />
          )}
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.common.categories.data_display.field_management.display_rules'
            )}
            isDefaultOpen
          >
            <Badge>{t('keywords.coming_soon')}</Badge>
          </BuilderAccordion.Item>
          <BuilderAccordion.Item label={t('keywords.width')} isDefaultOpen>
            <Controller
              name="width"
              control={form.control}
              render={({ field }) => (
                <Tabs
                  defaultValue={field.value.type}
                  className="w-40"
                  onValueChange={(val) => field.onChange({ ...field.value, type: val })}
                >
                  <Tabs.List
                    items={[
                      { value: 'default', children: t('keywords.default') },
                      { value: 'custom', children: t('keywords.custom') }
                    ]}
                  />
                  <Tabs.Content value="custom" className="text-default">
                    <div className="flex w-full items-center gap-2">
                      <Input className="w-1/2 text-sm" {...form.register('width.amount')} />

                      <Select
                        value={field.value.units}
                        onValueChange={(val) => field.onChange({ ...field.value, units: val })}
                      >
                        <Select.Trigger className="w-1/2" />
                        <Select.Content className="min-w-[100px]">
                          <Select.Item value="px">px</Select.Item>
                          <Select.Item value="pt">%</Select.Item>
                        </Select.Content>
                      </Select>
                    </div>
                  </Tabs.Content>
                </Tabs>
              )}
            />
          </BuilderAccordion.Item>
          <BuilderAccordion.Item
            label={t(
              'pages.element_settings.common.categories.data_display.field_management.alignment'
            )}
            isDefaultOpen
          >
            <Controller
              name="align"
              control={form.control}
              render={({ field }) => (
                <Tabs
                  defaultValue={field.value}
                  className="w-[200px]"
                  onValueChange={(val) => field.onChange(val)}
                >
                  <Tabs.List
                    items={[
                      { value: 'left', children: t('keywords.left') },
                      { value: 'center', children: t('keywords.center') },
                      { value: 'right', children: t('keywords.right') }
                    ]}
                  />
                </Tabs>
              )}
            />
          </BuilderAccordion.Item>
        </BuilderAccordion>
      </Dialog.MainContent>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button intent="secondary">{t('actions.cancel')}</Button>
        </Dialog.Close>
        <Button type="submit" intent="primary">
          {t('actions.apply')}
        </Button>
      </Dialog.Footer>
    </form>
  );
}

export function TableViewColumnSettings() {
  const { view } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();

  const { isViewInputEditMode, setIsViewInputEditMode, viewInputId } =
    useViewInputEditModeContext();

  const columnItemInSettings = view.columns.find((col) => col.id === viewInputId);

  const updateTableViewColumn = (column: TableViewColumn) => {
    const newColumns = view.columns.map((col) => {
      if (col.id === column.id) {
        return column;
      }

      return col;
    });

    updateViewSchema({
      columns: newColumns
    });
  };

  const handleSaveTableColumnSettings = (data: ColumnSettingsSchemaType) => {
    if (!columnItemInSettings) {
      return;
    }

    const newColumnSettings = {
      ...columnItemInSettings,
      ...data
    };

    updateTableViewColumn(newColumnSettings);
    setIsViewInputEditMode(false);
  };

  if (!columnItemInSettings) {
    return null;
  }

  return (
    <Dialog open={isViewInputEditMode} onOpenChange={setIsViewInputEditMode}>
      <Dialog.Content className="text-sm" width="sm">
        <TableViewColumnSettingsContent
          columnItem={columnItemInSettings}
          onApply={handleSaveTableColumnSettings}
        />
      </Dialog.Content>
    </Dialog>
  );
}
