import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  Input,
  Label,
  MultiSelect,
  type MultiSelectOption
} from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderPageKey, type PageType } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { PAGE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { getDropdownMenuOptions } from '@/pages/pages/page-tree/getDropdownMenuOptions';

interface NewFormProps {
  pageType: PageType;
  pageKey?: BuilderPageKey;
  onSubmitCallback?: () => void;
  onCancel?: () => void;
}

export function NewPageForm({ pageType, pageKey, onSubmitCallback, onCancel }: NewFormProps) {
  const [t] = useTranslation();
  const { setNewPageKey } = useLeftPanelContext();
  const { createMutation } = usePageMutation();
  const { nonUserPages, hasAppSingleLoginPage, appSingleLoginPage } = useLeftPanelContext();
  const dropDownMenuOptions = getDropdownMenuOptions({
    allPages: nonUserPages,
    appSingleLoginPage
  });
  const selectedPagesForDropdown = nonUserPages?.filter((page) => page.menuPageKey === pageKey);
  const [selectedPages, setSelectedPages] = useState<MultiSelectOption[]>(
    selectedPagesForDropdown?.map((page) => ({
      label: page.name,
      key: page.key,
      value: page.id
    })) || []
  );

  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    pageName: z.string().min(1, t('pages.dialogs.error_page_name_required')),
    pageParentSlug: z.string().optional()
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pageName: pageKey ? nonUserPages?.find((page) => page.key === pageKey)?.name : '',
      pageParentSlug: ''
    }
  });

  function onSubmit() {
    const parentSlug = getValues('pageParentSlug');
    const isAuthenticated = nonUserPages?.find(
      (page) => page.slug === parentSlug
    )?.requiresAuthentication;
    const menuPages = selectedPages.map((page) => page.key as BuilderPageKey);

    const newPage = {
      type: pageType,
      name: getValues('pageName'),
      menuPages,
      isAuthenticated,
      parentSlug: parentSlug || null
    };

    if (hasAppSingleLoginPage && appSingleLoginPage) {
      newPage.parentSlug = appSingleLoginPage.slug;
    }

    createMutation.mutate(newPage, {
      onSuccess: (response) => {
        setNewPageKey(response.scene.key);
        if (onSubmitCallback) {
          onSubmitCallback();
        }
      }
    });
  }

  return (
    <Form
      data-testid="new-page-form"
      onSubmit={handleSubmit(onSubmit)}
      className="mt-2 flex flex-col gap-4"
    >
      <Form.Section>
        <Input
          data-testid="new-page-input"
          id="new-page-input"
          {...register('pageName')}
          placeholder={t(`pages.page_tree.new_${pageType}_name`)}
          maxLength={PAGE_NAME_MAX_CHAR_LENGTH}
        />
        {pageType === 'menu' && (
          <div className="mt-2">
            <Label className="text-sm">{t('pages.dialogs.new_menu.select_pages')}</Label>
            <div className="mt-2">
              <MultiSelect
                id="new-menu-pages"
                maxVisibleChips={3}
                shouldRenderInline
                placeholder={t('pages.page_tree.select_pages')}
                options={dropDownMenuOptions}
                selectedOptions={selectedPages}
                isSearchEnabled
                contentClassName="z-50"
                onSelectOptions={setSelectedPages}
              />
            </div>
          </div>
        )}
      </Form.Section>
      <div className="mt-4 flex justify-end gap-2" data-testid="new-page-dialog-footer">
        {onCancel && (
          <Button onClick={onCancel} intent="minimal" data-testid="new-page-button-cancel">
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          disabled={!isValid}
          type="submit"
          isLoading={createMutation.isPending}
          data-testid="new-page-submit-button"
        >
          {t('actions.submit')}
        </Button>
      </div>
    </Form>
  );
}
