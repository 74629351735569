import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { ReportView, StackingType } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { Contrast } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/Contrast';
import { LegendPlacement } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/LegendPlacement';
import { ShowLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowLabels';
import { ShowTotal } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowTotal';
import { TiltLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/TiltLabels';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function AreaChartAppearanceSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  const stackingOptions: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.none')}</span>,
      value: 'none'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.normal')}</span>,
      value: 'normal'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.percent')}</span>,
      value: 'percent'
    }
  ];

  return (
    <>
      <div className="mb-4">
        <div className="mb-2 text-sm">
          {t('pages.element_settings.report.categories.general.stacking')} (Coming Soon)
        </div>
        <Tabs
          defaultValue={view.rows[0].reports[0].layout.stacking}
          onValueChange={(value) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].layout.stacking = value as StackingType;
            updateViewSchema(newSchema);
          }}
        >
          <Tabs.List items={stackingOptions} shouldDisableResponsive />
        </Tabs>
      </div>
      <TiltLabels />
      <ShowLabels />
      <ShowTotal />
      <Contrast />
      <LegendPlacement />
    </>
  );
}
