import { type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type ListView, type ListViewColumn } from '@/types/schema/views/ListView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from './generateNewViewKey';

const NEW_LIST_COLUMN_LIMIT = 7;
const NEW_LIST_ROWS_PER_PAGE = '25';

const baseListViewSchema: Partial<ListView> = {
  type: 'list',
  description: '',
  groups: [],
  inputs: [],
  links: [],
  preset_filters: [],
  menu_filters: [],
  label_format: 'left',
  layout: 'full',
  list_layout: 'full',
  rows_per_page: NEW_LIST_ROWS_PER_PAGE,
  keyword_search: true,
  keyword_search_fields: 'view',
  filter_fields: 'view',
  filter_type: 'fields',
  allow_exporting: false,
  allow_preset_filters: false,
  allow_limit: true,
  hide_fields: false,
  rules: {
    fields: []
  }
};

const baseListViewColumnSchema: Partial<ListViewColumn> = {
  conn_separator: '',
  conn_link: '',
  link_type: 'text',
  link_text: '',
  link_field: '',
  link_design_active: false,
  icon: {
    icon: '',
    align: 'left'
  },
  img_gallery: '',
  show_map: false,
  map_width: 400,
  map_height: 300,
  value: '',
  copy: '',
  format: {
    label_custom: false,
    label_format: 'left',
    styles: []
  }
};

function generateListViewColumns({
  viewSourceObject,
  viewSourceSchema
}: {
  viewSourceObject: KnackObject;
  viewSourceSchema: BuilderViewSourceSchema;
}) {
  const listColumns: ListViewColumn[] = [];

  let addedColumnsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (addedColumnsCount >= NEW_LIST_COLUMN_LIMIT) {
      return;
    }

    const isSourceConnectionField =
      field.type === 'connection' &&
      viewSourceSchema.parent_source?.object &&
      field.relationship.object === viewSourceSchema.parent_source.object;
    const isAuthUserImmutable = viewSourceSchema.authenticated_user && field.immutable;

    if (isSourceConnectionField || field.type === 'password' || field.user || isAuthUserImmutable) {
      return;
    }

    listColumns.push({
      ...baseListViewColumnSchema,
      id: field.key,
      type: 'field',
      key: field.key,
      name: field.name
    } satisfies Partial<ListViewColumn> as ListViewColumn);

    addedColumnsCount += 1;
  });

  return listColumns;
}

export function getListViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const viewTitle =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const listViewSchema: Partial<ListView> = {
    ...baseListViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    columns: [
      {
        groups: [
          {
            columns: [
              generateListViewColumns({
                viewSourceObject: viewSource.object,
                viewSourceSchema
              })
            ]
          }
        ],
        width: 100
      }
    ]
  };

  return listViewSchema;
}
