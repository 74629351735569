import { Trans, useTranslation } from 'react-i18next';

import {
  type AddressFieldConditionalRuleValue,
  type AddressFieldConditionalRuleValues,
  type ConnectionFieldConditionalRuleValue,
  type DateTimeFieldConditionalRuleValue,
  type DateTimeFieldConditionalRuleValues,
  type EmailFieldConditionalRuleValue,
  type LinkFieldConditionalRuleValue,
  type NameFieldConditionalRuleValue,
  type SignatureFieldConditionalRuleValue,
  type TimerFieldConditionalRuleValue
} from '@/types/schema/fields';
import { type ConditionalRuleValues, type KnackField } from '@/types/schema/KnackField';
import { useAssetInfoQuery } from '@/hooks/api/queries/useAssetInfoQuery';
import { DAYS_OF_THE_WEEK } from '@/utils/constants';
import { getBooleanFieldLabel } from '@/utils/fields';
import { getDateTimeConditionalRuleActionValue } from '@/utils/rules/getDateTimeConditionalRuleActionValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface ConditionalRuleActionValueProps {
  ruleValues: ConditionalRuleValues;
  field: KnackField;
  tableFields: KnackField[];
  className?: string;
}

export function ConditionalRuleActionValue({
  ruleValues,
  field,
  tableFields,
  className
}: ConditionalRuleActionValueProps) {
  const [t] = useTranslation();
  const { value: ruleValue, type: ruleType, input: ruleInput } = ruleValues;

  // If the field is an image or file, we need to fetch the asset info
  const { data: assetInfo, isLoading } = useAssetInfoQuery({
    assetId: ruleValue as string,
    enabled:
      (field.type === 'image' || field.type === 'file') &&
      ruleType !== 'record' &&
      typeof ruleValue === 'string'
  });

  if (isLoading) {
    return <InlineKnackValue value={t('keywords.loading')} />;
  }

  if (assetInfo) {
    if (field.type === 'file') {
      return <InlineKnackValue value={assetInfo.filename} />;
    }
    if (field.type === 'image') {
      return <img className="h-10 w-10" src={assetInfo.thumb_url} alt={assetInfo.filename} />;
    }
  }

  // If the rule type is `record`, we just need to get the field and display it
  if (ruleType === 'record') {
    const valueField = tableFields.find((f) => f.key === ruleInput);
    if (!valueField) return null;

    return (
      <InlineKnackField
        fieldType={valueField.type}
        fieldName={valueField.name}
        className={className}
      />
    );
  }

  switch (field.type) {
    case 'boolean': {
      // This is necessary to account for a schema bug where `false` boolean values are stored as empty strings in some scenarios
      if (typeof ruleValue === 'string') {
        return (
          <InlineKnackValue
            value={getBooleanFieldLabel(field.format, false)}
            className={className}
          />
        );
      }
      const booleanValue = ruleValue as boolean;
      return (
        <InlineKnackValue
          value={getBooleanFieldLabel(field.format, booleanValue)}
          className={className}
        />
      );
    }
    case 'email': {
      const emailValue = ruleValue as EmailFieldConditionalRuleValue;
      return <InlineKnackValue value={emailValue.email} className={className} />;
    }
    case 'name': {
      const { first, last, title } = ruleValue as NameFieldConditionalRuleValue;
      return <InlineKnackValue value={`${title || ''} ${first || ''} ${last || ''}`.trim()} />;
    }
    case 'date_time': {
      const dateTimeRuleType = ruleType as DateTimeFieldConditionalRuleValues['type'];
      const dateTimeRuleValue = ruleValue as DateTimeFieldConditionalRuleValue;

      if (dateTimeRuleType === 'current_date') {
        return <InlineKnackValue value={t('attributes.field_labels.date_time.current_date')} />;
      }

      let repeatValue: JSX.Element | null = null;
      if (dateTimeRuleValue.repeat) {
        const {
          frequency,
          interval,
          endson,
          end_date: endDate,
          end_count: endCount
        } = dateTimeRuleValue.repeat;
        const repeatBy = frequency === 'monthly' ? dateTimeRuleValue.repeat.repeatby : undefined;

        let ActiveDaysIfWeekly: JSX.Element | null = null;

        if (frequency === 'weekly') {
          const { SU, MO, TU, WE, TH, FR, SA } = dateTimeRuleValue.repeat;
          const daysActive = [
            SU && DAYS_OF_THE_WEEK[0],
            MO && DAYS_OF_THE_WEEK[1],
            TU && DAYS_OF_THE_WEEK[2],
            WE && DAYS_OF_THE_WEEK[3],
            TH && DAYS_OF_THE_WEEK[4],
            FR && DAYS_OF_THE_WEEK[5],
            SA && DAYS_OF_THE_WEEK[6]
          ].filter(Boolean);
          const daysActiveFormatted = daysActive.join(', ');

          ActiveDaysIfWeekly = (
            <>
              {', '}
              <Trans i18nKey="attributes.field_labels.date_time.repeats_on_days">
                on <strong>{{ days: daysActiveFormatted } as never}</strong>
              </Trans>
            </>
          );
        }

        repeatValue = (
          <>
            .{' '}
            <Trans
              i18nKey={`attributes.field_labels.date_time.repeats_${frequency}`}
              count={Number(interval)}
            >
              Repeats every <strong>{{ interval } as never}</strong>
              {{ frequency }}
            </Trans>
            {ActiveDaysIfWeekly}
            {repeatBy && (
              <>
                {', '}
                <Trans i18nKey={`attributes.field_labels.date_time.repeats_on_${repeatBy}`}>
                  on <strong>{{ repeatBy } as never}</strong>
                </Trans>
              </>
            )}
            {endson === 'date' && (
              <>
                {', '}
                <Trans i18nKey="attributes.field_labels.date_time.repeat_ends_on_date">
                  and ends on <strong>{{ date: endDate } as never}</strong>
                </Trans>
              </>
            )}
            {endson === 'limit' && (
              <>
                {', '}
                <Trans
                  i18nKey="attributes.field_labels.date_time.repeat_ends_after"
                  count={Number(endCount)}
                >
                  and ends on <strong>{{ count: endCount } as never}</strong>
                </Trans>
              </>
            )}
            {endson === 'never' && (
              <>
                {', '}
                <Trans i18nKey="attributes.field_labels.date_time.repeat_ends_never" />
              </>
            )}
            .
          </>
        );
      }

      return (
        <>
          <InlineKnackValue
            value={getDateTimeConditionalRuleActionValue(
              ruleValue as DateTimeFieldConditionalRuleValue
            )}
          />
          {repeatValue}
        </>
      );
    }
    case 'signature': {
      const { svg } = ruleValue as SignatureFieldConditionalRuleValue;
      return <InlineKnackValue value={svg} asHtml className={className} />;
    }
    case 'link': {
      const { url } = ruleValue as LinkFieldConditionalRuleValue;
      return <InlineKnackValue value={url} />;
    }
    case 'rating': {
      const ratingValue = t('attributes.field_labels.rating.stars', { count: ruleValue as number });
      return <InlineKnackValue value={ratingValue} />;
    }
    case 'address': {
      const addressRuleType = ruleType as AddressFieldConditionalRuleValues['type'];
      if (addressRuleType === 'current_location') {
        return (
          <InlineKnackValue value={t('attributes.field_labels.address.user_current_location')} />
        );
      }
      const { street, street2, city, state, zip, latitude, longitude } =
        ruleValue as AddressFieldConditionalRuleValue;
      if (latitude && longitude) {
        const lat = `${t('attributes.field_labels.address.latitude')}: ${latitude}`;
        const long = `${t('attributes.field_labels.address.longitude')}: ${longitude}`;
        return <InlineKnackValue value={`${lat}, ${long}`} />;
      }
      return (
        <InlineKnackValue value={[street, street2, city, state, zip].filter(Boolean).join(', ')} />
      );
    }
    case 'timer': {
      const timerValue = ruleValue as TimerFieldConditionalRuleValue;
      const { from, to } = timerValue.times[0];
      return (
        <InlineKnackValue
          className={className}
          value={t('attributes.field_labels.timer.from_to', {
            from: `${from.date} ${from.hours}:${from.minutes}`,
            to: `${to.date} ${to.hours}:${to.minutes}`
          })}
        />
      );
    }
    case 'connection': {
      const valueField = tableFields.find((f) => f.key === ruleValues.field);
      if (!valueField) return null;

      if (valueField.type === 'connection') {
        const connectionValue = ruleValue as ConnectionFieldConditionalRuleValue;
        if (!connectionValue) return null;
        return connectionValue.map((record, index) => (
          <InlineKnackRecordValue
            // eslint-disable-next-line react/no-array-index-key
            key={`${record.id}-${index}`}
            tableKey={valueField.relationship.object}
            recordId={record.id}
          />
        ));
      }
      return null;
    }
    default: {
      // Covers all remaining cases where the value is a string, that way we don't need to check for all the field types that can have a string value
      if (typeof ruleValue === 'string') {
        // This is necessary to account for a schema bug where `false` boolean values are stored as empty strings in some scenarios
        return (
          <InlineKnackValue
            value={ruleValue}
            asHtml={field.type === 'rich_text' ? true : undefined}
            className={className}
          />
        );
      }
      return null;
    }
  }
}
