import { useEffect } from 'react';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { MultiSelect } from '@knack/asterisk-react';

import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { InlineSingleSelect } from '@/components/data-table/display/fields/connection/InlineSingleSelect';
import { type MultipleChoiceField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { MultipleChoiceValues } from '@/components/data-table/display/fields/multiple-choice/MultipleChoiceValues';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function MultipleChoiceEdit({
  fieldId,
  rawValue,
  rowId,
  type
}: FieldRenderProps<MultipleChoiceField>) {
  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const selectedCell = useDataTableStore().use.selectedCell();
  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  const isImmutable = currentField.immutable;

  if (!selectedCell) throw new Error('No selected cell');
  const { moveSelectionHorizontal } = useSelectionStrategy();

  const { options = [], type: formatType } = currentField.format;
  const isMultiselect = formatType === 'multi' || formatType === 'checkboxes';

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      size({
        padding: 500,
        apply({ availableHeight, elements }) {
          if (parseInt(elements.floating.style.height, 10) < availableHeight) {
            elements.floating.style.height = `${availableHeight}px`;
          }
        }
      }),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLButtonElement>) => {
    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (e.key === 'Tab' && selectedCell.isEditing) {
      moveSelectionHorizontal('right');
      setIsEditing(false);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      (refs.reference.current as HTMLTextAreaElement).focus();
    }, 0);
  }, [refs.reference]);

  // The selected option gets the focus when it changes or when you start editing
  // This way if you have a scrollbar the element will be scrolled into view
  useEffect(() => {
    if (selectedCell.isEditing) {
      clearCellErrors(rowId, fieldId);
    }
  }, [selectedCell.isEditing, clearCellErrors, rowId, fieldId]);

  return (
    <button
      ref={refs.setReference}
      data-testid={`edit-multiple-choice-input-${rowId}-${fieldId}`}
      type="button"
      className="flex size-full focus:outline-0"
      onKeyDown={handleKeyDown}
      onClick={() => {
        if (!selectedCell.isEditing) {
          setIsEditing(true);
        }
      }}
    >
      <MultipleChoiceValues
        rawValue={rawValue}
        {...(!isImmutable && {
          onDeleteOption: (option) => {
            if (typeof rawValue === 'object') {
              const newRawValue = rawValue?.filter((item) => item !== option);

              void saveCell(rowId, fieldId, newRawValue, {
                value: '',
                rawValue: newRawValue
              });
            }

            if (typeof rawValue === 'string') {
              const newRawValue = '';

              void saveCell(rowId, fieldId, newRawValue, {
                value: '',
                rawValue: newRawValue
              });
            }
          }
        })}
      />
      <div ref={refs.setFloating} style={floatingStyles} className="w-full">
        {selectedCell.isEditing && !isMultiselect && (
          <InlineSingleSelect
            options={
              options.map((item) => ({
                label: item,
                value: item
              })) || []
            }
            defaultValue={typeof rawValue === 'string' ? rawValue : ''}
            onChange={(selected) => {
              const newRawValue = selected;

              void saveCell(rowId, fieldId, newRawValue, {
                value: '',
                rawValue: newRawValue
              });
            }}
          />
        )}

        {selectedCell.isEditing && isMultiselect && (
          <MultiSelect
            id="new-menu-pages"
            shouldRenderInline
            options={
              options.map((item) => ({
                label: item,
                key: item
              })) || []
            }
            selectedOptions={
              typeof rawValue === 'object'
                ? rawValue.map((item) => ({
                    label: item,
                    key: item
                  }))
                : []
            }
            isSearchEnabled
            contentClassName="z-50"
            onSelectOptions={(selectedOptions) => {
              const newRawValue = selectedOptions?.map((item) => item.key);

              void saveCell(rowId, fieldId, newRawValue, {
                value: '',
                rawValue: newRawValue
              });
            }}
          />
        )}
      </div>

      <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="multiple-choice-edit-error" />
    </button>
  );
}
