import { useQuery } from '@tanstack/react-query';

import { type Task } from '@/types/schema/tasks/KnackTask';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

type GetApplicationTasksResponse = {
  tasks: Task[];
};

export async function getApplicationTasks(accountSlug: string, appSlug: string): Promise<Task[]> {
  const { data } = await axiosInstance.get<GetApplicationTasksResponse>(
    `/v1/builder/${accountSlug}/application/${appSlug}/tasks`,
    {
      withCredentials: true
    }
  );

  return data.tasks;
}

export function useTasksQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.tasks, accountSlug, appSlug],
    queryFn: () => getApplicationTasks(accountSlug, appSlug),
    staleTime: 1000 * 60 * 5
  });
}
