import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type PasswordField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { PasswordInput } from '@/components/data-table/display/fields/password/PasswordInput';
import { getPasswordSchema } from '@/components/data-table/display/fields/password/PasswordSchema';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function PasswordEdit(props: FieldRenderProps<PasswordField>) {
  const { fieldId, rowId, type } = props;
  const [t] = useTranslation();

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const selectedCell = useDataTableStore().use.selectedCell();

  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors, cancelFieldEdit } =
    useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const schema = getPasswordSchema(currentField);
  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    formState: { errors, isValid }
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      password: '',
      password_confirmation: '',
      hasChanged: true
    },
    mode: 'onChange'
  });

  const saveValues = useCallback(() => {
    const newRawValue = getValues();
    const ofuscatedPassword = Array(newRawValue.password.length).fill('*').join('');
    void saveCell(rowId, fieldId, newRawValue, {
      value: ofuscatedPassword,
      rawValue: newRawValue
    });
  }, [fieldId, getValues, rowId, saveCell]);

  const handleKeyDownFirst = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }
    if (e.key === 'Enter' && !selectedCell.isEditing) {
      setIsEditing(true);
    }
    if (e.key === 'Enter') {
      setFocus('password_confirmation');
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      moveSelectionHorizontal('left');
    }
  };

  const handleKeyDownLast = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }
    if (e.key === 'Enter') {
      moveSelectionVertical('down');
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      moveSelectionHorizontal('right');
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('password');
    }, 0);
  }, [setFocus]);

  const passwordRegister = register('password', {
    onChange: () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      void handleSubmit(saveValues, () => {
        cancelFieldEdit(rowId, fieldId);
      })();
    }
  });

  const passwordConfirmationRegister = register('password_confirmation', {
    onChange: () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      void handleSubmit(saveValues, () => {
        cancelFieldEdit(rowId, fieldId);
      })();
    }
  });

  return (
    <div className="flex size-full flex-col">
      <PasswordInput
        placeholder={t('components.data_table.password_field.new_password')}
        intent={cellErrors ? 'destructive' : 'default'}
        className={cn('z-10 min-h-full flex-grow resize-none rounded-none border-0 ', {
          'opacity-0': !selectedCell.isEditing,
          'min-h-8': selectedCell.isEditing
        })}
        onKeyDown={handleKeyDownFirst}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);
            setCursorPositionAtTheEnd(e);
          }
        }}
        data-testid={`edit-password-input-${rowId}-${fieldId}`}
        {...passwordRegister}
      />
      <PasswordInput
        placeholder={t('components.data_table.password_field.confirm_password')}
        intent={cellErrors ? 'destructive' : 'default'}
        className={cn('min-h-8 flex-grow resize-none rounded-none border-0', {
          hidden: !selectedCell.isEditing
        })}
        onKeyDown={handleKeyDownLast}
        data-testid={`edit-confirm-password-input-${rowId}-${fieldId}`}
        {...passwordConfirmationRegister}
      />
      <CellErrors
        rowId={rowId}
        fieldId={fieldId}
        testIdPrefix="password-edit-error"
        additionalErrors={
          selectedCell.isEditing
            ? [errors.password, errors.password_confirmation].filter(
                (el) => typeof el !== 'undefined'
              )
            : []
        }
      />
      {currentField.required && !isValid && errors.password && (
        <p className="absolute right-0 top-0 m-1 text-destructive">*</p>
      )}
    </div>
  );
}
