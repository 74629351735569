import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { ROUTES, type PageUrlParams } from '@/Router';

interface RemoveLoginFormProps {
  page: BuilderPage;
  onRemove?: () => void;
  onCancel?: () => void;
}

export function RemoveLoginForm({ page, onRemove, onCancel }: RemoveLoginFormProps) {
  const [t] = useTranslation();
  const { removeLoginMutation } = usePageMutation();
  const navigate = useNavigate();
  const urlParams = useParams<PageUrlParams>();
  const { id: selectedPageKey } = urlParams;
  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    pageName: z.string().refine((val) => val === 'Remove Login')
  });

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema)
  });

  function onSubmit() {
    removeLoginMutation.mutate(page, {
      onSuccess: () => {
        if (onRemove) {
          onRemove();
        }
        if (page.key === selectedPageKey) {
          navigate(ROUTES.PAGES);
        }
      }
    });
  }

  return (
    <Form
      data-testid="remove-login-form"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      <Form.Section>
        <Form.Label
          className="text-sm font-medium"
          htmlFor="remove-login-input"
          data-testid="remove-login-label"
        >
          <Trans i18nKey="pages.dialogs.remove_login.description">
            <p className="my-2 font-semibold">undone text</p>
          </Trans>
        </Form.Label>
        <div className="mt-1">
          <Input
            data-testid="remove-login-input"
            id="remove-login-input"
            type="text"
            placeholder={t('pages.dialogs.remove_login.input_placeholder')}
            {...register('pageName')}
          />
        </div>
      </Form.Section>
      <div className="mt-4 flex justify-end gap-2" data-testid="remove-login-dialog-footer">
        {onCancel && (
          <Button onClick={onCancel} intent="minimal" data-testid="remove-login-button-cancel">
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          disabled={!isValid}
          intent="destructive"
          type="submit"
          isLoading={removeLoginMutation.isPending}
          data-testid="remove-login-button"
        >
          {t('pages.dialogs.remove_login.submit_button')}
        </Button>
      </div>
    </Form>
  );
}
