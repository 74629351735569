import { type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import {
  type ReportView,
  type ReportViewChart,
  type ReportViewRow
} from '@/types/schema/views/ReportView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/generateNewViewKey';

const baseReportViewSchema: Partial<ReportView> = {
  type: 'report',
  description: '',
  columns: [],
  inputs: [],
  links: []
};

function generateReportViewRows(viewSubtype: ReportViewChart['type']) {
  const rows: ReportViewRow[] = [
    {
      reports: [
        {
          title: '',
          type: viewSubtype,
          source: {
            criteria: {
              match: 'all',
              rules: [],
              groups: []
            },
            limit: '',
            object: 'object_1'
          },
          filters: {
            filter_type: 'none',
            preset_filters: [
              {
                value: '',
                operator: 'contains',
                field: 'field_1'
              }
            ],
            menu_filters: [
              {
                key: '1',
                value: '',
                text: '',
                operator: 'contains',
                field: 'field_1'
              }
            ],
            filter_fields: 'view',
            allow_preset_filters: false
          },
          groups: [
            {
              group: 'name-field_1',
              type: 'name',
              field: 'field_1',
              label: 'Name'
            }
          ],
          calculations: [
            {
              field: 'count',
              calculation: 'total',
              label: 'Total'
            }
          ],
          description: '',
          options: {
            exclude_empties: false,
            hide_negatives: false,
            child_records: false,
            export_links: false,
            shouldShowDataTable: false
          },
          layout: {
            dimensions: 'auto',
            chart_width: '500',
            chart_height: '350',
            legend_width: '170',
            legend: 'bottom',
            stacking: 'none',
            tilt_labels: false,
            data_labels: true,
            bar_type: 'bar'
          },
          preview: false,
          settings: {
            export_links: false,
            shouldShowDataTable: false
          }
        }
      ]
    }
  ];

  return rows;
}

export function getReportViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema,
  viewSubtype: ReportViewChart['type']
) {
  const viewTitle =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const reportViewSchema: Partial<ReportView> = {
    ...baseReportViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    rows: generateReportViewRows(viewSubtype)
  };

  return reportViewSchema;
}
