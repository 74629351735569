import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Form, Input, Label, Select } from '@knack/asterisk-react';
import isEqual from 'lodash.isequal';
import { z } from 'zod';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  TASK_ACTION_TYPE_OPTIONS,
  TASK_RUN_STATUS_OPTIONS,
  TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS,
  TASK_TYPE,
  type Task,
  type TaskValueTypeValue
} from '@/types/schema/tasks/KnackTask';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import {
  DEFAULT_TASK_DATE_FORMAT,
  TASK_SCHEDULE_DATE_REGEX,
  TASK_SCHEDULE_TIME_REGEX
} from '@/pages/tables/toolkit-sidebar/tasks/constants';
import { useTaskFormHelpers } from '@/pages/tables/toolkit-sidebar/tasks/helpers/useTaskFormHelpers';
import { TaskEmailValueFormSection } from '@/pages/tables/toolkit-sidebar/tasks/TaskEmailValueFormSection';
import { TaskScheduleFormSection } from '@/pages/tables/toolkit-sidebar/tasks/TaskScheduleFormSection';
import { TaskStatusFormSection } from '@/pages/tables/toolkit-sidebar/tasks/TaskStatusFormSection';
import { TaskValueForm } from '@/pages/tables/toolkit-sidebar/tasks/TaskValueForm';
import { TaskCriteriaForm } from './TaskCriteriaForm';

type TaskFormProps = {
  table: KnackObject;
  existingTask?: Task;
  onCancel: () => void;
  onTaskSave: (updatedTask: Task) => void;
};

export function TaskForm({ table, existingTask, onCancel, onTaskSave }: TaskFormProps) {
  const [t] = useTranslation();

  const { getCurrentDate, getCurrentTime } = useDateTimeHelpers();
  const { getObjectByKey, getFieldByKey } = useObjectHelpers();
  const { getDefaultTaskValue, parseTask } = useTaskFormHelpers();

  const taskFormCriteriaSchema = z.object({
    field: z.string().min(1),
    operator: z.string(),
    value: z.union([z.string(), z.boolean(), z.number(), z.array(z.string())])
  });

  const recordTypeValueSchema = z.object({
    field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
    value: z.string().optional(),
    type: z.literal('record'),
    input: z.string()
  });

  const valueTypeValueSchema = z.object({
    field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
    value: z.union([
      z.string(),
      z.boolean(),
      z.number(),
      z.null(),
      z.record(z.any()),
      z.array(z.union([z.string(), z.record(z.any())]))
    ]),
    type: z.literal('value'),
    input: z.string().optional()
  });

  const connectionTypeValueSchema = z.object({
    field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
    value: z.string().optional(),
    type: z.literal('connection'),
    input: z.string().optional(),
    connection_field: z.string()
  });

  const emailTypeValueSchema = z.object({
    from_name: z.string().optional(),
    from_email: z.string().email().optional(),
    subject: z.string().optional(),
    message: z.string().optional(),
    recipients: z
      .array(
        z.object({
          recipient_mode: z.enum(['to', 'cc', 'bcc', 'reply_to']),
          recipient_type: z.enum(['custom', 'field']),
          email: z.string().email().or(z.literal('')),
          field: z.string().nullable()
        })
      )
      .optional()
  });

  const taskValueSchema = z.union([
    recordTypeValueSchema,
    valueTypeValueSchema,
    connectionTypeValueSchema
  ]);

  const taskFormSchema = z
    .object({
      name: z.string().min(1, {
        message: t('components.data_table.right_sidebar.tasks.errors.name_required')
      }),
      action: z.object({
        action: z.enum(TASK_ACTION_TYPE_OPTIONS),
        email: emailTypeValueSchema,
        criteria: z.array(taskFormCriteriaSchema),
        connection: z.string().optional(),
        values: z.array(taskValueSchema)
      }),
      run_status: z.enum(TASK_RUN_STATUS_OPTIONS),
      type: z.literal(TASK_TYPE),
      object_key: z.string().min(1, {
        message: t('components.data_table.right_sidebar.tasks.errors.object_key_required')
      }),
      schedule: z.object({
        repeat: z.enum(TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS),
        date: z.string().regex(TASK_SCHEDULE_DATE_REGEX, {
          message: t('components.data_table.right_sidebar.tasks.errors.date_error')
        }),
        time: z.string().regex(TASK_SCHEDULE_TIME_REGEX, {
          message: t('components.data_table.right_sidebar.tasks.errors.time_error')
        })
      }),
      key: z.string().optional() // Only if it's an existing task
    })
    .superRefine((data, context) => {
      // Validate action values
      data.action.values.forEach((value, valueIndex) => {
        if (value.type === 'record' && !value.input) {
          context.addIssue({
            path: [`action.values.${valueIndex}.input`],
            message: t('errors.value_required'),
            code: 'custom'
          });
        }
      });

      // Validate criteria values
      data.action.criteria.forEach((criteria, criteriaIndex) => {
        const isFieldMissingFromTable = !table.fields.some((field) => field.key === criteria.field);

        // Only show a validation error on the field if the selected field is missing from the table
        if (isFieldMissingFromTable) {
          context.addIssue({
            path: [`action.criteria.${criteriaIndex}.field`],
            message: t('errors.value_required'),
            code: 'custom'
          });
        }

        const isValueRequired = !shouldHideValueBasedOnOperator(criteria.operator);
        let isValueMissing = false;
        if (!criteria.value) {
          isValueMissing = true;
        } else if (Array.isArray(criteria.value)) {
          isValueMissing = criteria.value.length === 0 || criteria.value[0] === '';
        } else if (typeof criteria.value === 'string') {
          isValueMissing = criteria.value.trim() === '';
        }

        // Only show a validation error on the value if the operator requires a value, and the value is missing
        if (isValueRequired && isValueMissing) {
          context.addIssue({
            path: [`action.criteria.${criteriaIndex}.value`],
            message: t('errors.value_required'),
            code: 'custom'
          });
        }
      });

      // Validate schedule date to be in the future
      const scheduleDate = new Date(data.schedule.date);
      const [hours, minutes] = data.schedule.time.split(':');
      const isPM = minutes.includes('PM');
      scheduleDate.setHours(Number(hours) + (isPM ? 12 : 0));
      scheduleDate.setMinutes(Number(minutes.slice(0, 2))); // Removes the AM/PM
      const now = new Date();
      now.setMinutes(now.getMinutes() - 1); // Subtract 1 minute to avoid the edge case of the current time
      if (scheduleDate < now) {
        context.addIssue({
          path: [`schedule.date`, `schedule.time`],
          message: t('components.data_table.right_sidebar.tasks.errors.task_schedule_date_error'),
          code: 'custom'
        });
      }

      // Transform email subjects from field name to field key
      if (data.action.action === 'email' && data.action.email?.subject) {
        const emailSubject = data.action.email.subject;

        table.fields.forEach((field) => {
          if (emailSubject.includes(field.name) && data.action.email) {
            data.action.email.subject = emailSubject.replace(field.name, field.key);
          }
        });
      }

      // Validate email recipients
      if (data.action.action === 'email' && data.action.email?.recipients) {
        data.action.email.recipients.forEach((recipient, recipientIndex) => {
          if (recipient.recipient_type === 'field' && !recipient.field) {
            context.addIssue({
              path: [`action.email.recipients.${recipientIndex}.field`],
              message: t('errors.value_required'),
              code: 'custom'
            });
          }

          if (recipient.recipient_type === 'custom' && !recipient.email) {
            context.addIssue({
              path: [`action.email.recipients.${recipientIndex}.email`],
              message: t('errors.value_required'),
              code: 'custom'
            });
          }
        });
      }

      // Validate connection actions
      if (
        (data.action.action === 'connection' || data.action.action === 'insert') &&
        !data.action.connection
      ) {
        context.addIssue({
          path: [`action.connection`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }
    });

  type TaskFormSchema = z.infer<typeof taskFormSchema>;

  const defaultActionValue = getDefaultTaskValue(table.fields) as TaskValueTypeValue;

  const defaultValues: TaskFormSchema = {
    name: '',
    action: {
      action: 'record',
      email: {},
      criteria: [],
      values: [defaultActionValue]
    },
    run_status: 'running',
    type: 'actions',
    object_key: table.key,
    schedule: {
      repeat: 'daily',
      date: getCurrentDate(DEFAULT_TASK_DATE_FORMAT),
      time: getCurrentTime()
    }
  };

  const formMethods = useForm<TaskFormSchema>({
    resolver: zodResolver(taskFormSchema),
    // TODO: Remove this cast when all the TaskFormSchema is updated to Task (missing things still)
    defaultValues: existingTask ? (parseTask(existingTask) as TaskFormSchema) : defaultValues
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = formMethods;

  const allCurrentFormValues = watch();
  const shouldDisableSaveButton = isEqual(existingTask, allCurrentFormValues);

  const tableHasConnections =
    table.connections.inbound.length > 0 || table.connections.outbound.length > 0;

  const taskActionTypeOptions = TASK_ACTION_TYPE_OPTIONS.filter(
    (taskActionTypeItem) =>
      tableHasConnections ||
      (taskActionTypeItem !== 'connection' && taskActionTypeItem !== 'insert')
  );

  const isEmailActionTypeTask = watch('action.action') === 'email';
  const isConnectionActionTypeTask =
    watch('action.action') === 'connection' || watch('action.action') === 'insert';

  const tableConnections = [...table.connections.inbound, ...table.connections.outbound];
  const connectedObjectFields = isConnectionActionTypeTask
    ? getObjectByKey(watch('action.connection')?.split('.')[0] || tableConnections[0].object)
        ?.fields || []
    : [];

  const onSubmit = (updatedTask: TaskFormSchema) => {
    onTaskSave(updatedTask as Task); // This as Task will be removed when all the TaskFormSchema is updated to Task (missing things still)
  };

  // This is just a feature flag to disable the task creation in production
  if (import.meta.env.PUBLIC_IS_PRODUCTION === 'true') {
    return <div className="rounded-lg bg-muted p-2">Coming soon...</div>;
  }

  return (
    <div className="space-y-4 rounded-lg bg-muted p-4">
      <h4 className="text-sm font-semibold">
        {t('components.data_table.right_sidebar.tasks.new_task')}
      </h4>
      <FormProvider {...formMethods}>
        <Form data-testid="task-form" id="task-form" onSubmit={handleSubmit(onSubmit)}>
          <Form.Section className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="task-name-input" className="text-sm font-medium">
                {t('components.data_table.right_sidebar.tasks.name_label')}
              </Label>
              <Input
                id="task-name-input"
                className="w-full"
                intent={errors?.name ? 'destructive' : undefined}
                {...register('name')}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="task-action-type-select" className="text-sm font-medium">
                {t('components.data_table.right_sidebar.tasks.action_type.label')}
              </Label>
              <Controller
                name="action.action"
                defaultValue={defaultValues.action.action}
                render={({ field: taskActionType }) => (
                  <Select
                    onValueChange={taskActionType.onChange}
                    defaultValue={taskActionType.value || defaultValues.action.action}
                  >
                    <Select.Trigger
                      id="task-action-type-select"
                      placeholder={t('actions.select')}
                      className="w-full"
                      {...taskActionType}
                    />
                    <Select.Content>
                      {taskActionTypeOptions.map((taskActionTypeOption) => (
                        <Select.Item
                          key={taskActionTypeOption}
                          value={taskActionTypeOption}
                          data-testid={`task-action-type-${taskActionTypeOption}`}
                        >
                          {t(
                            `components.data_table.right_sidebar.tasks.action_type.${taskActionTypeOption}`
                          )}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />

              {isConnectionActionTypeTask && tableConnections.length > 0 && (
                <div className="flex flex-col gap-2">
                  <Label htmlFor="task-connection-select" className="text-sm font-medium">
                    {t('components.data_table.right_sidebar.tasks.connection.label')}
                  </Label>
                  <Controller
                    name="action.connection"
                    defaultValue={
                      defaultValues.action.connection ||
                      `${tableConnections[0].object}.${tableConnections[0].key}`
                    }
                    render={({ field: taskConnectedTableField }) => (
                      <Select
                        onValueChange={taskConnectedTableField.onChange}
                        defaultValue={
                          taskConnectedTableField.value || defaultValues.action.connection
                        }
                      >
                        <Select.Trigger
                          id="task-connection-select"
                          placeholder={t('actions.select')}
                          className="w-full"
                          {...taskConnectedTableField}
                        />
                        <Select.Content>
                          {tableConnections.map((connection) => (
                            <Select.Item
                              key={`${connection.object}-${connection.key}`}
                              value={`${connection.object}.${connection.key}`}
                              data-testid={`task-connection-${connection.object}-${connection.key}`}
                            >
                              <Trans
                                i18nKey="components.data_table.right_sidebar.tasks.connection.connected_table_select_trigger"
                                values={{
                                  connectedTable: getObjectByKey(connection.object)?.name,
                                  connectedField: getFieldByKey(connection.key)?.name
                                }}
                              />
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>
              )}
            </div>
          </Form.Section>

          <Divider className="my-2" />

          <TaskCriteriaForm availableFields={table.fields} />

          <Divider className="my-2" />

          {isEmailActionTypeTask ? (
            <TaskEmailValueFormSection availableFields={table.fields} />
          ) : (
            <TaskValueForm
              availableFields={isConnectionActionTypeTask ? connectedObjectFields : table.fields}
              tableConnections={table.connections}
            />
          )}

          <Divider className="my-2" />

          <TaskScheduleFormSection />

          <Divider className="my-2" />

          <TaskStatusFormSection />

          <div className="mt-4 flex justify-end gap-2">
            <Button intent="secondary" onClick={onCancel}>
              {t('actions.cancel')}
            </Button>
            <Button
              type="submit"
              data-testid="task-submit-button"
              disabled={shouldDisableSaveButton}
            >
              {t('actions.save')}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
}
