import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from '@knack/asterisk-react';
import * as XLSX from 'xlsx';

import { useGoogleSheetsPreviewMutation } from '@/hooks/api/mutations/useGoogleSheetsPreviewMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getBuilderBaseUrl } from '@/utils/application';
import { importDataAllowedFileFormats } from '@/utils/constants';
import { isFlagEnabled } from '@/utils/flagsmith';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { readFileAsync } from '@/utils/readFileAsync';
import { FileDropzone } from '@/components/FileDropzone';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { transformGoogleSheetsDataToWorkbook } from '@/components/import/utils';
import { WizardLayout } from '@/components/layout/WizardLayout';
import { CreateBlankTable } from '@/pages/add-table/CreateBlankTable';
import { CreateTableFromExcelSampleList } from '@/pages/add-table/CreateTableFromExcelSampleList';
import { DisclosureLinks } from '@/pages/add-table/DisclosureLinks';
import {
  DataSource,
  type GoogleSheetPreview,
  type SelectedDataSource
} from '@/pages/add-table/types';
import { AddTableSourceList } from './AddTableSourceList';
import { CreateTableFromFileImport } from './CreateTableFromFileImport';
import { CreateTableFromSampleList } from './CreateTableFromSampleList';
import { useGooglePickerApi } from './useGooglePickerApi';

export const MAX_FILE_SIZE_IN_BYTES = 250 * 1000 * 1000; // 250 MB

export function AddTablePage() {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const { presentToast } = useToast();
  const [selectedDataSource, setSelectedDataSource] = useState<SelectedDataSource | null>(null);
  const [googleSheetRowCountMap, setGoogleSheetRowCountMap] = useState<Record<string, number>>({});
  const [isGooglePickerLoading, setIsGooglePickerLoading] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState<string>('');
  const [isFetchingGoogleSheetsPreviewError, setIsFetchingGoogleSheetsPreviewError] =
    useState(false);
  const { data: application } = useApplicationQuery();
  const { mutate: fetchGoogleSheetsPreview } = useGoogleSheetsPreviewMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isFetchingGoogleSheetsPreviewError) {
      presentToast({
        title: t('components.add_table.errors.google_sheets_import')
      });
    }
  }, [isFetchingGoogleSheetsPreviewError, presentToast, t]);

  const pickerCallback = (data: google.picker.ResponseObject, token: string) => {
    setGoogleAccessToken(token);
    if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
      const doc = data[google.picker.Response.DOCUMENTS][0];
      setIsGooglePickerLoading(true);

      fetchGoogleSheetsPreview(
        { spreadsheetId: doc[google.picker.Document.ID], token },
        {
          onSuccess: (response: GoogleSheetPreview[]) => {
            const workbook = transformGoogleSheetsDataToWorkbook(response);

            const sheetRowCounts = response.reduce(
              (acc, sheet) => {
                acc[sheet.name] = sheet.rowCount;
                return acc;
              },
              {} as Record<string, number>
            );
            setGoogleSheetRowCountMap(sheetRowCounts);

            setSelectedDataSource({
              type: DataSource.import,
              file: doc,
              workbook
            });
            setIsGooglePickerLoading(false);
            setIsFetchingGoogleSheetsPreviewError(false);
          },
          onError: () => {
            setIsFetchingGoogleSheetsPreviewError(true);
            setIsGooglePickerLoading(false);
          }
        }
      );
    }
  };

  const initializeGooglePicker = useGooglePickerApi(pickerCallback);
  const handleSourceListItemClick = (id: string) => {
    if (id === 'blank') {
      setSelectedDataSource({ type: DataSource.blankTable });
    } else if (id === 'pre-made') {
      setSelectedDataSource({ type: DataSource.preMade });
    } else if (id === 'google-sheets') {
      initializeGooglePicker();
    }
  };

  const onBack = () => {
    setSelectedDataSource(null);
  };

  if (isGooglePickerLoading) {
    return <FullPageSpinner />;
  }

  if (!selectedDataSource) {
    return (
      <WizardLayout
        title={t('components.add_table.title')}
        onClose={() => {
          safeLogRocketTrack('NavigateToBuilder', application?.account.isHipaa);
          const shouldRedirectToNewBuilder =
            searchParams.has('origin') && searchParams.get('origin') === 'builder-next';

          if (shouldRedirectToNewBuilder) {
            navigate(-1);
          } else {
            window.location.replace(getBuilderBaseUrl());
          }
        }}
      >
        <FileDropzone
          onDrop={async (files: File[]) => {
            const file = files[0];
            const arrayBuffer = await readFileAsync(file, () => {});

            const workbook = XLSX.read(arrayBuffer, {
              cellDates: true,
              cellNF: true,
              dense: true
            });
            setSelectedDataSource({ type: DataSource.import, file, workbook });
          }}
          allowedFormats={importDataAllowedFileFormats}
          maxFileSize={MAX_FILE_SIZE_IN_BYTES}
          description={t('components.file_dropzone.description')}
          errorMapping={(error) => {
            if (error.code === 'file-too-large') {
              return t('components.file_dropzone.file_too_large', {
                size: MAX_FILE_SIZE_IN_BYTES / 1000 / 1000
              });
            }
            if (error.code === 'file-invalid-type') {
              return t('components.file_dropzone.file_invalid_spreadsheet');
            }

            return t('components.file_dropzone.file_upload_error');
          }}
          data-testid="add-table-file-dropzone"
        />
        <AddTableSourceList onClick={handleSourceListItemClick} />
        <DisclosureLinks className="m-5" />
      </WizardLayout>
    );
  }

  switch (selectedDataSource.type) {
    case DataSource.blankTable: {
      return <CreateBlankTable onBack={onBack} />;
    }
    case DataSource.import: {
      return (
        <CreateTableFromFileImport
          onBack={onBack}
          selectedDataSource={selectedDataSource}
          googleAccessToken={googleAccessToken}
          googleSheetRowCountMap={googleSheetRowCountMap}
        />
      );
    }
    case DataSource.preMade: {
      if (isFlagEnabled('pre_made_excels')) {
        return <CreateTableFromExcelSampleList onBack={onBack} />;
      }
      return <CreateTableFromSampleList onBack={onBack} />;
    }
    default:
      return null;
  }
}
