import { type KnackFieldType } from '@/types/schema/KnackField';
import {
  blankOperators,
  containsOperators,
  dateTimeOperators,
  hideValueOperators,
  higherLowerOperators,
  isAnyOperators,
  isOperators,
  stringOperators,
  timeComparisonOperators
} from '@/types/schema/KnackOperator';

export function generateConditionalRuleOperators(fieldType: KnackFieldType) {
  const defaultOperators = [
    ...containsOperators,
    ...isOperators,
    ...stringOperators,
    ...blankOperators,
    ...timeComparisonOperators
  ];

  const textOperators = [
    ...containsOperators,
    ...isOperators,
    ...stringOperators,
    ...blankOperators
  ];
  const numericOperators = [...isOperators, ...higherLowerOperators, ...blankOperators];
  const choiceOperators = [
    ...isOperators,
    ...containsOperators,
    ...isAnyOperators,
    ...blankOperators
  ];

  switch (fieldType) {
    case 'short_text':
    case 'paragraph_text':
    case 'rich_text':
    case 'name':
    case 'address':
    case 'phone':
    case 'email':
      return textOperators;
    case 'number':
    case 'currency':
    case 'rating':
    case 'timer':
      return numericOperators;
    case 'multiple_choice':
    case 'connection':
      return choiceOperators;
    case 'boolean':
      return [...isOperators];
    case 'link':
      return [...isOperators, ...blankOperators];
    case 'image':
    case 'file':
    case 'signature':
      return [...blankOperators];
    case 'date_time':
      return [...dateTimeOperators];
    case 'auto_increment':
    case 'concatenation':
    case 'equation':
    case 'sum':
    case 'min':
    case 'max':
    case 'average':
    case 'count':
      return [...isOperators, ...higherLowerOperators, ...blankOperators];
    default:
      return [...defaultOperators, ...blankOperators];
  }
}

export const conditionalRuleMap = {
  short_text: {
    operators: generateConditionalRuleOperators('short_text')
  },
  paragraph_text: {
    operators: generateConditionalRuleOperators('paragraph_text')
  },
  rich_text: {
    operators: generateConditionalRuleOperators('rich_text')
  },
  number: {
    operators: generateConditionalRuleOperators('number')
  },
  currency: {
    operators: generateConditionalRuleOperators('currency')
  },
  multiple_choice: {
    operators: generateConditionalRuleOperators('multiple_choice')
  },
  boolean: {
    operators: generateConditionalRuleOperators('boolean')
  },
  date_time: {
    operators: generateConditionalRuleOperators('date_time')
  },
  timer: {
    operators: generateConditionalRuleOperators('timer')
  },
  file: {
    operators: generateConditionalRuleOperators('file')
  },
  image: {
    operators: generateConditionalRuleOperators('image')
  },
  name: {
    operators: generateConditionalRuleOperators('name')
  },
  email: {
    operators: generateConditionalRuleOperators('email')
  },
  address: {
    operators: generateConditionalRuleOperators('address')
  },
  phone: {
    operators: generateConditionalRuleOperators('phone')
  },
  link: {
    operators: generateConditionalRuleOperators('link')
  },
  signature: {
    operators: generateConditionalRuleOperators('signature')
  },
  rating: {
    operators: generateConditionalRuleOperators('rating')
  },
  connection: {
    operators: generateConditionalRuleOperators('connection')
  },
  auto_increment: {
    operators: generateConditionalRuleOperators('auto_increment')
  },
  concatenation: {
    operators: generateConditionalRuleOperators('concatenation')
  },
  equation: {
    operators: generateConditionalRuleOperators('equation')
  },
  sum: {
    operators: generateConditionalRuleOperators('sum')
  },
  min: {
    operators: generateConditionalRuleOperators('min')
  },
  max: {
    operators: generateConditionalRuleOperators('max')
  },
  average: {
    operators: generateConditionalRuleOperators('average')
  },
  count: {
    operators: generateConditionalRuleOperators('count')
  }
};

export function shouldHideOperator(operator: string) {
  const hideValueOperatorsSet = new Set(hideValueOperators);
  // TODO: FE-1944 refine type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return hideValueOperatorsSet.has(operator as any);
}
// TODO
// remove these when the conditional rules are fully implemented
// https://knack.atlassian.net/browse/FE-2480
export function getDisabledConditionalRuleCriteriaFields(): KnackFieldType[] {
  const disabledActionFields: KnackFieldType[] = ['user_roles', 'password'];
  return disabledActionFields;
}

export function getDisabledConditionalRuleActionFields(): KnackFieldType[] {
  const disabledActionFields: KnackFieldType[] = ['user_roles', 'password'];
  return disabledActionFields;
}
