import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Label, Spinner, Tabs } from '@knack/asterisk-react';

import { type Task, type TaskHistoryStatus } from '@/types/schema/tasks/KnackTask';
import { useTasksHistoryQuery } from '@/hooks/api/queries/useTasksHistoryQuery';
import { TaskHistoryContextProvider } from '@/pages/tables/toolkit-sidebar/tasks/task-history/TaskHistoryContext';
import { TaskHistoryTable } from '@/pages/tables/toolkit-sidebar/tasks/task-history/TaskHistoryTable';

interface TaskHistoryModalProps {
  onOpenChange: (isOpen: boolean) => void;
  task: Task;
}

type TaskHistoryTabs = {
  value: TaskHistoryStatus | 'All';
  children: React.ReactNode;
};

export function TaskHistoryModal({ onOpenChange, task }: TaskHistoryModalProps) {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState<TaskHistoryTabs['value']>('All');

  const items: TaskHistoryTabs[] = [
    {
      children: (
        <span>{t('components.data_table.right_sidebar.tasks.task_history.tab_status.all')}</span>
      ),
      value: 'All'
    },
    {
      children: (
        <span>
          {t('components.data_table.right_sidebar.tasks.task_history.tab_status.finished')}
        </span>
      ),
      value: 'Finished'
    },
    {
      children: (
        <span>
          {t('components.data_table.right_sidebar.tasks.task_history.tab_status.canceled')}
        </span>
      ),
      value: 'Canceled'
    },
    {
      children: (
        <span>{t('components.data_table.right_sidebar.tasks.task_history.tab_status.failed')}</span>
      ),
      value: 'Failed'
    }
  ];

  const { data: taskHistory = [], isLoading: isTaskHistoryLoading } = useTasksHistoryQuery({
    taskKey: task.key,
    tableKey: task.object_key
  });

  const getTasksHistoryData = (status: TaskHistoryTabs['value']) => {
    if (status === 'All') {
      return taskHistory;
    }

    return taskHistory.filter((history) => history.status.includes(status));
  };

  return (
    <TaskHistoryContextProvider taskHistoryData={getTasksHistoryData(activeTab)}>
      <Dialog open onOpenChange={onOpenChange}>
        <Dialog.Content data-testid="tasks-history-modal" width="lg">
          {isTaskHistoryLoading ? (
            <div className="flex w-full items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <Dialog.MainContent className="pb-0">
              <Dialog.Header>
                <Dialog.Title>
                  {t('components.data_table.right_sidebar.tasks.task_history.title', {
                    taskKey: task.key.split('_')[1] || task.name
                  })}
                </Dialog.Title>
                <Label className="text-xs">
                  {`${t(`components.data_table.right_sidebar.tasks.card.action_options.${task.action.action}`)} 
                ${t(`components.data_table.right_sidebar.tasks.card.schedule_options.${task.schedule.repeat}`)}`}
                </Label>
              </Dialog.Header>
              <Tabs
                defaultValue={activeTab}
                className="flex h-full flex-col"
                onValueChange={(value) => setActiveTab(value as TaskHistoryTabs['value'])}
                data-testid="tables-page-tabs"
              >
                <Tabs.List
                  data-testid="task-history-tabs-list"
                  items={items}
                  shouldUseGradient
                  intent="page"
                  className="mt-6"
                />
                <TaskHistoryTable />
              </Tabs>
            </Dialog.MainContent>
          )}
          <Dialog.Footer>
            <Dialog.Close>
              <Button intent="minimal">{t('actions.cancel')}</Button>
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </TaskHistoryContextProvider>
  );
}
