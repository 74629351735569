import { useEffect } from 'react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { assertTruthiness } from '@/utils/assert';
import { useJobsStore } from '@/components/jobs/useJobsStore';

type JobEvent = {
  job_id: string;
  object_key: string;
  processed_count: number;
  total_count: number;
};

type JobEventCompleted = {
  failed_rows: number[];
} & JobEvent;

export function useWebSocket() {
  const { data: application } = useApplicationQuery();
  assertTruthiness(application, 'No application found.');

  const socket = useJobsStore((state) => state.socket);
  const actions = useJobsStore((state) => state.actions);

  useEffect(() => {
    const socketInitialize = actions.initializeSocket(application.id);
    socketInitialize.connect();
    return () => {
      socketInitialize.disconnect();
    };
  }, [actions, application.id]);

  useEffect(() => {
    if (!socket) return () => {};

    // Import Jobs
    const onImportRetry = (value: JobEvent) => {
      actions.setJobState(value.job_id, 'retrying');
    };

    const onImportComplete = (value: JobEventCompleted) => {
      actions.setJobState(value.job_id, 'completed');
      if (value.failed_rows) {
        actions.setFailedRows(value.job_id, value.failed_rows);
      }
    };

    const onImportCanceled = (value: JobEvent) => {
      actions.setJobState(value.job_id, 'canceled');
    };

    const onImportProgress = (value: JobEvent) => {
      actions.showJob(value.job_id);
      actions.setProgress(value.job_id, {
        tableKey: value.object_key,
        processedCount: value.processed_count,
        totalCount: value.total_count
      });
    };

    const onImportFailed = (value: JobEvent) => {
      actions.setJobState(value.job_id, 'failed');
    };

    socket.on('csv:import:progress', onImportProgress);
    socket.on('csv:import:retry', onImportRetry);
    socket.on('csv:import:complete', onImportComplete);
    socket.on('csv:import:canceled', onImportCanceled);
    socket.on('csv:import:failed', onImportFailed);

    return () => {
      socket.off('csv:import:progress', onImportProgress);
      socket.off('csv:import:retry', onImportRetry);
      socket.off('csv:import:complete', onImportComplete);
      socket.off('csv:import:canceled', onImportCanceled);
      socket.off('csv:import:failed', onImportFailed);
    };
  }, [actions, socket]);
}
