import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { type PageType } from '@/types/schema/BuilderPage';
import { NewPageForm } from '@/pages/pages/page-tree/new-page-dropdown-menu/NewPageForm';

export function NewPageDialog({
  isDialogOpen,
  setIsDialogOpen,
  newPageType
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  newPageType: PageType;
}) {
  const [t] = useTranslation();

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t(`pages.dialogs.new_${newPageType}.title`)}</Dialog.Title>
          </Dialog.Header>
          <NewPageForm
            pageType={newPageType}
            onSubmitCallback={() => setIsDialogOpen(false)}
            onCancel={() => setIsDialogOpen(false)}
          />
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
