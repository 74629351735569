import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFilterAlt as FilterIcon } from 'react-icons/md';
import { Button, Chip, Popover } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { Filters, getFirstOperator } from '@/components/data-table/display/header/filters/Filters';
import { type FilterFormat } from '@/components/data-table/helpers/getRecords';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';

const MAX_FILTERED_FIELDS_TO_SHOW = 2;

const getFilteredFields = (filters: FilterFormat | null) => {
  const allFilteredFields = filters?.rules.map((rule) => rule.field);

  if (!allFilteredFields) return [];

  // remove duplicates
  return allFilteredFields.filter((item, index) => allFilteredFields.indexOf(item) === index);
};

export function FilterButton() {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const fields = useDataTableStore().use.fields();
  const filters = useDataTableStorePersist().use.filters();
  const { setFilters, setPage } = useDataTableStorePersist().use.actions();
  const { refetchPagesInViewport, setPagesInViewport } = useDataTableStore().use.actions();

  const filteredFields = getFilteredFields(filters);
  const totalRecords = useDataTableStore().use.totalRecords();
  const isDraftModeEnabled = useDataTableStore().use.isDraftModeEnabled();

  useEffect(() => {
    // Iterate the rules and remove the fields that doesn't exist.
    // This can happen if you remove a field or you change the url manually
    const listOfFieldsInTable = fields.map((field) => field.key);
    const rulesWithoutMissingFields =
      filters?.rules.filter((filter) => listOfFieldsInTable.includes(filter.field)) || [];

    if (rulesWithoutMissingFields.length === 0) {
      setFilters(null);
      return;
    }

    setFilters({
      ...(filters || { match: 'and' }),
      rules: rulesWithoutMissingFields
    });
    // We only need to check this when fields change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <TextTooltip
        label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
        asChild
      >
        <div>
          <Popover.Trigger data-testid="data-table-filter-button" asChild>
            {/* TODO: FE-2522 - These are not the final colors. We'll change them with the correct ones when they are available in Asterisk */}
            <Button
              intent="secondary"
              className={cn('h-9 p-2', { 'border-blue-200 bg-blue-50': filters })}
              disabled={isDraftModeEnabled}
            >
              <FilterIcon size={20} className="mr-1 flex-shrink-0 text-default" />
              {filters
                ? t('components.data_table.filtering.filtered_by')
                : t('components.data_table.header.filter')}

              {filteredFields.length <= MAX_FILTERED_FIELDS_TO_SHOW &&
                filteredFields.map((field) => {
                  const fieldSelectedFormat = fields.find((el) => el.key === field);
                  if (!fieldSelectedFormat) return null;
                  return (
                    <div
                      key={field}
                      className="ml-1 flex h-6 max-w-24 items-center rounded bg-blue-100 px-1"
                    >
                      <FieldIcon name={fieldSelectedFormat.type} size={13} className="mr-1" />
                      <p className="truncate">{fieldSelectedFormat.name}</p>
                    </div>
                  );
                })}

              {filteredFields.length > MAX_FILTERED_FIELDS_TO_SHOW &&
                ` ${filteredFields.length} ${t('components.data_table.filtering.fields')}`}
              {filters && (
                <Chip
                  text={`${totalRecords}`}
                  size="sm"
                  className="pointer-events-none m-0 ml-1 bg-blue-100 px-2 py-3"
                />
              )}
            </Button>
          </Popover.Trigger>
        </div>
      </TextTooltip>
      <Popover.Content className="w-[680px] max-w-[100vw] overflow-auto p-6">
        <h2 className="mb-1 text-xl">{t('components.data_table.filtering.filter_by')}</h2>
        <p className="mb-6 text-xs ">{t('components.data_table.filtering.choose_field')}</p>

        <Filters
          initialFilters={
            filters || {
              match: 'and',
              rules: [
                {
                  field: fields[0]?.key,
                  operator: getFirstOperator(fields, fields[0]?.key),
                  value: ''
                }
              ]
            }
          }
          onCancel={() => {
            setIsOpen(false);
          }}
          filterChange={(newFilter) => {
            setFilters(newFilter);
            setPage(null);
            setPagesInViewport([0]);
            refetchPagesInViewport();
            setIsOpen(false);
          }}
        />
      </Popover.Content>
    </Popover>
  );
}
