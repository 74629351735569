import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  type BuilderViewBase,
  type BuilderViewKey,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackCriteria, type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormViewAddressInput } from '@/types/schema/views/form/Address';
import { type FormViewBooleanInput } from '@/types/schema/views/form/Boolean';
import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormViewCurrencyInput } from '@/types/schema/views/form/Currency';
import { type FormViewDateTimeInput } from '@/types/schema/views/form/DateTime';
import { type FormViewEmailInput } from '@/types/schema/views/form/Email';
import { type FormViewEquationInput } from '@/types/schema/views/form/Equation';
import { type FormViewFileInput } from '@/types/schema/views/form/File';
import { type FormViewImageInput } from '@/types/schema/views/form/Image';
import { type FormViewLinkInput } from '@/types/schema/views/form/Link';
import { type FormViewMultipleChoiceInput } from '@/types/schema/views/form/MultipleChoice';
import { type FormViewNameInput } from '@/types/schema/views/form/Name';
import { type FormViewNumberInput } from '@/types/schema/views/form/Number';
import { type FormViewParagraphTextInput } from '@/types/schema/views/form/ParagraphText';
import { type FormViewPasswordInput } from '@/types/schema/views/form/Password';
import { type FormViewPhoneInput } from '@/types/schema/views/form/Phone';
import { type FormViewRatingInput } from '@/types/schema/views/form/Rating';
import { type FormViewRichTextInput } from '@/types/schema/views/form/RichText';
import { type FormViewShortTextInput } from '@/types/schema/views/form/ShortText';
import { type FormViewSignatureInput } from '@/types/schema/views/form/Signature';
import { type FormViewTimerInput } from '@/types/schema/views/form/Timer';
import { type FormViewUserRolesInput } from '@/types/schema/views/form/UserRoles';

export const FORM_VIEW_GROUP_ID_PREFIX = 'group_';
export const FORM_VIEW_COLUMN_ID_PREFIX = 'column_';
export const FORM_VIEW_DIVIDER_INPUT_ID_PREFIX = 'divider_';
export const FORM_VIEW_SECTION_BREAK_INPUT_ID_PREFIX = 'section_break_';

export const DISALLOWED_FIELD_TYPES_IN_FORM_VIEWS: KnackFieldType[] = [
  'concatenation',
  'auto_increment',
  'sum',
  'min',
  'max',
  'average',
  'count'
];

export interface FormView extends BuilderViewBase {
  type: 'form' | 'registration';
  action: 'create' | 'update' | 'insert';
  columns: unknown[];
  links: unknown[];
  inputs: unknown[];
  source: BuilderViewSourceSchema;
  groups: FormViewGroup[];
  submitButtonText: string;
  reload_form_text?: string;
  rules: {
    submits: FormViewSubmitRule[];
    fields?: FormViewDisplayRule[];
    records?: unknown[];
    emails?: Array<{
      action: string;
      connection: null;
      criteria: unknown[];
      values: Array<{
        type: string;
        input: string;
        connection_field: string;
        value: string;
      }>;
      email: {
        from_name: string;
        from_email: string;
        subject: string;
        message: string;
        recipients: Array<{
          recipient_mode: string;
          recipient_type: string;
          email: string;
          field: string;
        }>;
      };
      key: string;
    }>;
  };
  alert: string;
  design: unknown;
  parent?: BuilderViewKey;
}

export type FormViewGroup = {
  id: string;
  columns: FormViewGroupColumn[];
};

export type FormViewGroupColumn = {
  id: string;
  inputs: FormViewInput[];
};

export const FORM_VIEW_LAYOUT_COLUMNS = ['1', '2', '3'] as const;
export type FormViewLayoutColumns = (typeof FORM_VIEW_LAYOUT_COLUMNS)[number];

export interface FormViewDisplayRule {
  key: string;
  criteria: KnackCriteria[];
  actions: FormViewDisplayRuleAction[];
}

export interface FormViewSubmitRule {
  key: string;
  action: FormViewSubmitRuleActionType;
  criteria?: KnackCriteriaWithValueType[];
  is_default: boolean;
  reload_show: boolean;
  reload_auto?: boolean;
  message: string;
  existing_page?: string;
  url?: string;

  // `scene` can be a string (the slug of an existing child page) or an object (the data for a new child page to be created that is sent to the API)
  scene?:
    | string
    | {
        name: string;
        object: KnackObject['key'];
        parent: string;
        views: BuilderViewKey[];
        allowed_profiles: BuilderPage['allowedProfileKeys'];
      }
    | null;
}

export const FORM_VIEW_SUBMIT_RULE_ACTION_TYPES = [
  'message',
  'existing_page',
  'child_page',
  'url'
] as const;
export type FormViewSubmitRuleActionType = (typeof FORM_VIEW_SUBMIT_RULE_ACTION_TYPES)[number];

export const FORM_VIEW_DISPLAY_RULE_ACTION_TYPES = [
  'show',
  'hide',
  'label',
  'show-hide',
  'hide-show'
] as const;
export type FormViewDisplayRuleActionType = (typeof FORM_VIEW_DISPLAY_RULE_ACTION_TYPES)[number];

export interface FormViewDisplayRuleAction {
  field: KnackFieldKey;
  action: FormViewDisplayRuleActionType;
  value: string;
}

export interface FormViewFieldInputBase {
  id: KnackFieldKey;
  field: {
    key: KnackFieldKey;
  };
  instructions: string;
  label: string;
  value?: string;
  read_only?: boolean; // This only comes when editing a record and enabling the read-only option on the input
}

export interface FormViewSectionBreakInput {
  id: string;
  type: 'section_break';
  copy: string;
  label: string;
  instructions: string;
}

export interface FormViewDividerInput {
  id: string;
  type: 'divider';
  instructions: string;
}

export type FormViewStaticInput = FormViewSectionBreakInput | FormViewDividerInput;

export type FormViewFieldInput =
  | FormViewShortTextInput
  | FormViewParagraphTextInput
  | FormViewRichTextInput
  | FormViewNumberInput
  | FormViewCurrencyInput
  | FormViewEquationInput
  | FormViewNameInput
  | FormViewAddressInput
  | FormViewEmailInput
  | FormViewMultipleChoiceInput
  | FormViewBooleanInput
  | FormViewFileInput
  | FormViewImageInput
  | FormViewDateTimeInput
  | FormViewTimerInput
  | FormViewPhoneInput
  | FormViewLinkInput
  | FormViewSignatureInput
  | FormViewRatingInput
  | FormViewConnectionInput
  | FormViewPasswordInput
  | FormViewUserRolesInput;

export type FormViewInput = FormViewFieldInput | FormViewStaticInput;
