import { useEffect } from 'react';

import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';

export function usePersistUrlParams() {
  const objectKey = useDataTableStore().use.objectKey();
  const scroll = useDataTableStorePersist().use.scroll();
  const sortBy = useDataTableStorePersist().use.sortBy();
  const sortOrder = useDataTableStorePersist().use.sortOrder();
  const filters = useDataTableStorePersist().use.filters();
  const page = useDataTableStorePersist().use.page();
  const rowsPerPage = useDataTableStorePersist().use.rowsPerPage();

  useEffect(() => {
    const save = {
      scroll,
      sortBy,
      sortOrder,
      filters,
      page,
      rowsPerPage
    };
    localStorage.setItem(`table-url-params-${objectKey}`, JSON.stringify(save));
  }, [objectKey, scroll, sortBy, sortOrder, filters, page, rowsPerPage]);
}
