import { useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiPaintBrush as StylingIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type ListView } from '@/types/schema/views/ListView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/ViewGeneralSettingsCategory';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type ListSettingsCategoryId = 'general-settings' | 'data-display' | 'actions' | 'styling';
type ListSettingsCategoryItem = ViewSettingsCategoryItem<ListSettingsCategoryId>;

function getListSettingsCategories() {
  const LIST_SETTINGS_CATEGORIES: ListSettingsCategoryItem[] = [
    {
      id: 'general-settings',
      name: t('pages.element_settings.common.categories.general_settings.name'),
      description: t('pages.element_settings.list.categories.general_settings.description'),
      icon: GeneralSettingsIcon
    },
    {
      id: 'data-display',
      name: t('pages.element_settings.common.categories.data_display.name'),
      description: t('pages.element_settings.common.categories.data_display.description'),
      icon: FieldsIcon,
      isComingSoon: true
    },
    {
      id: 'actions',
      name: t('pages.element_settings.list.categories.actions.name'),
      description: t('pages.element_settings.list.categories.actions.description'),
      icon: ColumnsIcon,
      isComingSoon: true
    },
    {
      id: 'styling',
      name: t('pages.element_settings.common.categories.styling.name'),
      description: t('pages.element_settings.table.categories.styling.description'),
      icon: StylingIcon,
      isComingSoon: true
    }
  ];

  return LIST_SETTINGS_CATEGORIES;
}

function getPanelTitle(
  activeCategoryId: ListSettingsCategoryId | null,
  categories: ListSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.list.heading');
}

export function ListViewSettings() {
  const [activeCategoryId, setActiveCategoryId] = useState<ListSettingsCategoryId | null>(null);
  const { view } = useActiveViewContext<ListView>();

  const listSettingsCategories = getListSettingsCategories();
  const panelTitle = getPanelTitle(activeCategoryId, listSettingsCategories);

  const listViewGeneralSettingsOptions = [
    {
      key: 'keyword_search',
      label: t('pages.element_settings.common.categories.general_settings.keyword_search'),
      checked: view.keyword_search
    },
    {
      key: 'allow_exporting',
      label: t('pages.element_settings.common.categories.general_settings.record_exporting'),
      checked: view.allow_exporting
    }
  ];

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
      shouldHideDivider={activeCategoryId === 'data-display'}
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && (
            <ViewGeneralSettingsCategory options={listViewGeneralSettingsOptions} />
          )}
          {activeCategoryId === 'data-display' && <div>Data Display</div>}
          {activeCategoryId === 'actions' && <div>Actions</div>}
        </>
      ) : (
        <ViewSettingsCategoryList
          categories={listSettingsCategories}
          onCategorySelect={(selectedCategoryId: string) => {
            setActiveCategoryId(selectedCategoryId as ListSettingsCategoryId);
          }}
        />
      )}
    </CollapsiblePanel.Content>
  );
}
