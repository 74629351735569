import { useTranslation } from 'react-i18next';
import { Input, Label, RichTextEditor } from '@knack/asterisk-react';

import { type LoginView } from '@/types/schema/views/LoginView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function LoginSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<LoginView>();
  const updateViewSchema = useUpdateView<LoginView>();

  const i18nParams = { viewName: t(`views.view_names.${view.type}`) };

  return (
    <CollapsiblePanel.Content title={t('pages.element_settings.login.heading')}>
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.general.title_and_description')}
        >
          <div className="mb-4">
            <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-title`}>
              {t('pages.element_settings.general.view_title', i18nParams)}
            </Label>
            <Input
              id={`${view.key}-view-title`}
              className="text-sm"
              value={view.title ?? ''}
              onChange={(e) => {
                updateViewSchema({
                  title: e.target.value
                });
              }}
            />
            <p className="mt-2 text-xs text-subtle">
              {t('pages.element_settings.general.title_hint', i18nParams)}
            </p>
          </div>
          <div>
            <span className="mb-2 block text-sm font-medium">
              {t('pages.element_settings.general.view_description', i18nParams)}
            </span>
            <RichTextEditor
              onUpdate={({ editor }) => {
                updateViewSchema({
                  description: editor.getText() ? editor.getHTML() : ''
                });
              }}
              content={view.description || ''}
            />
            <p className="mt-2 text-xs text-subtle">
              {t('pages.element_settings.general.description_hint', i18nParams)}
            </p>
          </div>
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </CollapsiblePanel.Content>
  );
}
