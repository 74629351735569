import { type KnackFieldType } from '@/types/schema/KnackField';

export const NUMERIC_FIELD_TYPES: KnackFieldType[] = [
  'number',
  'currency',
  'timer',
  'auto_increment',
  'rating',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'equation'
];
