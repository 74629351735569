import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { buildSocket } from '@/hooks/socket/socket';
import { createSelectors } from '@/utils/zustand';

type JobStatus =
  | 'queued'
  | 'in_progress'
  | 'in_progress'
  | 'completed'
  | 'failed'
  | 'retrying'
  | 'canceled';

type JobState = {
  jobId: string;
  jobFinishedOn: number;
  tableKey: string;
  isShown: boolean;
  jobStatus: JobStatus;
  progress: number;
  processedCount: number;
  totalCount: number;
  failedRows: number[];
};

const initialJobState: JobState = {
  jobId: '',
  jobFinishedOn: 0,
  tableKey: '',
  isShown: false,
  jobStatus: 'queued',
  progress: 0,
  processedCount: 0,
  totalCount: 0,
  failedRows: []
};

const initialState = {
  jobList: {
    // Uncomment to test locally the different job states without running an import
    // '1': {
    //   jobId: '1',
    //   jobFinishedOn: 0,
    //   tableKey: 'object_1', // Set to an existing tableKey
    //   isShown: true,
    //   jobStatus: 'in_progress',
    //   progress: 10,
    //   processedCount: 0,
    //   totalCount: 100,
    //   failedRows: []
    // }
  },
  socket: null
} satisfies Partial<Store>;

type Store = {
  jobList: Record<string, JobState>;
  socket: SocketIOClient.Socket | null;
  actions: {
    initializeSocket: (appId: string) => SocketIOClient.Socket;
    jobStarted: (jobId: string, tableKey: string) => void;
    setJobState: (jobId: string, status: JobStatus) => void;
    showJob: (jobId: string) => void;
    hideJob: (jobId: string) => void;
    setProgress: (
      jobId: string,
      progress: {
        tableKey: string;
        processedCount: number;
        totalCount: number;
      }
    ) => void;
    setFailedRows: (jobId: string, failedRows: number[]) => void;
  };
};

const initializeJobIfNew = (jobId: string, jobList: Record<string, JobState>) => {
  if (!jobList[jobId]) {
    jobList[jobId] = { ...initialJobState, jobId };
  }
  return jobList;
};
const storeStateBase = create<Store>()(
  immer((set) => ({
    ...initialState,
    actions: {
      initializeSocket: (appId) => {
        const socket = buildSocket(appId);
        set((draft) => {
          draft.socket = socket;
        });
        return socket;
      },
      setJobState: (jobId, status) => {
        set((draft) => {
          draft.jobList = initializeJobIfNew(jobId, draft.jobList);
          draft.jobList[jobId].jobStatus = status;
          if (status !== 'in_progress' && status !== 'queued') {
            draft.jobList[jobId].jobFinishedOn = Date.now();
          }
        });
      },
      jobStarted: (jobId, tableKey) => {
        set((draft) => {
          draft.jobList = initializeJobIfNew(jobId, draft.jobList);
          draft.jobList[jobId].jobStatus = 'queued';
          draft.jobList[jobId].tableKey = tableKey;
          draft.jobList[jobId].isShown = true;
          draft.jobList[jobId].failedRows = [];
        });
      },
      showJob: (jobId) => {
        set((draft) => {
          draft.jobList = initializeJobIfNew(jobId, draft.jobList);
          draft.jobList[jobId].isShown = true;
        });
      },
      hideJob: (jobId) => {
        set((draft) => {
          draft.jobList[jobId].isShown = false;
        });
      },
      setProgress: (jobId, progress) => {
        set((draft) => {
          draft.jobList = initializeJobIfNew(jobId, draft.jobList);

          draft.jobList[jobId].jobStatus = 'in_progress';

          draft.jobList[jobId].tableKey = progress.tableKey;
          draft.jobList[jobId].processedCount = progress.processedCount;
          draft.jobList[jobId].totalCount = progress.totalCount;
          draft.jobList[jobId].progress = Math.floor(
            (progress.processedCount / progress.totalCount) * 100
          );
        });
      },
      setFailedRows: (jobId, failedRows) => {
        set((draft) => {
          draft.jobList = initializeJobIfNew(jobId, draft.jobList);

          draft.jobList[jobId].failedRows = failedRows;
        });
      }
    }
  }))
);

export const useJobsStore = createSelectors(storeStateBase);
