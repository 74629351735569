import { useTranslation } from 'react-i18next';
import { RichTextEditor } from '@knack/asterisk-react';

import { type RichTextView } from '@/types/schema/views/RichTextView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

export function RichTextSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<RichTextView>();
  const { updatePage } = usePageEditorContext();

  return (
    <CollapsiblePanel.Content title={t('pages.element_settings.rich_text.heading')}>
      <RichTextEditor
        onUpdate={({ editor }) => {
          updatePage({
            type: 'view',
            origin: 'builder',
            action: 'update',
            viewKey: view.key,
            viewSchema: {
              content: editor.getHTML()
            }
          });
        }}
        content={view.content}
      />
    </CollapsiblePanel.Content>
  );
}
