import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@knack/asterisk-react';

import { useImportGoogleSheetsMutation } from '@/hooks/api/mutations/useImportGoogleSheetsMutation';
import { useImportFileMutation } from '@/hooks/api/mutations/useImportMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { TABLE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { Import } from '@/components/import/Import';
import { ImportStep } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { getSerializedNewColumns } from '@/components/import/utils';
import { WizardLayout } from '@/components/layout/WizardLayout';
import { SPREADSHEET_FORMATS } from '@/pages/add-table/useGooglePickerApi';
import { hasUploadedFileChanged } from '@/pages/import-records/isUploadedFileChanged';
import GoogleSheetsIcon from '@/assets/svg/google-sheets.svg';
import { DataSource, type SelectedDataSource } from './types';

function EditableTableName({
  isGoogleSheetFile,
  hasExistingNameError,
  setHasExistingNameError
}: {
  isGoogleSheetFile: boolean;
  hasExistingNameError: boolean;
  setHasExistingNameError: (value: boolean) => void;
}) {
  const { fileName, setFileName } = useImportStore((state) => ({
    fileName: state.fileName,
    setFileName: state.setFileName
  }));
  const [name, setName] = useState(fileName);

  useEffect(() => {
    setName(fileName.slice(0, TABLE_NAME_MAX_CHAR_LENGTH));
  }, [fileName]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.currentTarget.value.slice(0, TABLE_NAME_MAX_CHAR_LENGTH);
    setName(newName);
    setFileName(newName);
    if (hasExistingNameError) setHasExistingNameError(false);
  };

  return (
    <div className="flex items-center">
      {isGoogleSheetFile && (
        <img src={GoogleSheetsIcon} alt="Google Sheets" className="icon-style ml-2 size-6" />
      )}
      <Input
        data-testid="file-name-input"
        value={name}
        intent={hasExistingNameError ? 'destructive' : 'default'}
        className="ml-2 text-sm"
        onChange={handleNameChange}
        onBlur={() => setFileName(name)}
      />
    </div>
  );
}

export function CreateTableFromFileImport({
  onBack,
  selectedDataSource,
  googleAccessToken,
  googleSheetRowCountMap
}: {
  onBack: () => void;
  selectedDataSource: SelectedDataSource;
  googleAccessToken?: string;
  googleSheetRowCountMap?: Record<string, number>;
}) {
  const [t] = useTranslation();
  const {
    totalRecords,
    hasHeaderRow,
    columns,
    columnVisibility,
    fileName,
    getTotalVisibleColumns,
    getSelectedSheetIndex,
    shouldSkipRecordsWithErrors,
    hasPendingUploads,
    setTotalRecords
  } = useImportStore((state) => ({
    totalRecords: state.totalRecords,
    hasHeaderRow: state.hasHeaderRow,
    columns: state.columns,
    columnVisibility: state.columnVisibility,
    fileName: state.fileName,
    shouldSkipRecordsWithErrors: state.shouldSkipRecordsWithErrors,
    getTotalVisibleColumns: state.getTotalVisibleColumns,
    setFileName: state.setFileName,
    getSelectedSheetIndex: state.getSelectedSheetIndex,
    hasPendingUploads: state.hasPendingUploads,
    setTotalRecords: state.setTotalRecords
  }));

  const importFile = useImportFileMutation();
  const importGoogleSheet = useImportGoogleSheetsMutation();
  const [hasExistingNameError, setHasExistingNameError] = useState(false);

  const [step, setStep] = useState(ImportStep.FieldMapping);

  const presentErrorToast = useErrorToast();

  if (!selectedDataSource || selectedDataSource.type !== DataSource.import) return null;

  const { file, workbook } = selectedDataSource;

  const handleGoBack = () => {
    if (step === ImportStep.ConfirmImport) {
      setStep(ImportStep.FieldMapping);
    } else if (step === ImportStep.FieldMapping) {
      onBack();
    }
  };

  const mutationCallbacks = {
    onError: async (error) => {
      if (file instanceof File && (await hasUploadedFileChanged(file))) {
        presentErrorToast({
          error: [
            {
              message: t('components.add_table.errors.file_modified'),
              errorCode: 'file_modified'
            }
          ],
          fallbackKey: 'components.add_table.errors.file_modified',
          translationPrefix: 'components.add_table.errors'
        });

        onBack();
        return;
      }

      presentErrorToast({
        error,
        fallbackKey: 'components.add_table.errors.data_import',
        translationPrefix: 'components.add_table.errors'
      });
      if (error.response.data.errorCode === 'table_name_unique') {
        setHasExistingNameError(true);
      }
    }
  };

  const isGoogleSheetFile =
    (file as google.picker.DocumentObject).mimeType === SPREADSHEET_FORMATS.googleSheets;

  const handleImportSubmit = () => {
    if (step === ImportStep.FieldMapping) {
      setStep(ImportStep.ConfirmImport);
      return;
    }
    const selectedColumns = columns.filter((column, index) => {
      const { isThisColumnMapped, mappedColumnIndex } = column.meta;

      const isVisibleAndNotMapped = columnVisibility[index] && !isThisColumnMapped;

      const isVisibleAndMappedToSelf =
        columnVisibility[index] &&
        isThisColumnMapped &&
        mappedColumnIndex !== undefined &&
        mappedColumnIndex === column.accessorKey;

      return isVisibleAndNotMapped || isVisibleAndMappedToSelf;
    });
    const serializedNewColumns = getSerializedNewColumns(selectedColumns);
    const match = { updateRecords: false, insertUnmatched: true, rules: [] };
    if (isGoogleSheetFile && googleAccessToken) {
      importGoogleSheet.mutate(
        {
          hasHeaderRow,
          match,
          file: file as google.picker.DocumentObject,
          serializedNewColumns,
          fileName,
          token: googleAccessToken,
          selectedSheetIndex: getSelectedSheetIndex(),
          shouldSkipRecordsWithErrors,
          spreadsheetId: (file as google.picker.DocumentObject).id
        },
        {
          onError: mutationCallbacks.onError
        }
      );
    } else if (file instanceof File) {
      importFile.mutate(
        {
          hasHeaderRow,
          match,
          file,
          serializedNewColumns,
          fileName,
          selectedSheetIndex: getSelectedSheetIndex(),
          shouldSkipRecordsWithErrors,
          shouldRedirectAfterImport: true
        },
        {
          onError: mutationCallbacks.onError
        }
      );
    }
  };

  const getRowCount = (): number => {
    if (isGoogleSheetFile && googleSheetRowCountMap) {
      const googleSheetRowCountArray = Object.values(googleSheetRowCountMap);
      const selectedRowCount = googleSheetRowCountArray[getSelectedSheetIndex()];
      if (hasHeaderRow) {
        setTotalRecords(selectedRowCount - 1);
        return selectedRowCount - 1;
      }
      setTotalRecords(selectedRowCount);
      return selectedRowCount;
    }
    return totalRecords;
  };

  return (
    <>
      <WizardLayout
        title={t('components.add_table.import_title')}
        contentWidth="full"
        onClose={onBack}
        headerContent={
          <EditableTableName
            isGoogleSheetFile={isGoogleSheetFile}
            hasExistingNameError={hasExistingNameError}
            setHasExistingNameError={setHasExistingNameError}
          />
        }
        dataTestid="import-records-page-wizard-layout"
      >
        <Import file={file} workbook={workbook} step={step} />
      </WizardLayout>
      <div className="fixed bottom-0 flex h-16 w-full items-center justify-center bg-base px-7 shadow-sm">
        <span className="text-subtle" data-testid="footer-step">
          {t('components.add_table.step', {
            step1: step === ImportStep.ConfirmImport ? 3 : step,
            step2: 3
          })}
        </span>
        <div className="fixed right-0 flex items-center">
          <span className="mr-4 text-subtle" data-testid="total-records-importing">
            {t('components.add_table.importing_records', { count: getRowCount() })}
          </span>
          <Button intent="secondary" onClick={handleGoBack} data-testid="go-back-button">
            {t('actions.back')}
          </Button>
          <Button
            className="mx-4"
            onClick={handleImportSubmit}
            isLoading={importFile.isPending || importGoogleSheet.isPending}
            disabled={
              importFile.isPending ||
              importGoogleSheet.isPending ||
              getTotalVisibleColumns() === 0 ||
              !fileName ||
              hasPendingUploads
            }
            data-testid="import-file-button"
          >
            {t(step === ImportStep.ConfirmImport ? 'actions.import' : 'actions.next')}
          </Button>
        </div>
      </div>
    </>
  );
}
