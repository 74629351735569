import { useState } from 'react';
import { Trans } from 'react-i18next';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiPaintBrush as StylingIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type DetailsView } from '@/types/schema/views/DetailsView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { DetailsGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/details/DetailsGeneralSettingsCategory';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type DetailsSettingsCategoryId = 'general-settings' | 'data-display' | 'actions' | 'styling';
type DetailsSettingsCategoryItem = ViewSettingsCategoryItem<DetailsSettingsCategoryId>;

function getDetailsSettingsCategories() {
  const DETAILS_SETTINGS_CATEGORIES: DetailsSettingsCategoryItem[] = [
    {
      id: 'general-settings',
      name: t('pages.element_settings.details.categories.general_settings.name'),
      description: t('pages.element_settings.details.categories.general_settings.description'),
      icon: GeneralSettingsIcon
    },
    {
      id: 'data-display',
      name: t('pages.element_settings.details.categories.data_display.name'),
      description: t('pages.element_settings.details.categories.data_display.description'),
      icon: FieldsIcon,
      isComingSoon: true
    },
    {
      id: 'actions',
      name: t('pages.element_settings.details.categories.actions.name'),
      description: t('pages.element_settings.details.categories.actions.description'),
      icon: ColumnsIcon,
      isComingSoon: true
    },
    {
      id: 'styling',
      name: t('pages.element_settings.details.categories.styling.name'),
      description: t('pages.element_settings.details.categories.styling.description'),
      icon: StylingIcon,
      isComingSoon: true
    }
  ];

  return DETAILS_SETTINGS_CATEGORIES;
}

function getPanelTitle(
  activeCategoryId: DetailsSettingsCategoryId | null,
  categories: DetailsSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.details.heading');
}

function SourceBanner() {
  const { view } = useActiveViewContext<DetailsView>();
  return (
    <div className="mb-4 rounded-md bg-subtle p-4 text-xs">
      <Trans
        i18nKey="pages.element_settings.details.categories.this_details_displays"
        values={{ tableName: view.name }}
      >
        <span className="font-semibold">tableName</span>
      </Trans>
    </div>
  );
}

export function DetailsSettings() {
  const [activeCategoryId, setActiveCategoryId] = useState<DetailsSettingsCategoryId | null>(null);

  const detailsSettingsCategories = getDetailsSettingsCategories();
  const panelTitle = getPanelTitle(activeCategoryId, detailsSettingsCategories);

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
      shouldHideDivider={activeCategoryId === 'data-display'}
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && <DetailsGeneralSettingsCategory />}
          {activeCategoryId === 'data-display' && <p>Data Display Coming Soon</p>}
          {activeCategoryId === 'actions' && <p>Actions Coming Soon</p>}
          {activeCategoryId === 'styling' && <p>Styling Coming Soon</p>}
        </>
      ) : (
        <>
          <SourceBanner />
          <ViewSettingsCategoryList
            categories={detailsSettingsCategories}
            onCategorySelect={(selectedCategoryId: string) => {
              setActiveCategoryId(selectedCategoryId as DetailsSettingsCategoryId);
            }}
          />
        </>
      )}
    </CollapsiblePanel.Content>
  );
}
