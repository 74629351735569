import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  Form,
  RichTextEditor,
  Tabs,
  type TabsListItem
} from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import { RoleSettingsTree } from '@/pages/roles/role-settings/RoleSettingsTree';
import { TableSettingsForm } from '@/pages/tables/tables-tree/table-dropdown-menu/TableSettingsForm';
import { ROUTES } from '@/Router';

export type RoleSettingsModalOutletContext = {
  object: KnackObject;
};

function Template() {
  const [t] = useTranslation();
  const templateSchema = z.object({
    template: z.string().min(1, t('components.dialogs.roles.settings.template_required'))
  });

  type TemplateSchema = z.infer<typeof templateSchema>;

  const formMethods = useForm<TemplateSchema>({
    defaultValues: {
      template: 'This is the default message to replace'
    },
    resolver: zodResolver(templateSchema)
  });

  const { setValue, handleSubmit, getValues, formState, reset } = formMethods;
  const onFormSubmit = (data: TemplateSchema) => {
    // Coming soon FE-3482
    console.log(data);
  };

  const handleResetButton = () => {
    reset({
      template: 'This is the default message to replace'
    });
  };
  // Coming soon FE-3482
  return <p>Coming soon...</p>;

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <RichTextEditor
          data-testid="template"
          content={getValues('template')}
          onUpdate={({ editor }) => {
            const content = editor.isEmpty ? '' : editor.getHTML();
            setValue('template', content, { shouldDirty: true });
          }}
        />
      </Form>
      {formState.isDirty && (
        <div className="mt-4">
          <Button intent="destructive" onClick={handleResetButton}>
            {t('components.dialogs.roles.settings.reset_to_default_template')}
          </Button>
        </div>
      )}

      <div className="mt-4 flex justify-end">
        <Button type="submit" data-testid="templates-confirm-button">
          {t('actions.save')}
        </Button>
      </div>
    </FormProvider>
  );
}

function RoleSettingsDialogContent({
  defaultTable,
  selectedSetting
}: {
  defaultTable: KnackObject;
  selectedSetting: string;
}) {
  const [t] = useTranslation();
  const [table, setTable] = useState<KnackObject>(defaultTable);
  const navigate = useNavigate();
  const tableKey = defaultTable.key;

  const closeDialog = () => {
    navigate(generatePath(ROUTES.ROLES_ID, { id: tableKey }));
  };

  const items: TabsListItem[] = [
    {
      children: <span>{t('components.dialogs.roles.settings.settings')}</span>,
      value: 'edit'
    },
    {
      children: <span>{t('components.dialogs.roles.settings.invite_template')}</span>,
      value: 'invite-template'
    },
    {
      children: <span>{t('components.dialogs.roles.settings.approval_template')}</span>,
      value: 'approval-template'
    },
    {
      children: <span>{t('components.dialogs.roles.settings.account_info_template')}</span>,
      value: 'account-info-template'
    }
  ];

  const handleTabChange = (value: string) => {
    navigate(generatePath(ROUTES.ROLES_ID_SETTINGS_SETTING_ID, { id: tableKey, settingId: value }));
  };

  if (!table) return null;

  return (
    <Dialog open onOpenChange={closeDialog}>
      <Dialog.Content width="lg">
        <Dialog.MainContent>
          <div className="space-y-4">
            <Dialog.Header>
              <Dialog.Title>{t('components.dialogs.roles.settings.title')}</Dialog.Title>
            </Dialog.Header>
            <Tabs defaultValue={selectedSetting} onValueChange={handleTabChange}>
              <Tabs.List items={items} intent="page" shouldDisableResponsive shouldUseGradient />
              {selectedSetting === 'edit' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <Button intent="secondary" className="w-full" disabled>
                      <PlusIcon size={20} data-testid="add-data-icon" className="mr-1" />
                      {t('components.dialogs.roles.settings.user_role')}
                    </Button>
                    <div>
                      <RoleSettingsTree table={table} setTable={setTable} />
                    </div>
                  </div>
                  <div className="w-2/3">
                    <TableSettingsForm key={table.key} table={table} onSave={closeDialog} />
                  </div>
                </div>
              )}
              {selectedSetting === 'invite-template' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <span className="text-subtle">
                      {t('components.dialogs.roles.settings.invite_templates')}
                    </span>
                    <RoleSettingsTree
                      table={table}
                      setTable={setTable}
                      allUsersNameOverride="Default"
                    />
                  </div>
                  <div className="w-2/3">
                    <Template />
                  </div>
                </div>
              )}
              {selectedSetting === 'approval-template' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <span className="text-subtle">
                      {t('components.dialogs.roles.settings.invite_templates')}
                    </span>
                    <RoleSettingsTree
                      table={table}
                      setTable={setTable}
                      allUsersNameOverride="Default"
                    />
                  </div>
                  <div className="w-2/3">
                    <Template />
                  </div>
                </div>
              )}
              {selectedSetting === 'account-info-template' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <span className="text-subtle">
                      {t('components.dialogs.roles.settings.invite_templates')}
                    </span>
                    <RoleSettingsTree
                      table={table}
                      setTable={setTable}
                      allUsersNameOverride="Default"
                    />
                  </div>
                  <div className="w-2/3">
                    <Template />
                  </div>
                </div>
              )}
            </Tabs>
          </div>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}

export function RoleSettingsDialog() {
  const { id, settingId } = useParams();
  const { object } = useOutletContext<RoleSettingsModalOutletContext>();
  if (!object || !settingId) {
    return null;
  }

  if (object.key !== id) {
    return null;
  }

  return <RoleSettingsDialogContent defaultTable={object} selectedSetting={settingId} />;
}
