import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiBolt as TasksIcon } from 'react-icons/hi2';
import {
  MdPlaylistAddCheck as ChecklistIcon,
  MdCallSplit as ConditionalIcon
} from 'react-icons/md';
import { PiPlugs as ConnectionsIcon } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useTasksQuery } from '@/hooks/api/queries/useTasksQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { DataModelIcon } from '@/components/DataModelIcon';
import { RightSidebar } from '@/components/layout/RightSidebar';
import { SidePanel } from '@/components/SidePanel';
import { useTablesPageContext } from '@/pages/tables/TablesPageContext';
import { ROUTES, type PageUrlParams } from '@/Router';
import { ConnectionsPanel } from './connections/ConnectionsPanel';
import { ConditionalRulesPanel } from './rules/conditional-rules/ConditionalRulesPanel';
import { ValidationRulesPanel } from './rules/validation-rules/ValidationRulesPanel';
import { TasksPanel } from './tasks/TasksPanel';

export type ActiveSidebarItemType =
  | 'connections'
  | 'validationRules'
  | 'conditionalRules'
  | 'tasks'
  | '';

const getSidebarItems = (accountLevel: number = 0) => [
  {
    id: 'connections',
    icon: ConnectionsIcon,
    tooltipI18nKey: 'components.data_table.right_sidebar.connections.title',
    isPublicFeature: true
  },
  {
    id: 'validationRules',
    icon: ChecklistIcon,
    tooltipI18nKey: 'components.data_table.right_sidebar.validation_rules.title',
    isPublicFeature: true
  },
  {
    id: 'conditionalRules',
    icon: ConditionalIcon,
    tooltipI18nKey: 'components.data_table.right_sidebar.conditional_rules.title',
    isPublicFeature: true
  },
  ...(accountLevel > 1
    ? [
        {
          id: 'tasks',
          icon: TasksIcon,
          tooltipI18nKey: 'components.data_table.right_sidebar.tasks.title',
          isPublicFeature: false
        }
      ]
    : []),
  {
    id: 'dataModel',
    icon: DataModelIcon,
    // Query param to track origin of navigation
    link: `${ROUTES.DATA_MODEL}?origin=builder-next`,
    tooltipI18nKey: 'components.data_table.right_sidebar.data_model.title',
    isPublicFeature: true
  }
];

export function ToolkitSidebar() {
  const [t] = useTranslation();
  const { id: tableKey } = useParams<PageUrlParams>();
  const { data: application, isLoading: isApplicationLoading } = useApplicationQuery();
  const { data: tasks } = useTasksQuery();

  const {
    activeToolkitSidebarItem,
    isToolkitSidebarOpen,
    setIsToolkitSidebarOpen,
    setActiveToolkitSidebarItem,
    handleSelectToolkitSidebarItem
  } = useTablesPageContext();

  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  const table = useMemo(
    () => application?.objects.find((o) => o.key === tableKey),
    [application, tableKey]
  );

  const tableTasks =
    useMemo(() => tasks?.filter((task) => task.object_key === tableKey), [tasks, tableKey]) || [];

  if (isApplicationLoading || !table) {
    return null; // TODO: FE-1944 - what do design and product want to display in this case, if anything.
  }

  const hasAccessToNonPublicFeatures =
    import.meta.env.DEV || !!application?.account.isInternalAccount;

  return (
    <RightSidebar>
      <SidePanel
        open={isToolkitSidebarOpen}
        modal={false}
        onOpenChange={(isOpen) => {
          setIsToolkitSidebarOpen(isOpen);
          if (!isOpen) {
            setActiveToolkitSidebarItem('');
          }
        }}
      >
        {getSidebarItems(application?.account.productPlan.level).map(
          ({ id, icon, tooltipI18nKey, link, isPublicFeature }) => {
            const isDisabled =
              (!hasAccessToNonPublicFeatures && !isPublicFeature) ||
              (isDraftModeEnabled && id !== 'dataModel');

            return (
              <RightSidebar.Item
                key={id}
                dataTestId={`right-sidebar-item-${id}`}
                icon={icon}
                tooltipText={t(tooltipI18nKey)}
                onClick={() => handleSelectToolkitSidebarItem(id as ActiveSidebarItemType)}
                link={link}
                isActive={activeToolkitSidebarItem === id}
                isDisabled={isDisabled}
                isDraftModeEnabled={isDraftModeEnabled}
              />
            );
          }
        )}
        <SidePanel.Content
          className="top-16 z-10 mr-14 mt-px h-[calc(100%-64px)] rounded-tl-lg bg-base p-4"
          data-testid="toolkit-panel-content"
          onInteractOutside={(e) => e.preventDefault()}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          {activeToolkitSidebarItem === 'connections' && <ConnectionsPanel table={table} />}
          {activeToolkitSidebarItem === 'validationRules' && <ValidationRulesPanel table={table} />}
          {activeToolkitSidebarItem === 'conditionalRules' && (
            <ConditionalRulesPanel table={table} />
          )}
          {activeToolkitSidebarItem === 'tasks' && <TasksPanel table={table} tasks={tableTasks} />}
        </SidePanel.Content>
      </SidePanel>
    </RightSidebar>
  );
}
