import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiBolt as DynamicIcon,
  HiPencil as EditIcon,
  HiLink as LinkIcon,
  HiRectangleStack as ViewIcon
} from 'react-icons/hi2';
import { Badge, Button, Combobox, Dialog, Tooltip } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { VIEW_COLUMN_ID_PREFIX } from '@/types/schema/BuilderView';
import { type TableView } from '@/types/schema/views/TableView';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { getDetailsViewSchemaFromPartialView } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { getFormViewSchemaFromPartialView } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/formViewSchema';
import { baseTableViewColumnSchema } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/tableViewSchema';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

function DisabledActionButtonTooltip({
  isDisabled,
  children
}: {
  isDisabled?: boolean;
  children: React.ReactNode;
}) {
  const [t] = useTranslation();

  if (!isDisabled) {
    return children;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <div>{children}</div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        {t('pages.element_settings.common.categories.actions.this_column_already_exists')}
      </Tooltip.Content>
    </Tooltip>
  );
}

export function TableActionsSettingsCategory() {
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const { page: currentPage } = usePageEditorContext();
  const { data: pages = [] } = usePagesQuery();

  const [isLinkToExitingPageDialogOpen, setIsLinkToExitingPageDialogOpen] = useState(false);
  const [linkToExistingPageSlug, setLinkToExistingPageSlug] = useState({
    key: '',
    label: ''
  });

  const childDetailsView = getDetailsViewSchemaFromPartialView(
    {
      name: `View ${sourceObject.inflections.singular} Details`,
      source: {
        object: sourceObject.key,
        criteria: {
          groups: [],
          match: 'any',
          rules: []
        }
      }
    },
    sourceObject
  );

  const childFormView = getFormViewSchemaFromPartialView(
    {
      action: 'update',
      name: t('views.new_view_defaults.form.title_add', {
        objectName: sourceObject.inflections.singular
      }),
      source: {
        object: sourceObject.key,
        criteria: {
          match: 'any',
          rules: [],
          groups: []
        }
      }
    },
    sourceObject
  );

  const handleClickLinkAction = (isEditAction?: boolean) => {
    const childName = isEditAction
      ? `View ${sourceObject.inflections.singular} Edit`
      : `View ${sourceObject.inflections.singular} Details`;

    const newView = isEditAction ? childFormView : childDetailsView;

    const newChildPageWithDefaultView = {
      name: childName,
      object: sourceObject.key,
      parent: currentPage.slug,
      views: [
        {
          ...newView
        }
      ]
    };

    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
          link_text: isEditAction ? t('actions.edit') : t('actions.view'),
          header: childName,
          type: 'link',
          // We send an object with the partial information of the child page and the server is in charge of creating it
          scene: newChildPageWithDefaultView,
          align: 'center'
        }
      ]
    });
  };

  const handleClickDeleteRecordAction = () => {
    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
          link_text: t('actions.delete'),
          header: t('actions.delete'),
          type: 'delete',
          align: 'center'
        }
      ]
    });
  };

  const handleApplyLinkToExistingPage = () => {
    const selectedPage = pages.find((p) => p.key === linkToExistingPageSlug.key);

    if (!selectedPage) {
      return;
    }

    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
          link_text: selectedPage.name,
          header: selectedPage.name,
          type: 'link',
          scene: selectedPage.slug,
          align: 'center'
        }
      ]
    });

    setIsLinkToExitingPageDialogOpen(false);
  };

  const viewActionList = [
    {
      key: 'edit',
      label: t('pages.element_settings.common.categories.actions.edit_record'),
      icon: EditIcon,
      onClick: () => handleClickLinkAction(true)
    },
    {
      key: 'view',
      label: t('pages.element_settings.common.categories.actions.view_record'),
      icon: ViewIcon,
      onClick: handleClickLinkAction
    },
    {
      key: 'delete',
      label: t('pages.element_settings.common.categories.actions.delete_record'),
      icon: DeleteIcon,
      onClick: handleClickDeleteRecordAction,
      isDisabled: view.columns.some((column) => column.type === 'delete')
    },
    {
      key: 'link',
      label: t('pages.element_settings.common.categories.actions.link_record'),
      icon: LinkIcon,
      onClick: () => setIsLinkToExitingPageDialogOpen(true)
    },
    {
      key: 'dynamic',
      label: t('pages.element_settings.common.categories.actions.dynamic_action'),
      icon: DynamicIcon,
      isComingSoon: true,
      onClick: () => {}
    }
  ];

  const getParentPageName = (parentSlug: string | null) => {
    const parentPage = pages.find((p) => p.slug === parentSlug);
    return parentPage?.name || parentSlug;
  };

  // A list of existing pages that share the same source object as the original object
  const childPagesWithSameSourceObject = pages
    .filter((p) => p.parentSlug && p.sourceObjectKey === sourceObject.key)
    .map((p) => ({
      key: p.key,
      label: `${getParentPageName(p.parentSlug)} > ${p.name}`
    }));

  return (
    <div className="space-y-2">
      <p>{t('pages.element_settings.common.categories.actions.select_action_type')}</p>
      {viewActionList.map((action) => (
        <DisabledActionButtonTooltip key={action.key} isDisabled={action.isDisabled}>
          <Button
            intent="secondary"
            disabled={action.isComingSoon || action.isDisabled}
            className="w-full justify-start gap-2 font-normal hover:bg-muted"
            onClick={() => action.onClick()}
          >
            <Button.Icon icon={action.icon} />
            {action.label}
            {action.isComingSoon && <Badge>{t('keywords.coming_soon')}</Badge>}
          </Button>
        </DisabledActionButtonTooltip>
      ))}
      <Dialog open={isLinkToExitingPageDialogOpen} onOpenChange={setIsLinkToExitingPageDialogOpen}>
        <Dialog.Content>
          <Dialog.MainContent>
            <Dialog.Header>
              <Dialog.Title>
                {t('pages.element_settings.common.categories.actions.select_page')}
              </Dialog.Title>
              <Dialog.Description>
                {t('pages.element_settings.common.categories.actions.select_page_description')}
              </Dialog.Description>
            </Dialog.Header>
            <Combobox
              id="link-to-existing-page"
              selectedOption={linkToExistingPageSlug || childPagesWithSameSourceObject[0]}
              options={childPagesWithSameSourceObject}
              onSelectOption={(option) => setLinkToExistingPageSlug(option)}
              triggerClassName="mt-4 w-full"
              isSearchEnabled
            />
          </Dialog.MainContent>
          <Dialog.Footer>
            <Dialog.Close asChild>
              <Button intent="secondary">{t('actions.cancel')}</Button>
            </Dialog.Close>
            <Button intent="primary" onClick={handleApplyLinkToExistingPage}>
              {t('actions.apply')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
