import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, Switch } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { DefaultValue as BooleanDefaultValue } from '@/components/field-settings/boolean/DefaultValue';
import { DefaultValue as ConnectionDefaultValue } from '@/components/field-settings/connection/DefaultValue';
import { DefaultValue as MultipleChoiceDefaultValue } from '@/components/field-settings/multiple-choice/DefaultValue';
import { DefaultValue as PhoneDefaultValue } from '@/components/field-settings/phone/DefaultValue';

type DefaultValueProps = {
  defaultValue: any;
  fieldType: KnackFieldType;
};

export function DefaultValue({ fieldType, defaultValue }: DefaultValueProps) {
  const [t] = useTranslation();

  const [showEditDefaultValue, setShowEditDefaultValue] = useState(!!defaultValue || false);

  const { setValue, register } = useFormContext();

  if (fieldType === 'boolean') {
    return <BooleanDefaultValue defaultValue={defaultValue?.default} />;
  }

  if (fieldType === 'phone') {
    return <PhoneDefaultValue defaultValue={defaultValue} />;
  }

  if (fieldType === 'multiple_choice') {
    return <MultipleChoiceDefaultValue />;
  }

  if (fieldType === 'connection') {
    return <ConnectionDefaultValue />;
  }

  const allowOnlyNumbers = fieldType === 'number' || fieldType === 'currency';

  return (
    <div className="flex">
      <Switch
        className="mr-2"
        data-testid="default-value-switch"
        checked={showEditDefaultValue}
        onCheckedChange={() => {
          if (showEditDefaultValue) {
            setValue('default', '');
          }
          setShowEditDefaultValue(!showEditDefaultValue);
        }}
      />
      <div className="flex flex-grow flex-col">
        <Label htmlFor="field-settings-default-value-input">
          {t('components.data_table.attributes.field_settings.common.add_default_value')}
        </Label>
        {showEditDefaultValue && (
          // TODO: Add iMask for Number and Currency fields FE-2670: https://knack.atlassian.net/browse/FE-2670
          <Input
            type={allowOnlyNumbers ? 'number' : 'text'}
            step={allowOnlyNumbers ? 'any' : undefined}
            id="field-settings-default-value-input"
            className={cn('mt-2 w-full rounded-lg', {
              '[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none':
                allowOnlyNumbers
            })}
            data-testid="default-value-input"
            placeholder={t('components.data_table.attributes.field_settings.common.default_value')}
            {...register('default')}
          />
        )}
      </div>
    </div>
  );
}
