import { type BuilderViewBase, type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';

export interface ReportView extends BuilderViewBase {
  type: 'report';
  source: BuilderViewSourceSchema;
  columns: unknown[];
  inputs: unknown[];
  links: unknown[];
  rows: ReportViewRow[];
}

export type ReportViewRow = {
  reports: ReportViewChart[];
  layout?: 'one-col' | 'half' | 'thirds';
};

export const CHART_TYPES = ['bar', 'pivot', 'pie', 'line', 'area'] as const;
export type ChartType = (typeof CHART_TYPES)[number];

export type BarType = 'bar' | 'column';
export type PieType = 'filled' | 'donut';
export type LegendPosition = 'bottom' | 'left' | 'right' | 'none';
export type StackingType = 'none' | 'normal' | 'percent';
export type ColorOptions = 'monochromatic' | 'high-contrast';
export type LineType = 'curved' | 'straight';
export type LabelPosition = 'inside' | 'outside' | 'outside_with_line';

export type ReportViewChart = {
  title: string;
  type: ChartType;
  description: string;
  source: BuilderViewSourceSchema;
  filters: ReportViewFilters;
  groups: ReportViewChartGroup[];
  calculations: ReportViewCalculation[];
  options: {
    exclude_empties: boolean;
    hide_negatives: boolean;
    child_records: boolean;
    export_links: boolean;
    shouldShowDataTable: boolean;
  };
  layout: {
    dimensions: string;
    chart_width: string;
    chart_height: string;
    legend_width: string;
    legend: LegendPosition;
    stacking: StackingType;
    tilt_labels: boolean;
    data_labels: boolean;
    bar_type: BarType;
    pie_type?: PieType;
    line_type?: LineType;
    label_position?: LabelPosition;
    shouldShowTotal?: boolean;
    colors?: ColorOptions;
  };
  preview: boolean;
  settings: {
    export_links: boolean;
    shouldShowDataTable: boolean;
  };
};

type ReportViewFilters = {
  filter_type: 'fields' | 'menu' | 'none';
  preset_filters: KnackFilter[];
  menu_filters: KnackMenuFilter[];
  filter_fields: 'view' | 'object';
  allow_preset_filters: boolean;
};

type ReportViewChartGroup = {
  group: string;
  type: KnackFieldType;
  field: KnackFieldKey;
  label: string;
};

type ReportViewCalculation = {
  calculation: 'total' | 'average' | 'min' | 'max' | 'sum' | 'count';
  field: string;
  label: string;
  displayLabel?: string;
};
