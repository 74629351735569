import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackRecord } from '@/types/schema/KnackRecord';
import { ApiWrapper } from '@/utils/api-wrapper';
import { type DataTableRow } from '@/components/data-table/display/types';
import { type KnackRecordsResponse } from '@/definitions/api-wrapper/types';

export type RuleFormat = {
  field: KnackFieldKey;
  operator: string;
  value: any;
  type?: string;
  range?: string;
  anyDate?: boolean;
  anyTime?: boolean;
};

export type FilterFormat = {
  match: 'and' | 'or';
  rules: RuleFormat[];
};

export interface RecordsOptions {
  filters?: FilterFormat;
  search?: string;
  page?: number;
  recordsPerPage?: number;
  sortField?: string;
  sortOrder?: string;
}

export interface RecordsPayload extends RecordsOptions {
  objectKey: string;
}
export const convertKnackRecordToDataTableRow = (record: KnackRecord): DataTableRow => {
  const recordReturn: DataTableRow = {
    id: record.id,
    fields: {}
  };

  Object.keys(record).forEach((key) => {
    if (key.endsWith('_raw')) {
      const fieldKey = key.replace('_raw', '');
      recordReturn.fields[fieldKey] = {
        value: record[fieldKey],
        rawValue: record[key]
      };
    }
  });
  return recordReturn;
};

export const convertKnackRecordsToDataTableRow = (records: KnackRecord[]): DataTableRow[] => {
  const dataTableRows = records.map((record) => convertKnackRecordToDataTableRow(record));
  return dataTableRows;
};

export async function getRecords({
  objectKey,
  filters,
  search,
  page,
  recordsPerPage,
  sortField,
  sortOrder
}: RecordsPayload): Promise<Omit<KnackRecordsResponse, 'records'> & { records: DataTableRow[] }> {
  // Instead of returning the raw data, we will return the data in a format that is easier to use
  const data = await ApiWrapper.getRecords(
    objectKey,
    filters,
    search,
    page,
    recordsPerPage,
    sortField,
    sortOrder
  );

  return { ...data, records: convertKnackRecordsToDataTableRow(data.records) };
}
