import { Fragment } from 'react';

import { type DateTimeFieldConditionalRuleCriteria } from '@/types/schema/fields';
import { type ConditionalRuleCriteria, type KnackField } from '@/types/schema/KnackField';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { getBooleanFieldLabel } from '@/utils/fields';
import { getDateTimeConditionalRuleCriteriaValue } from '@/utils/rules/getDateTimeConditionalRuleCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface ConditionalRuleCriteriaValueProps {
  criteria: ConditionalRuleCriteria;
  tableFields: KnackField[];
  dataTestId?: string;
  className?: string;
}

export function ConditionalRuleCriteriaValue({
  criteria,
  tableFields,
  dataTestId,
  className
}: ConditionalRuleCriteriaValueProps) {
  if (shouldHideValueBasedOnOperator(criteria.operator)) return null;

  // If the criteria value type is a field, we just need to get the field and display it
  if (criteria.value_type === 'field') {
    const criteriaValueField = tableFields.find((f) => f.key === criteria.value_field);
    if (!criteriaValueField) return null;

    return (
      <>
        {' '}
        <InlineKnackField
          fieldType={criteriaValueField.type}
          fieldName={criteriaValueField.name}
          className={className}
        />
      </>
    );
  }

  // If the criteria value is not a string, then we need to get the criteria field
  const criteriaField = tableFields.find((f) => f.key === criteria.field);

  // If the criteria value is an array, it means the criteria field is a connection field, so we need to display the record value
  if (criteriaField?.type === 'connection' && Array.isArray(criteria.value)) {
    return criteria.value.map((value, valueIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${value}-${valueIndex}`}>
        {' '}
        <InlineKnackRecordValue
          tableKey={criteriaField.relationship.object}
          recordId={value.id}
          dataTestId={dataTestId}
        />
      </Fragment>
    ));
  }

  let valueToReturn: string | undefined;

  // If the criteria value is a boolean, we need the criteria field's format in order to display the boolean label
  if (criteriaField?.type === 'boolean') {
    if (typeof criteria.value === 'boolean') {
      valueToReturn = getBooleanFieldLabel(criteriaField.format, criteria.value);
    }
    // This is necessary to account for a schema bug where `false` boolean values are stored as empty strings in some scenarios
    if (typeof criteria.value === 'string') {
      valueToReturn = getBooleanFieldLabel(criteriaField.format, false);
    }
  } else if (criteriaField?.type === 'date_time') {
    valueToReturn = getDateTimeConditionalRuleCriteriaValue(
      criteria as DateTimeFieldConditionalRuleCriteria
    );
  } else if (typeof criteria.value === 'string') {
    valueToReturn = criteria.value;
  }

  if (!valueToReturn) {
    return null;
  }

  return (
    <>
      {' '}
      <InlineKnackValue value={valueToReturn} data-testid={dataTestId} className={className} />
    </>
  );
}
