/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { Button } from '@knack/asterisk-react';

import { type ReportView } from '@/types/schema/views/ReportView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ReportAxisSettings } from '@/pages/pages/settings-panel/view-settings/report/ReportAxisSettings';
import { ReportTableInfo } from '@/pages/pages/settings-panel/view-settings/report/ReportTableInfo';

export function ReportDataDisplay() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));

  return hasAccessToNonPublicFeatures ? (
    <>
      <ReportTableInfo view={view} />
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.report.categories.data_display.source_filters')}
        >
          <div className="mb-4">
            <div className="mb-2">
              {t('pages.element_settings.report.categories.data_display.all_records_are_shown')}
            </div>
            <Button intent="secondary">
              <PlusIcon size={16} className="mr-1" />
              {t('pages.element_settings.report.categories.data_display.filter')}
            </Button>
          </div>
        </BuilderAccordion.Item>
        <ReportAxisSettings />
        <ReportAxisSettings isCalculations />
      </BuilderAccordion>
    </>
  ) : (
    <div className="text-sm text-subtle">{t('keywords.coming_soon')}</div>
  );
}
