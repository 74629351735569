import { type ComponentPropsWithoutRef } from 'react';
import { Trans } from 'react-i18next';

import { cn } from '@/utils/tailwind';

export function DisclosureLinks({ className }: ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={cn('text-center', className)}>
      <p>
        <Trans
          i18nKey="components.add_table.view_imports_guide"
          components={[
            <a
              href="https://learn.knack.com/article/ejdb2toq4i-import-records"
              className="underline"
              key="viewImportsGuideLink"
            >
              Import Guide Link
            </a>
          ]}
        />
      </p>
      <p>
        <Trans
          i18nKey="components.add_table.limited_use_disclosure"
          components={[
            <a
              href="https://learn.knack.com/article/limited-use-disclosure"
              className="underline"
              key="limitedUseDisclosureLink"
            >
              Limited Use Disclosure Link
            </a>
          ]}
        />
      </p>
    </div>
  );
}
