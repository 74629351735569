import { type BuilderViewSourceSchema, type KnackViewType } from '@/types/schema/BuilderView';
import { type ReportViewChart } from '@/types/schema/views/ReportView';
import {
  type ViewSource,
  type ViewSourcePath
} from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { getDetailsViewSchema } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { getFormViewSchema } from './formViewSchema';
import { getListViewSchema } from './listViewSchema';
import { getMenuViewSchema } from './menuViewSchema';
import { getReportViewSchema } from './reportViewSchema';
import { getRichTextViewSchema } from './richTextSchema';
import { getTableViewSchema } from './tableViewSchema';

type NewViewOptions = {
  viewType: KnackViewType;
  viewSubtype?: ReportViewChart['type'];
  viewSource: ViewSource;
  viewSourcePath: ViewSourcePath;
  viewSourcePathDirectConnectionFieldKey?: string;
  viewSourcePathParentConnectionFieldKey?: string;
};

function generateViewSourceSchema({
  viewType,
  viewSource,
  viewSourcePath,
  viewSourcePathDirectConnectionFieldKey,
  viewSourcePathParentConnectionFieldKey
}: NewViewOptions) {
  const viewSourceSchema: BuilderViewSourceSchema = {
    object: viewSource.object.key,
    criteria: {
      match: 'any',
      rules: [],
      groups: []
    },
    limit: '',
    sort: viewSource.object.sort ? [viewSource.object.sort] : [],
    ...(viewSourcePath.isAuthenticatedUser && { authenticated_user: true })
  };

  if (viewSourcePath.connections?.direct.length) {
    // If an explicit direct connection field key is provided, use that connection, otherwise use the first direct connection
    const directConnectionKey =
      viewSourcePathDirectConnectionFieldKey || viewSourcePath.connections.direct[0].field.key;

    const directConnection = viewSourcePath.connections.direct.find(
      (conn) => conn.field.key === directConnectionKey
    );

    if (directConnection) {
      viewSourceSchema.connection_key = directConnectionKey as `field_${string}`;
      // The relationship type is in the context of the source object (e.g. the parent object, or the logged-in user)
      viewSourceSchema.relationship_type = directConnection.relationshipType;
    }
  }

  if (viewSourcePath.connections?.parent.length) {
    // If an explicit parent connection field key is provided, use that connection, otherwise use the first parent connection
    const parentConnectionKey =
      viewSourcePathParentConnectionFieldKey || viewSourcePath.connections.parent[0].field.key;

    const parentConnection = viewSourcePath.connections.parent.find(
      (conn) => conn.field.key === parentConnectionKey
    );

    if (parentConnection) {
      viewSourceSchema.parent_source = {
        connection: parentConnection.field.key as `field_${string}`,
        object: parentConnection.object.key as `object_${string}`
      };
    }
  }

  if (['table', 'list', 'map', 'calendar', 'search'].includes(viewType)) {
    return {
      ...viewSourceSchema,
      limit: '',
      sort: viewSource.object.sort
        ? [
            {
              field: viewSource.object.sort.field as `field_${string}`,
              order: viewSource.object.sort.order
            }
          ]
        : []
    };
  }

  return viewSourceSchema;
}

export function getNewStaticViewSchema(viewType: KnackViewType) {
  switch (viewType) {
    case 'rich_text':
      return getRichTextViewSchema();
    case 'menu':
      return getMenuViewSchema();
    default:
      throw new Error(`Invalid view type: ${viewType}`);
  }
}

export function getNewViewSchema(newViewOptions: NewViewOptions) {
  const { viewType, viewSource, viewSubtype } = newViewOptions;
  const viewSourceSchema = generateViewSourceSchema(newViewOptions);

  // When adding more view schemas, look at the `buildRecordsView` function in v3 at src/components/pages/page/ViewAdd.vue,
  // which sets some initial properties, then goes through a series of calls like `setView` and `buildViewSchema` to build the view schema
  switch (viewType) {
    case 'form':
      return getFormViewSchema(viewSource, viewSourceSchema);
    case 'table':
      return getTableViewSchema(viewSource, viewSourceSchema);
    case 'list':
      return getListViewSchema(viewSource, viewSourceSchema);
    case 'details':
      return getDetailsViewSchema(viewSource, viewSourceSchema);
    case 'report':
      if (!viewSubtype) {
        throw new Error('Report view subtype is required');
      }
      return getReportViewSchema(viewSource, viewSourceSchema, viewSubtype);
    default:
      // Throwing an error for now to prevent us from adding a new view type without adding the proper schema
      throw new Error(`Invalid view type: ${viewType}`);
  }
}
