import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type BuilderPage, type BuilderPageKey, type PageType } from '@/types/schema/BuilderPage';
import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { type LoginViewRegistrationType } from '@/types/schema/views/LoginView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { type PageChanges } from '@/pages/pages/page-editor/helpers/types';

export type NewPageParams = {
  type: PageType;
  name: string;
  menuPages?: null | BuilderPageKey[];
  isAuthenticated?: boolean;
  parentSlug?: null | string;
};

type UpdatePageParams = {
  updatedPage: BuilderPage;
  pageChanges?: PageChanges;
};

type UpdateDropdownParams = {
  pageKey: string;
  name: string;
  menuPages: null | BuilderPageKey[];
};

type DuplicatePageParams = {
  pageKey: string;
  name: string;
};

interface NewPagePayload {
  authenticated: boolean;
  login_vars: null | {
    allowed_profiles: KnackObjectProfileKey[];
    authenticated: boolean;
    limit_profile_acces: boolean;
    users?: {
      registration: LoginViewRegistrationType;
    };
  };
  menu_pages?: null | BuilderPageKey[];
  name: string;
  type?: PageType;
  parent?: null | string;
  views: [];
}

async function sortPages(order: string[]) {
  const { data } = await axios.post(
    '/v1/scenes/sort',
    { order },
    {
      withCredentials: true
    }
  );
  return data;
}

async function newPage({
  type,
  name,
  menuPages,
  isAuthenticated = false,
  parentSlug = null
}: NewPageParams) {
  const payload: NewPagePayload = {
    authenticated: isAuthenticated,
    login_vars: null,
    menu_pages: null,
    name,
    views: []
  };

  if (type !== 'authentication') {
    payload.type = type;
    payload.parent = null;
  } else {
    payload.login_vars = {
      allowed_profiles: [],
      authenticated: true,
      limit_profile_acces: false,
      users: {
        registration: 'open'
      }
    };
  }

  if (type === 'menu') {
    payload.menu_pages = menuPages;
  }

  if (parentSlug) {
    payload.parent = parentSlug;
  }

  const { data } = await axios.post('/v1/scenes', payload, {
    withCredentials: true
  });
  return data;
}

async function deletePage(pageKey: string) {
  const { data } = await axios.delete(`/v1/scenes/${pageKey}`, {
    withCredentials: true
  });
  return data;
}

async function duplicatePage(pageKey: string, name: string) {
  const { data } = await axios.post(
    `/v1/scenes/${pageKey}/copy`,
    { name },
    {
      withCredentials: true
    }
  );
  return data;
}

async function updateDropdown({ pageKey, name, menuPages }: UpdateDropdownParams) {
  const { data } = await axios.put(
    `/v1/scenes/${pageKey}`,
    { name, menu_pages: menuPages, views: [] },
    {
      withCredentials: true
    }
  );
  return data;
}

async function removeLogin(page: BuilderPage) {
  const views = page.views.map((view) => ({
    ...view,
    _id: view.id,
    limit_profile_access: false
  }));

  const { data } = await axios.put(
    `/v1/scenes/${page.key}`,
    { authenticated: false, views },
    {
      withCredentials: true
    }
  );
  return data;
}

async function updatePage({ updatedPage, pageChanges }: UpdatePageParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  const { data } = await axios.put<{ updatedPage: BuilderPage }>(
    `/v1/builder/${accountSlug}/application/${appSlug}/pages/${updatedPage.key}`,
    { updatedPage, pageChanges },
    {
      withCredentials: true
    }
  );

  return data.updatedPage;
}

export function usePageMutation() {
  const queryClient = useQueryClient();

  return {
    sortMutation: useMutation({
      mutationFn: (order: string[]) => sortPages(order),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    createMutation: useMutation({
      mutationFn: ({ type, name, menuPages, isAuthenticated, parentSlug }: NewPageParams) =>
        newPage({ type, name, menuPages, isAuthenticated, parentSlug }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    updateMutation: useMutation({
      mutationFn: ({ updatedPage, pageChanges }: UpdatePageParams) =>
        updatePage({ updatedPage, pageChanges }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    updateDropdownMutation: useMutation({
      mutationFn: ({ pageKey, name, menuPages }: UpdateDropdownParams) =>
        updateDropdown({ pageKey, name, menuPages }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    deleteMutation: useMutation({
      mutationFn: (pageKey: string) => deletePage(pageKey),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    removeLoginMutation: useMutation({
      mutationFn: (page: BuilderPage) => removeLogin(page),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    duplicateMutation: useMutation({
      mutationFn: ({ pageKey, name }: DuplicatePageParams) => duplicatePage(pageKey, name),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    })
  };
}
