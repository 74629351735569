import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type ReportView } from '@/types/schema/views/ReportView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewSettingsFiltering } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { ReportDataDisplay } from '@/pages/pages/settings-panel/view-settings/report/ReportDataDisplay';
import { ReportGeneral } from '@/pages/pages/settings-panel/view-settings/report/ReportGeneral';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ReportSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));

  const items: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.name')}</span>,
      value: 'general'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.data_display.name')}</span>,
      value: 'data-display'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.filtering.name')}</span>,
      value: 'filtering'
    }
  ];

  const reportViewFilteringOptions = view.rows[0].reports[0].filters;

  const handleUpdateViewSchema = (options) => {
    updateViewSchema({
      rows: [
        {
          reports: [
            {
              ...view.rows[0].reports[0],
              filters: {
                ...view.rows[0].reports[0].filters,
                ...options
              }
            }
          ]
        }
      ]
    });
  };

  return (
    <CollapsiblePanel.Content
      title={t('pages.element_settings.report.heading', {
        chart_type: t(`views.view_names.${view.rows[0].reports[0].type}_chart`)
      })}
      shouldHideDivider
    >
      <div className="pb-4">
        <Tabs defaultValue="general">
          <Tabs.List items={items} intent="page" shouldDisableResponsive shouldUseGradient />
          <Tabs.Content value="general">
            <ReportGeneral />
          </Tabs.Content>
          <Tabs.Content value="data-display">
            <ReportDataDisplay />
          </Tabs.Content>
          {hasAccessToNonPublicFeatures && (
            <Tabs.Content value="filtering">
              <ViewSettingsFiltering
                options={reportViewFilteringOptions}
                updateViewSchema={handleUpdateViewSchema}
              />
            </Tabs.Content>
          )}
        </Tabs>
      </div>
    </CollapsiblePanel.Content>
  );
}
