import { useTranslation } from 'react-i18next';
import { Badge, Input, Label, RichTextEditor, Select, Switch } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import { type TableView } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

const defaultPaginationRowsPerPageNumber = '25';
const paginationRowsPerPageOptions = ['10', defaultPaginationRowsPerPageNumber, '50', '100'];

interface ViewSwitchOptionProps {
  label: string;
  disabled?: boolean;
  checked: boolean;
  isComingSoon?: boolean;
  onCheckedChange?: (value: boolean) => void;
}

interface ViewGeneralSettingsCategoryProps {
  options: {
    key: string;
    label: string;
    disabled?: boolean;
    checked: boolean;
    isComingSoon?: boolean;
  }[];
}

function ViewSwitchOption({
  label,
  disabled,
  checked,
  isComingSoon,
  onCheckedChange
}: ViewSwitchOptionProps) {
  const [t] = useTranslation();

  return (
    <Label className="flex items-center gap-2">
      <Switch checked={checked} disabled={disabled} onCheckedChange={onCheckedChange} />
      {label}
      {isComingSoon && (
        <Badge className="px-2 py-0.5 text-xs text-default">{t('keywords.coming_soon')}</Badge>
      )}
    </Label>
  );
}

export function ViewGeneralSettingsCategory({ options }: ViewGeneralSettingsCategoryProps) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<TableView | ListView>();
  const updateViewSchema = useUpdateView();

  const i18nParams = { viewName: t(`views.view_names.${view.type}`) };

  return (
    <BuilderAccordion>
      <div className="mb-4">
        <span className="mb-2 block text-sm font-medium">
          {t('pages.element_settings.common.categories.general_settings.element_label')}
        </span>
        <Input
          value={view.name ?? ''}
          className="text-sm"
          onChange={(e) => {
            updateViewSchema({
              name: e.target.value
            });
          }}
        />
        <p className="mt-2 text-xs text-subtle">
          {t('pages.element_settings.change_view_name_helper_text')}
        </p>
      </div>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.general.title_and_description')}
      >
        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-title`}>
            {t('pages.element_settings.general.view_title', i18nParams)}
          </Label>
          <Input
            value={view.title ?? ''}
            className="text-sm"
            onChange={(e) => {
              updateViewSchema({
                title: e.target.value
              });
            }}
          />
          <p className="mt-2 text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.general_settings.leave_this_field_blank_title'
            )}
          </p>
        </div>
        <div>
          <span className="mb-2 block text-sm font-medium">
            {t('pages.element_settings.general.view_description', i18nParams)}
          </span>
          <RichTextEditor
            onUpdate={({ editor }) => {
              updateViewSchema({
                description: editor.getText() ? editor.getHTML() : ''
              });
            }}
            content={view.description || ''}
          />
          <p className="mt-2 text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.general_settings.leave_this_field_blank_description'
            )}
          </p>
        </div>
      </BuilderAccordion.Item>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.common.categories.general_settings.user_options')}
      >
        <div className="mb-4 space-y-2">
          {options.map((option) => (
            <ViewSwitchOption
              key={option.key}
              label={option.label}
              disabled={option.disabled}
              checked={option.checked}
              isComingSoon={option.isComingSoon}
              onCheckedChange={(value) => {
                if (option.key === 'cell_editor') {
                  updateViewSchema({
                    options: {
                      cell_editor: value
                    }
                  });
                  return;
                }
                updateViewSchema({
                  [option.key]: value
                });
              }}
            />
          ))}
        </div>
      </BuilderAccordion.Item>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.common.categories.general_settings.pagination')}
      >
        <div className="space-y-2">
          <Label>
            <span className="font-medium">
              {t('pages.element_settings.common.categories.general_settings.default_pagination')}
            </span>
            <Select
              value={view.rows_per_page || defaultPaginationRowsPerPageNumber}
              onValueChange={(value) => {
                updateViewSchema({
                  rows_per_page: value
                });
              }}
            >
              <Select.Trigger className="mt-1 w-full text-sm text-subtle" />
              <Select.Content>
                {paginationRowsPerPageOptions.map((option) => (
                  <Select.Item key={option} className="text-sm" value={option}>
                    {t(
                      'pages.element_settings.common.categories.general_settings.display_record_count',
                      { rowsPerPage: option }
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </Label>
          <ViewSwitchOption
            label={t('pages.element_settings.common.categories.general_settings.allow_pagination')}
            onCheckedChange={(value) =>
              updateViewSchema({
                allow_limit: value
              })
            }
            checked={view.allow_limit}
          />
        </div>
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
