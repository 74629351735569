import { useTranslation } from 'react-i18next';
import { HiDocumentPlus as NewDocumentIcon, HiTableCells as TableIcon } from 'react-icons/hi2';

import GoogleSheetsIcon from '@/assets/svg/google-sheets.svg';
import { AddTableSourceItem } from './AddTableSourceItem';

export function AddTableSourceList({ onClick }: { onClick: (id: string) => void }) {
  const [t] = useTranslation();

  const generalItems = [
    {
      id: 'blank',
      icon: NewDocumentIcon,
      title: t('components.add_table.blank_table')
    },
    {
      id: 'pre-made',
      icon: TableIcon,
      title: t('components.add_table.pre_made_table')
    }
  ];

  return (
    <div data-testid="add-table-list-items" className="my-6">
      <div className="mt-2 flex flex-col gap-2">
        {generalItems.map((item) => (
          <div key={item.id}>
            <AddTableSourceItem
              item={item}
              onClick={onClick}
              data-testid={`add-table-${item.id}`}
            />
          </div>
        ))}
        <AddTableSourceItem
          item={{
            id: 'google-sheets',
            icon: GoogleSheetsIcon,
            title: t('components.add_table.google_sheets')
          }}
          onClick={onClick}
          data-testid="add-table-google-sheets"
        />
      </div>
    </div>
  );
}
