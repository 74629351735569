import { useTranslation } from 'react-i18next';
import { Badge, Input, Label, RichTextEditor, Switch } from '@knack/asterisk-react';

import { type DetailsView } from '@/types/schema/views/DetailsView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface DetailsSwitchOptionProps {
  label: string;
  disabled?: boolean;
  checked: boolean;
  isComingSoon?: boolean;
  onCheckedChange?: (value: boolean) => void;
}

function DetailsSwitchOption({
  label,
  disabled,
  checked,
  isComingSoon,
  onCheckedChange
}: DetailsSwitchOptionProps) {
  const [t] = useTranslation();

  return (
    <Label className="flex items-center gap-2">
      <Switch checked={checked} disabled={disabled} onCheckedChange={onCheckedChange} />
      {label}
      {isComingSoon && (
        <Badge className="px-2 py-0.5 text-xs text-default">{t('keywords.coming_soon')}</Badge>
      )}
    </Label>
  );
}

export function DetailsGeneralSettingsCategory() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<DetailsView>();
  const updateViewSchema = useUpdateView<DetailsView>();

  return (
    <BuilderAccordion>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.general.title_and_description')}
      >
        <div className="mb-4">
          <span className="mb-2 block text-sm font-medium">{t('keywords.title')}</span>
          <Input
            value={view.title ?? ''}
            className="text-sm"
            onChange={(e) => {
              updateViewSchema({
                title: e.target.value
              });
            }}
          />
        </div>
        <div>
          <span className="mb-2 block text-sm font-medium">{t('keywords.description')}</span>
          <RichTextEditor
            onUpdate={({ editor }) => {
              updateViewSchema({
                description: editor.getText() ? editor.getHTML() : ''
              });
            }}
            content={view.description || ''}
          />
        </div>
      </BuilderAccordion.Item>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.details.categories.general_settings.empty_states')}
      >
        <div className="mb-4 space-y-2">
          <DetailsSwitchOption
            label={t(
              'pages.element_settings.details.categories.general_settings.hide_fields_with_no_values'
            )}
            checked={view.hide_fields || false}
            onCheckedChange={(value) => {
              updateViewSchema({
                hide_fields: value
              });
            }}
          />
        </div>
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
