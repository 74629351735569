import { useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiPaintBrush as StylingIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type TableView } from '@/types/schema/views/TableView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/ViewGeneralSettingsCategory';
import { TableActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/table/actions/TableActionsSettingsCategory';
import { TableViewColumnSettings } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/TableViewColumnSettings';
import { TableDataDisplaySettingsCategory } from '@/pages/pages/settings-panel/view-settings/table/TableDataDisplaySettingsCategory';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type TableSettingsCategoryId = 'general-settings' | 'data-display' | 'actions' | 'styling';
type TableSettingsCategoryItem = ViewSettingsCategoryItem<TableSettingsCategoryId>;

function getPanelTitle(
  activeCategoryId: TableSettingsCategoryId | null,
  categories: TableSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.table.heading');
}

export function TableViewSettings() {
  const { view } = useActiveViewContext<TableView>();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));
  const [activeCategoryId, setActiveCategoryId] = useState<TableSettingsCategoryId | null>(null);

  function getTableSettingsCategories() {
    const TABLE_SETTINGS_CATEGORIES: TableSettingsCategoryItem[] = [
      {
        id: 'general-settings',
        name: t('pages.element_settings.common.categories.general_settings.name'),
        description: t('pages.element_settings.table.categories.general_settings.description'),
        icon: GeneralSettingsIcon
      },
      {
        id: 'data-display',
        name: t('pages.element_settings.common.categories.data_display.name'),
        description: t('pages.element_settings.common.categories.data_display.description'),
        icon: FieldsIcon,
        isComingSoon: !hasAccessToNonPublicFeatures
      },
      {
        id: 'actions',
        name: t('pages.element_settings.table.categories.actions.name'),
        description: t('pages.element_settings.table.categories.actions.description'),
        icon: ColumnsIcon,
        isComingSoon: !hasAccessToNonPublicFeatures
      },
      {
        id: 'styling',
        name: t('pages.element_settings.table.categories.styling.name'),
        description: t('pages.element_settings.table.categories.styling.description'),
        icon: StylingIcon,
        isComingSoon: true
      }
    ];

    return TABLE_SETTINGS_CATEGORIES;
  }

  const tableSettingsCategories = getTableSettingsCategories();
  const panelTitle = getPanelTitle(activeCategoryId, tableSettingsCategories);

  const tableViewGeneralSettingsOptions = [
    {
      key: 'cell_editor',
      label: t('pages.element_settings.table.categories.general_settings.edit_record_inline'),
      checked: view.options?.cell_editor || false
    },
    {
      key: 'keyword_search',
      label: t('pages.element_settings.common.categories.general_settings.keyword_search'),
      checked: view.keyword_search
    },
    {
      key: 'allow_exporting',
      label: t('pages.element_settings.common.categories.general_settings.record_exporting'),
      checked: view.allow_exporting
    },
    {
      key: 'add_record_form',
      label: t('pages.element_settings.table.categories.general_settings.add_record_form'),
      checked: false,
      disabled: true,
      isComingSoon: true
    }
  ];

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
      shouldHideDivider={activeCategoryId === 'data-display'}
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && (
            <ViewGeneralSettingsCategory options={tableViewGeneralSettingsOptions} />
          )}
          {activeCategoryId === 'data-display' && <TableDataDisplaySettingsCategory />}
          {activeCategoryId === 'actions' && <TableActionsSettingsCategory />}
        </>
      ) : (
        <ViewSettingsCategoryList
          categories={tableSettingsCategories}
          onCategorySelect={(selectedCategoryId: string) => {
            setActiveCategoryId(selectedCategoryId as TableSettingsCategoryId);
          }}
        />
      )}
      <TableViewColumnSettings />
    </CollapsiblePanel.Content>
  );
}
