import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useCriteriaDateTimeHelpers } from './useCriteriaDateTimeHelpers';

export function useCriteriaHelpers() {
  const { getDefaultCriteriaDateTimeValue } = useCriteriaDateTimeHelpers();
  const { getBaseFieldOperators } = useFieldHelpers();

  const getDefaultCriteriaValue = (field: KnackField) => {
    if (field.type === 'connection') {
      return [] as string[];
    }

    if (field.type === 'boolean') {
      return true;
    }

    if (field.type === 'date_time') {
      return getDefaultCriteriaDateTimeValue(field.format);
    }

    return '';
  };

  const getDefaultCriteriaOperator = (selectedField: KnackField) => {
    const availableOperators = getBaseFieldOperators(selectedField);
    return availableOperators[0];
  };

  // If either the new or previously selected field is a type that has a complex value (e.g. connection, boolean, multiple_choice), we need to clear the criteria value.
  // This prevents displaying the previous value in the input when changing the field type (e.g. changing from `connection` to `short_text` would show the previously selected record ID in the input), and also ensures that the value is in the correct format for the new field type.
  const shouldResetCriteriaValue = (
    newSelectedField: KnackField,
    previousSelectedField: KnackField
  ) => {
    const fieldTypesWithComplexValues: KnackFieldType[] = [
      'boolean',
      'multiple_choice',
      'connection',
      'date_time'
    ];

    return (
      fieldTypesWithComplexValues.includes(newSelectedField.type) ||
      fieldTypesWithComplexValues.includes(previousSelectedField.type)
    );
  };

  const shouldResetCriteriaOperator = (
    selectedField: KnackField,
    existingOperator: KnackOperator
  ) => {
    const availableOperators = getBaseFieldOperators(selectedField);

    // If the field changes, the selected operator might not be in the new list of operators, so we select the operator in the list if that's the case
    const isOperatorInList = (availableOperators as KnackOperator[]).includes(existingOperator);

    return !isOperatorInList;
  };

  const isCriteriaValueEmpty = (
    criteriaValue: KnackCriteria['value'] | undefined,
    selectedField: KnackField
  ) => {
    if (!criteriaValue) {
      return true;
    }

    if (Array.isArray(criteriaValue)) {
      return criteriaValue.length === 0 || criteriaValue[0] === '';
    }

    if (typeof criteriaValue === 'string') {
      return criteriaValue.trim() === '';
    }

    if (typeof criteriaValue === 'boolean') {
      return false;
    }

    if (selectedField.type === 'date_time') {
      // Don't validate the date value if the field ignores the date
      if (selectedField.format.date_format === 'Ignore Date') {
        return false;
      }

      // If the field doesn't ignore time and date, it means that the user can select 'any date' or 'any time' for each part of the date/time input
      const hasAnyDateAnyTimeOptions =
        selectedField.format.time_format &&
        selectedField.format.time_format !== 'Ignore Time' &&
        selectedField.format.date_format;

      // If the field has any date/any time options, it means that the `date` property is still valid when it's an empty string
      if (
        hasAnyDateAnyTimeOptions &&
        typeof criteriaValue === 'object' &&
        'date' in criteriaValue &&
        criteriaValue.date === ''
      ) {
        return false;
      }

      return !criteriaValue.date;
    }

    return false;
  };

  return {
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator,
    isCriteriaValueEmpty
  };
}
