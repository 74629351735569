/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { Button, Divider, Input, Select } from '@knack/asterisk-react';

import { BuilderAccordion } from '@/components/BuilderAccordion';

export function ReportAxisSettings({ isCalculations }: { isCalculations?: boolean }) {
  const [t] = useTranslation();

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t(
        `pages.element_settings.report.categories.data_display.${isCalculations ? 'y_axis' : 'x_axis'}`
      )}
    >
      <div className="mb-4">
        <div className="mb-2">
          {t('pages.element_settings.report.categories.data_display.label')}
        </div>
        <Input
          value=""
          className="text-sm"
          onChange={(e) => {
            console.log('change x axis title', e.target.value);
          }}
        />
      </div>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t(
          `pages.element_settings.report.categories.data_display.${isCalculations ? 'calculation_n' : 'group_n'}`,
          {
            count: 1
          }
        )}
        className="rounded-xl bg-subtle p-2"
      >
        <div className="mb-4">
          <div className="mb-2">
            {t(
              `pages.element_settings.report.categories.data_display.${isCalculations ? 'field' : 'group'}`
            )}
          </div>
          <Select defaultValue="date" value="" onValueChange={() => {}}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              <Select.Item value="date">Date</Select.Item>
            </Select.Content>
          </Select>
          {!isCalculations && <Divider className="mt-4" />}
          <div className="mb-2 mt-4">
            {t('pages.element_settings.report.categories.data_display.order')}
          </div>
          <Select defaultValue="order" value="" onValueChange={() => {}}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              <Select.Item value="order">Order</Select.Item>
            </Select.Content>
          </Select>
          {!isCalculations && (
            <>
              <div className="my-2">
                {t(
                  `pages.element_settings.report.categories.data_display.${isCalculations ? 'calculation' : 'group_by'}`
                )}
              </div>
              <Select defaultValue="month" value="" onValueChange={() => {}}>
                <Select.Trigger className="w-full" />
                <Select.Content>
                  <Select.Item value="month">Month</Select.Item>
                </Select.Content>
              </Select>
            </>
          )}
        </div>
      </BuilderAccordion.Item>
      <Button intent="secondary" className="mt-4">
        <PlusIcon size={16} className="mr-1" />
        {t(
          `pages.element_settings.report.categories.data_display.${isCalculations ? 'y_axis_calculation' : 'x_axis_group'}`
        )}
      </Button>
    </BuilderAccordion.Item>
  );
}
