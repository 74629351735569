import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Combobox, MultiSelect, Spinner, type ComboboxOption } from '@knack/asterisk-react';
import debounce from 'lodash.debounce';

import { type ConnectionField } from '@/types/schema/fields';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import {
  useConnectionRecordsQuery,
  type GetConnectionRecordsOptions
} from '@/hooks/api/queries/useConnectionRecordsQuery';

type ConnectionInputProps = {
  id: string;
  field: ConnectionField;
  name: string;
};

const MAX_RECORDS_COUNT_TO_LOAD = 500;

export function ConnectionInput(props: ConnectionInputProps) {
  const { field, name, id } = props;
  const { data: app } = useApplicationQuery();
  const [searchValue, setSearchValue] = useState('');
  const [initialTotalRecords, setInitialTotalRecords] = useState<number | null>(null);
  const [t] = useTranslation();
  const connectionObject = field.relationship?.object;
  const connectionFieldKey = connectionObject
    ? app?.objects.find((obj) => obj.key === connectionObject)?.identifier
    : undefined;

  const options: GetConnectionRecordsOptions = {
    rowsPerPage: 'all_records',
    limitReturn: !searchValue,

    filters: searchValue
      ? [
          {
            field: connectionFieldKey || '',
            operator: 'contains',
            value: searchValue
          }
        ]
      : undefined
  };

  const { data, isFetching, refetch } = useConnectionRecordsQuery({
    objectKey: field.relationship.object || '',
    enabled: true,
    options
  });

  useEffect(() => {
    setInitialTotalRecords(null);
  }, [field]);

  useEffect(() => {
    if (initialTotalRecords === null && data?.total_records) {
      setInitialTotalRecords(data.total_records);
    }
  }, [data?.total_records, initialTotalRecords]);

  const debouncedSetSearchValue = useMemo(
    () => debounce((value) => setSearchValue(value), 500),
    []
  );

  useEffect(() => {
    if (
      searchValue &&
      initialTotalRecords !== null &&
      initialTotalRecords > MAX_RECORDS_COUNT_TO_LOAD
    ) {
      void refetch();
    }
  }, [searchValue, initialTotalRecords, refetch]);

  const isSearchEnabled = initialTotalRecords !== null && initialTotalRecords > 10;

  const showExceededRecordsLimitMessage =
    initialTotalRecords !== null && initialTotalRecords > MAX_RECORDS_COUNT_TO_LOAD;

  let description: string;
  if (showExceededRecordsLimitMessage && !searchValue) {
    description = t('components.add_into_existing_table.connections_over_500');
  }

  if (field.relationship.has === 'one') {
    return (
      <div data-testid="connection-input-combobox">
        <Controller
          name={name}
          render={({ field: { value: fieldValue, onChange } }) => {
            const formattedValue: ComboboxOption = {
              key: fieldValue[0]?.id,
              label: fieldValue[0]?.identifier
            };

            return (
              <div>
                <Combobox
                  id={id}
                  options={
                    !searchValue && showExceededRecordsLimitMessage
                      ? []
                      : data?.records.map((item) => ({
                          label: item.identifier.toString(),
                          key: item.id
                        })) || []
                  }
                  isSearchEnabled={isSearchEnabled || showExceededRecordsLimitMessage}
                  selectedOption={formattedValue}
                  onSearchChange={(value) => debouncedSetSearchValue(value)}
                  shouldIgnoreFiltering={!isSearchEnabled}
                  description={description}
                  onSelectOption={(value) => {
                    onChange([data?.records.find((record) => record.id === value.key)]);
                  }}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
  if (field.relationship.has === 'many') {
    return (
      <Controller
        name={name}
        render={({ field: formField }) => {
          const fieldValues =
            (formField.value &&
              formField.value.map((item) => ({
                label: item.identifier?.toString(),
                key: item.id
              }))) ||
            [];

          return (
            <MultiSelect
              id={id}
              options={
                !searchValue && showExceededRecordsLimitMessage
                  ? []
                  : data?.records.map((item) => ({
                      label: item.identifier.toString(),
                      key: item.id
                    })) || []
              }
              placeholder={isFetching ? <Spinner /> : undefined}
              selectedOptions={fieldValues}
              isSearchEnabled={isSearchEnabled || showExceededRecordsLimitMessage}
              contentClassName="z-50"
              onSelectOptions={(selectedOptions) => {
                const newRawValue = selectedOptions.map((item) => ({
                  id: item.key,
                  identifier: item.label
                }));
                formField.onChange(newRawValue);
              }}
              description={description}
              onSearchChange={(value) => debouncedSetSearchValue(value)}
              shouldIgnoreFiltering={!isSearchEnabled}
              isSelectAllEnabled={!description}
            />
          );
        }}
      />
    );
  }
}
