import { type Dispatch, type SetStateAction } from 'react';
import { Button, Divider } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';

function useUserRolesTables() {
  const { data: application } = useApplicationQuery();

  const userRoles = application?.objects.filter((o) => o.type === 'UserObject');

  return {
    allUsers: userRoles?.find((table) => table.profile_key === 'all_users'),
    userRole: userRoles?.filter((table) => table.profile_key !== 'all_users')
  };
}

export function RoleSettingsTree({
  table,
  setTable,
  allUsersNameOverride
}: {
  table: KnackObject;
  setTable: Dispatch<SetStateAction<KnackObject>>;
  allUsersNameOverride?: string;
}) {
  const tables = useUserRolesTables();

  return (
    <div className="space-y-2">
      {tables.allUsers && (
        <Button
          intent="minimal"
          className={cn([
            'w-full items-center justify-between p-2',
            {
              'bg-brand-100 focus:bg-brand-100': table.key === tables.allUsers.key
            }
          ])}
          key={tables?.allUsers.key}
          role="button"
          onClick={() => {
            setTable(tables?.allUsers as KnackObject);
          }}
          onKeyDown={() => {}}
        >
          <span className="text-sm font-normal">
            {allUsersNameOverride || tables.allUsers.name}
          </span>
        </Button>
      )}
      <Divider />
      {tables?.userRole?.map((table2) => (
        <Button
          intent="minimal"
          className={cn([
            'h-9 w-full items-center justify-between rounded-lg p-2',
            {
              'bg-brand-100 focus:bg-brand-100': table.key === table2.key
            }
          ])}
          key={table2.key}
          role="button"
          onClick={() => {
            setTable(table2);
          }}
          onKeyDown={() => {}}
        >
          <span className="text-sm font-normal">{table2.name}</span>
        </Button>
      ))}
    </div>
  );
}
