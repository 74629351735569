import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { type FormView, type FormViewInput } from '@/types/schema/views/FormView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewSourceBanner } from '@/pages/pages/settings-panel/view-settings/ViewSourceBanner';
import { ROUTES } from '@/Router';

function TableLink({ objectKey, objectName }: { objectKey: string; objectName: string }) {
  return (
    <Link
      to={generatePath(ROUTES.TABLES_ID, { id: objectKey })}
      className="text-emphasis underline"
    >
      {objectName}
    </Link>
  );
}

function useGetFormSourceDescription(view: FormView) {
  const { page } = usePageEditorContext();
  const { getObjectByKey, getObjectByFieldKey } = useObjectHelpers();

  const viewSourceObject = getObjectByKey(view.source.object);

  if (!viewSourceObject) {
    return null;
  }

  const viewActionI18nKey = view.action === 'update' ? 'updates' : 'inserts';

  const defaultSourceDescription = (
    <Trans
      i18nKey={`pages.element_settings.form.source.${viewActionI18nKey}_record`}
      components={[
        <TableLink
          key="0"
          objectKey={viewSourceObject.key}
          objectName={viewSourceObject.inflections.singular}
        />
      ]}
      values={{
        objectName: viewSourceObject.inflections.singular
      }}
    />
  );

  // If the form has a parent view, it means the form is adding a new record to a parent form's connection input or a registration form
  if (view.parent) {
    const parentView = page.views.find((v) => v.key === view.parent);

    if (!parentView) {
      return defaultSourceDescription;
    }

    // If the parent view is a login view, this form is a registration form
    if (parentView.type === 'login') {
      return (
        <Trans
          i18nKey="pages.element_settings.form.source.registers_new_user"
          components={[
            <TableLink
              key="0"
              objectKey={viewSourceObject.key}
              objectName={viewSourceObject.inflections.singular}
            />
          ]}
          values={{
            objectName: viewSourceObject.inflections.singular
          }}
        />
      );
    }

    // If the parent view is a form, this form is adding a new option to a connection input in the parent form
    if (parentView.type === 'form') {
      let parentFormInput: FormViewInput | undefined;

      // Find the input in the parent form that this form is adding a new option to
      parentView.groups.forEach((group) => {
        group.columns.forEach((column) => {
          parentFormInput = column.inputs.find(
            (input) => input.type === 'connection' && input.view === view.key
          );
        });
      });

      if (!parentFormInput) {
        return defaultSourceDescription;
      }

      return (
        <Trans
          i18nKey="pages.element_settings.form.source.adds_new_option_to_input"
          components={[
            <TableLink
              key="0"
              objectKey={viewSourceObject.key}
              objectName={viewSourceObject.inflections.singular}
            />,
            <span key="1" className="text-emphasis">
              {parentView.name}
            </span>
          ]}
          values={{
            inputName:
              parentFormInput.type !== 'divider' && parentFormInput.type !== 'section_break'
                ? parentFormInput.label
                : '',
            parentViewName: parentView.name
          }}
        />
      );
    }

    return defaultSourceDescription;
  }

  // If the view source object has a direct connection, we need to include it in the source description
  if (view.source.connection_key) {
    const connectionFieldObject = getObjectByFieldKey(view.source.connection_key);
    const connectionField = connectionFieldObject?.fields.find(
      (field) => field.key === view.source.connection_key
    );
    const connectedObject =
      connectionFieldObject?.key === view.source.object
        ? getObjectByKey(connectionField?.relationship?.object ?? '')
        : connectionFieldObject;

    if (!connectedObject) {
      return defaultSourceDescription;
    }

    const originObjectI18nKey = view.source.authenticated_user ? 'logged_in_user' : 'page';

    // When there is a parent and a direct connection, the parent connection represents the connection that the source object has with the origin object of the page (which could be the source object of the page itself, or the logged-in user object)
    // Example: "This form updates a Coach record [connected to the Team] [connected to this page's Player]"
    //                                                ^ this is the direct connection         ^ this is the parent connection
    if (view.source.parent_source) {
      const parentSourceObject = getObjectByKey(view.source.parent_source.object);

      if (parentSourceObject) {
        return (
          <Trans
            i18nKey={`pages.element_settings.form.source.${viewActionI18nKey}_record_connected_to_object_connected_to_the_${originObjectI18nKey}`}
            components={[
              <TableLink
                key="0"
                objectKey={viewSourceObject.key}
                objectName={viewSourceObject.inflections.singular}
              />,
              <TableLink
                key="1"
                objectKey={connectedObject.key}
                objectName={connectedObject.inflections.singular}
              />,
              <TableLink
                key="2"
                objectKey={parentSourceObject.key}
                objectName={parentSourceObject.inflections.singular}
              />
            ]}
            values={{
              objectName: viewSourceObject.inflections.singular,
              connectedObjectName: connectedObject.inflections.singular,
              parentSourceObjectName: parentSourceObject.inflections.singular
            }}
          />
        );
      }
    }

    // If there is no parent connection, we only need to include the direct connection which is the connection that the source object has with the origin object of the page (which could be the source object of the page itself, or the logged-in user object)
    // Example: "This form updates a Team record [connected to this page's Game]"
    //                                                   ^ this is the direct connection
    return (
      <Trans
        i18nKey={`pages.element_settings.form.source.${viewActionI18nKey}_record_connected_to_the_${originObjectI18nKey}`}
        components={[
          <TableLink
            key="0"
            objectKey={viewSourceObject.key}
            objectName={viewSourceObject.inflections.singular}
          />,
          <TableLink
            key="1"
            objectKey={connectedObject.key}
            objectName={connectedObject.inflections.singular}
          />
        ]}
        values={{
          objectName: viewSourceObject.inflections.singular,
          connectedObjectName: connectedObject.inflections.singular
        }}
      />
    );
  }

  return defaultSourceDescription;
}

export function FormSourceBanner() {
  const { view } = useActiveViewContext<FormView>();
  const sourceDescription = useGetFormSourceDescription(view);

  return <ViewSourceBanner className="mb-4">{sourceDescription}</ViewSourceBanner>;
}
