import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';

type SortingOption = {
  label: string;
  value: 'asc' | 'desc';
};

export const sortingOptionsMap = {
  number: [
    { label: 'Low --> High', value: 'asc' },
    { label: 'High --> Low', value: 'desc' }
  ],
  auto_increment: [
    { label: 'Low --> High', value: 'asc' },
    { label: 'High --> Low', value: 'desc' }
  ],
  boolean: [
    { label: 'True -> False', value: 'asc' },
    { label: 'False -> True', value: 'desc' }
  ],
  date_time: [
    { label: 'Oldest -> Newest', value: 'asc' },
    { label: 'Newest -> Oldest', value: 'desc' }
  ],
  default: [
    { label: 'A -> Z', value: 'asc' },
    { label: 'Z -> A', value: 'desc' }
  ]
} as const;

export const getTableViewSettingsSortingOptions = (
  selectedField: KnackFieldKey,
  sourceObject: KnackObject
) => {
  const fieldType = sourceObject.fields.find((field) => field.key === selectedField)?.type;

  if (!fieldType) return sortingOptionsMap.default;

  return sortingOptionsMap[fieldType] || sortingOptionsMap.default;
};

export const getTableViewSettingsSortingOptionLabel = (
  sortItem: {
    field: KnackFieldKey;
    order: 'asc' | 'desc';
  },
  fieldType: string
) => {
  const sortingOption: SortingOption[] = sortingOptionsMap[fieldType] || sortingOptionsMap.default;

  const selectedOption = sortingOption.find((option) => option.value === sortItem.order);

  return selectedOption?.label || '';
};
