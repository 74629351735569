import { type ComponentProps } from 'react';
import { RichTextRenderer } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface InlineKnackValueBaseProps extends ComponentProps<'span'> {
  value: string;
}

// Ensure `asRichTextRenderer` and `asHtml` are mutually exclusive
type InlineKnackValueProps =
  | (InlineKnackValueBaseProps & { asHtml?: true; asRichTextRenderer?: never })
  | (InlineKnackValueBaseProps & { asHtml?: never; asRichTextRenderer?: true });

export function InlineKnackValue({
  value,
  className,
  asRichTextRenderer,
  asHtml,
  ...props
}: InlineKnackValueProps) {
  if (asRichTextRenderer) {
    return (
      <RichTextRenderer
        className={cn('rounded-md bg-subtle p-1', className)}
        dangerouslySetInnerHTML={{
          __html: value
        }}
        {...props}
      />
    );
  }

  const commonClassNames = cn(
    'inline-flex rounded-md bg-subtle p-1 align-middle leading-none items-center text-emphasis',
    className
  );

  if (asHtml) {
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: value }}
        className={commonClassNames}
        {...props}
      />
    );
  }

  return (
    <span className={commonClassNames} {...props}>
      {value}
    </span>
  );
}
