import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiCheckCircle as CompletedIcon,
  HiExclamationCircle as FailedIcon,
  HiCloudArrowDown as ImportIcon
} from 'react-icons/hi2';
import { Button, Progress, Spinner } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { assertTruthiness } from '@/utils/assert';
import { useGetLatestJob } from '@/components/jobs/useGetLatestJob';
import { useJobsStore } from '@/components/jobs/useJobsStore';
import { useWebSocket } from '@/components/jobs/useWebSocket';
import { useLastVisitedStore } from '@/pages/tables/useLastVisitedStore';

export function JobNotification() {
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();
  assertTruthiness(application, 'No application found.');

  const [isCancelImportConfirmation, setIsCancelImportConfirmation] = useState(false);

  useWebSocket();

  const { latestJob } = useGetLatestJob();

  const lastVisitedTableKey = useLastVisitedStore((state) => state.lastVisitedTableKey);

  const socket = useJobsStore((state) => state.socket);
  const actions = useJobsStore((state) => state.actions);

  if (!latestJob || !latestJob.isShown) {
    return null;
  }

  const tableImporting = application.objects.find((table) => table.key === latestJob?.tableKey);

  return (
    <div className="pointer-events-none fixed bottom-6 z-50 flex w-screen items-center justify-center">
      {lastVisitedTableKey === latestJob.tableKey && (
        <div
          className="pointer-events-auto relative flex w-[383px] flex-col rounded-lg bg-intense p-4 text-xs text-inverted"
          data-testid="job-notifications-container"
        >
          <div className="flex items-center gap-2">
            {latestJob.jobStatus === 'queued' && (
              <>
                <Spinner />
                <h1>
                  {t('components.job_notification.title_importing', {
                    tableName: tableImporting?.name
                  })}
                </h1>
              </>
            )}
            {latestJob.jobStatus === 'in_progress' && (
              <>
                <ImportIcon size={18} />
                <h1>
                  {t('components.job_notification.title_importing', {
                    tableName: tableImporting?.name
                  })}
                </h1>
              </>
            )}
            {latestJob.jobStatus === 'failed' && (
              <>
                <FailedIcon size={18} className="text-destructive-muted" />
                <h1>
                  {t('components.job_notification.title_importing_failed', {
                    tableName: tableImporting?.name
                  })}
                </h1>
              </>
            )}
            {latestJob.jobStatus === 'completed' && (
              <>
                <CompletedIcon size={18} className="text-success-default" />

                <h1>
                  {t('components.job_notification.title_importing_complete', {
                    tableName: tableImporting?.name
                  })}
                </h1>
              </>
            )}
            {latestJob.jobStatus === 'canceled' && (
              <>
                <FailedIcon size={18} className="text-destructive-muted" />
                <h1>
                  {t('components.job_notification.title_importing_canceled', {
                    tableName: tableImporting?.name
                  })}
                </h1>
              </>
            )}
            {latestJob.jobStatus === 'retrying' && (
              <>
                <FailedIcon size={18} className="text-destructive-muted" />
                <h1>
                  {t('components.job_notification.title_retrying', {
                    tableName: tableImporting?.name
                  })}
                </h1>
              </>
            )}
          </div>

          {isCancelImportConfirmation ? (
            <div className="mt-2 flex space-x-2">
              <Button
                intent="destructive"
                onClick={() => {
                  const payload = {
                    job_id: latestJob.jobId
                  };
                  socket?.emit('csv:import:cancel', payload);
                  setIsCancelImportConfirmation(false);
                }}
              >
                {t('components.job_notification.cancel_import')}
              </Button>
              <Button
                intent="minimal"
                // Since this container has a black background we need the dark version of the button so we invert colors
                className="text-inverted hover:bg-inverted focus:bg-inverted"
                onClick={() => {
                  setIsCancelImportConfirmation(false);
                }}
              >
                {t('components.job_notification.keep_importing')}
              </Button>
            </div>
          ) : (
            <>
              {latestJob.jobStatus !== 'retrying' && latestJob.jobStatus !== 'queued' && (
                <div className="flex w-full items-center space-x-2">
                  <Progress intent="brand" value={latestJob.progress} className="h-2" />
                  <p>{latestJob.progress}%</p>

                  {latestJob.jobStatus === 'in_progress' && (
                    <Button
                      intent="minimal"
                      // Since this container has a black background we need the dark version of the button so we invert colors
                      className="text-inverted hover:bg-inverted focus:bg-inverted"
                      onClick={() => {
                        setIsCancelImportConfirmation(true);
                      }}
                    >
                      {t('actions.cancel')}
                    </Button>
                  )}
                  {latestJob.jobStatus !== 'in_progress' && (
                    <Button
                      intent="minimal"
                      // Since this container has a black background we need the dark version of the button so we invert colors
                      className="text-inverted hover:bg-inverted focus:bg-inverted"
                      onClick={() => {
                        actions.hideJob(latestJob.jobId);
                      }}
                    >
                      {t('actions.close')}
                    </Button>
                  )}
                </div>
              )}

              <p>
                {latestJob.jobStatus === 'in_progress' || latestJob.jobStatus === 'canceled'
                  ? t('components.job_notification.processed', {
                      count: latestJob.processedCount,
                      total: latestJob.totalCount
                    })
                  : null}
                {latestJob.jobStatus === 'completed' &&
                  `${t('components.job_notification.processed', {
                    count: latestJob.processedCount,
                    total: latestJob.totalCount
                  })} ${DateTime.fromMillis(latestJob.jobFinishedOn).toRelative()}`}
                {latestJob.jobStatus === 'failed' &&
                  `${t('components.job_notification.processed_failed', {
                    count: latestJob.processedCount,
                    total: latestJob.totalCount
                  })} ${DateTime.fromMillis(latestJob.jobFinishedOn).toRelative()}`}
              </p>

              {latestJob.failedRows.length > 0 && (
                <div>
                  <p className="mt-4">{t('components.job_notification.failed_rows')}</p>
                  <div className="mt-2 max-h-10 overflow-auto rounded bg-base p-2 text-subtle">
                    {latestJob.failedRows.map(String).join(', ')}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
