import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Form } from '@knack/asterisk-react';
import { type z } from 'zod';

import { type KnackField } from '@/types/schema/KnackField';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { FieldInput } from '@/components/data-table/display/edit-record/FieldInput';
import {
  generateFormSchema,
  getDefaultValues,
  getNormalizedRecordData
} from '@/components/data-table/display/edit-record/utils';
import { convertKnackRecordToDataTableRow } from '@/components/data-table/helpers/getRecords';
import {
  useDataTableStore,
  type ServerErrorResponseType
} from '@/components/data-table/useDataTableStore';
import { ROUTES } from '@/Router';

export type EditRecordModalOutletContext = {
  rowId: string;
  fields: KnackField[];
};

function EditRecordModalContent({ rowId, fields }: EditRecordModalOutletContext) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  type FormSchema = z.infer<typeof formSchema>;
  const record = useDataTableStore().use.getCellValues(rowId);
  const tableKey = useDataTableStore().use.objectKey();
  const updateRecord = useUpdateRecordMutation();

  const defaultValues = getDefaultValues(record, fields);
  const formSchema = generateFormSchema(record, fields);

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues
  });

  const { handleSubmit, setError, getFieldState, setFocus } = formMethods;

  const closeDialog = () => {
    navigate(generatePath(ROUTES.TABLES_ID, { id: tableKey }));
  };

  const { setRow } = useDataTableStore().use.actions();
  const onFormSubmit: SubmitHandler<FormSchema> = (data) => {
    const formattedData = getNormalizedRecordData(data, fields);
    updateRecord.mutate(
      {
        objectKey: tableKey,
        recordId: rowId,
        data: formattedData
      },
      {
        onSuccess: (response) => {
          setRow(convertKnackRecordToDataTableRow(response));
          closeDialog();
        },
        onError: (error: unknown) => {
          const errorResponse = error as ServerErrorResponseType;
          if (errorResponse && errorResponse.errors) {
            errorResponse.errors.forEach((err) => {
              const fieldName = err.field as keyof FormSchema;
              setError(fieldName, {
                type: 'manual',
                message: err.message
              });

              const fieldState = getFieldState(fieldName);
              if (fieldState.invalid) {
                setFocus(fieldName);
              }
            });
          }
        }
      }
    );
  };

  return (
    <Dialog open onOpenChange={() => closeDialog()}>
      <Dialog.Content data-testid={`delete-record-confirmation-${rowId}`}>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Dialog.MainContent className="space-y-6">
              <Dialog.Header>
                <Dialog.Title>{t('components.data_table.row_actions.edit_record')}</Dialog.Title>
              </Dialog.Header>
              <Form.Section className="space-y-6">
                {fields?.map((field) => (
                  <div key={field.key}>
                    <FieldInput field={field} />
                  </div>
                ))}
              </Form.Section>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal" data-testid="edit-record-cancel">
                  {t('components.data_table.row_actions.delete_record_confirmation.cancel')}
                </Button>
              </Dialog.Close>
              <Button data-testid="edit-record-save" type="submit">
                {t('actions.save')}
              </Button>
            </Dialog.Footer>
          </Form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}

export function EditRecordModal() {
  const { recordId } = useParams();
  const { rowId, fields } = useOutletContext<EditRecordModalOutletContext>();

  if (!recordId) {
    throw new Error('Record ID is required to edit a record');
  }

  if (rowId !== recordId) {
    return null;
  }

  return <EditRecordModalContent rowId={recordId} fields={fields} />;
}
