import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { Button } from '@knack/asterisk-react';

interface AddTableSectionItem {
  id: string;
  title: string;
  icon: string | IconType;
}

interface AddTableSourceItemProps {
  item: AddTableSectionItem;
  isDisabled?: boolean;
  RightComponent?: React.ReactNode;
  onClick: (id: string) => void;
}

export function AddTableSourceItem({
  item,
  isDisabled,
  RightComponent,
  onClick,
  ...props
}: AddTableSourceItemProps) {
  const [t] = useTranslation();

  return (
    <Button
      intent="secondary"
      className="flex w-full justify-start gap-2 font-normal"
      disabled={isDisabled}
      onClick={() => onClick(item.id)}
      {...props}
    >
      {typeof item.icon === 'string' ? (
        <div className="h-5 w-5 overflow-hidden">
          <img src={item.icon} alt={`${item.title} ${t('components.add_table.logo')}`} />
        </div>
      ) : (
        <item.icon size={20} />
      )}
      <p>{item.title}</p>
      {RightComponent && <div className="ml-auto">{RightComponent}</div>}
    </Button>
  );
}
