import { t } from 'i18next';
import { nanoid } from 'nanoid';

import { VIEW_GROUP_ID_PREFIX, type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type DetailsView, type DetailsViewColumn } from '@/types/schema/views/DetailsView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from './generateNewViewKey';

const NEW_DETAILS_COLUMN_LIMIT = 7;

const baseDetailsViewSchema: Partial<DetailsView> = {
  type: 'details',
  description: '',
  groups: [],
  inputs: [],
  links: [],
  label_format: 'left',
  layout: 'full',
  hide_fields: false,
  rules: {
    fields: []
  }
};

const baseDetailsViewColumnSchema: Partial<DetailsViewColumn> = {
  conn_separator: '',
  conn_link: '',
  link_type: 'text',
  link_text: '',
  link_field: '',
  link_design_active: false,
  icon: {
    icon: '',
    align: 'left'
  },
  img_gallery: '',
  show_map: false,
  map_width: 400,
  map_height: 300,
  value: '',
  copy: '',
  format: {
    label_custom: false,
    label_format: 'left',
    styles: []
  }
};

function generateDetailsViewColumns({ viewSourceObject }: { viewSourceObject: KnackObject }) {
  const detailsColumns: DetailsViewColumn[] = [];

  let addedColumnsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (addedColumnsCount >= NEW_DETAILS_COLUMN_LIMIT) {
      return;
    }

    const isUserField =
      field.user && (field.type === 'user_roles' || field.type === 'multiple_choice');

    if (isUserField || field.type === 'password') {
      return;
    }

    detailsColumns.push({
      ...baseDetailsViewColumnSchema,
      id: field.key,
      type: 'field',
      key: field.key,
      name: field.name
    } satisfies Partial<DetailsViewColumn> as DetailsViewColumn);

    addedColumnsCount += 1;
  });

  return detailsColumns;
}

export function getDetailsViewSchemaFromPartialView(
  partialFormView: Partial<DetailsView>,
  sourceObject: KnackObject
) {
  const detailsViewSchema: Partial<DetailsView> = {
    ...baseDetailsViewSchema,
    ...partialFormView,
    columns: partialFormView.source
      ? [
          {
            id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
            width: 100,
            groups: [
              {
                columns: [
                  generateDetailsViewColumns({
                    viewSourceObject: sourceObject
                  })
                ]
              }
            ]
          }
        ]
      : []
  };

  return detailsViewSchema as DetailsView;
}

export function getDetailsViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const objectName =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;
  const viewTitle = t('views.new_view_defaults.details.name', {
    objectName
  });

  const detailsViewSchema: Partial<DetailsView> = {
    ...baseDetailsViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    columns: [
      {
        groups: [
          {
            columns: [
              generateDetailsViewColumns({
                viewSourceObject: viewSource.object
              })
            ]
          }
        ],
        width: 100
      }
    ]
  };

  return detailsViewSchema;
}
