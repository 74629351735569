import { type TableView } from '@/types/schema/views/TableView';
import { InlineKnackField } from '@/components/InlineKnackField';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getTableViewSettingsSortingOptionLabel } from '@/pages/pages/settings-panel/view-settings/table/data-display/sorting/helper';

export function SortingListItem({ sortItem }) {
  const { sourceObject } = useActiveViewContext<TableView>();

  const sourceField = sourceObject.fields.find((f) => f.key === sortItem.field);

  if (!sourceField) {
    return null;
  }

  return (
    <div className="flex items-center gap-2 rounded-lg bg-subtle p-2">
      <InlineKnackField fieldType={sourceField.type} fieldName={sourceField.name} />
      <p>{getTableViewSettingsSortingOptionLabel(sortItem, sourceField.type)}</p>
    </div>
  );
}
