import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDocumentDuplicate as DuplicateIcon, HiCog6Tooth as SettingsIcon } from 'react-icons/hi2';
import { generatePath, useNavigate } from 'react-router-dom';
import { DropdownMenu } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { TextTooltip } from '@/components/TextTooltip';
import { ROUTES } from '@/Router';

export type DialogContentType = 'settings' | 'delete' | 'duplicate';

export interface RoleDropdownMenuProps {
  table: KnackObject;
  triggerElement: React.ReactNode;
  onDropdownMenuOpenChange?: (isOpen: boolean, tableKey: string) => void;
}

export function RoleDropdownMenu({
  table,
  triggerElement,
  onDropdownMenuOpenChange
}: RoleDropdownMenuProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  return (
    <DropdownMenu
      open={isDropdownOpen}
      onOpenChange={(isOpen) => {
        if (onDropdownMenuOpenChange) {
          onDropdownMenuOpenChange(isOpen, table.key);
        }
        setDropdownOpen(isOpen);
      }}
      data-testid={`dropdown-menu-${table.key}`}
      modal={false}
    >
      <DropdownMenu.Trigger data-testid={`dropdown-trigger-${table.key}`} asChild>
        {triggerElement}
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className="flex flex-col text-sm"
        data-testid={`dropdown-content-${table.key}`}
      >
        <TextTooltip
          label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
        >
          <DropdownMenu.Item
            onSelect={() =>
              navigate(
                generatePath(ROUTES.ROLES_ID_SETTINGS_SETTING_ID, {
                  id: table.key,
                  settingId: 'edit'
                })
              )
            }
            data-testid={`dropdown-settings-item-${table.key}`}
            disabled={isDraftModeEnabled}
          >
            <SettingsIcon size={18} className="mr-2" />
            {t('components.tables_list.table_settings')}
          </DropdownMenu.Item>
        </TextTooltip>
        <TextTooltip
          label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
        >
          <DropdownMenu.Item
            onSelect={() =>
              navigate(
                generatePath(ROUTES.ROLES_ID_COPY, {
                  id: table.key
                })
              )
            }
            data-testid={`dropdown-duplicate-item-${table.key}`}
            disabled={isDraftModeEnabled}
          >
            <DuplicateIcon size={18} className="mr-2" />
            {t('components.tables_list.duplicate_table')}
          </DropdownMenu.Item>
        </TextTooltip>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
