import { t } from 'i18next';
import { z } from 'zod';

import { type PasswordField } from '@/types/schema/fields';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getPasswordSchema(_field: PasswordField) {
  return z
    .object({
      hasChanged: z.boolean(),
      password: z.string(),
      password_confirmation: z.string()
    })
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: 'custom',
          message: t('components.data_table.errors.passwords_do_not_match'),
          path: ['password_confirmation']
        });
      }
    });
}
