import { useEffect } from 'react';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { MultiSelect } from '@knack/asterisk-react';

import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type UserRolesField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { UserRolesValues } from '@/components/data-table/display/fields/user-roles/UserRolesValues';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { useUserRolesTables } from '@/components/data-table/helpers/useUserRolesTables';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function UserRolesEdit({ fieldId, rawValue, rowId }: FieldRenderProps<UserRolesField>) {
  const userRolesTables = useUserRolesTables();
  const selectedUserRoles =
    userRolesTables?.filter(
      (table) => table.profile_key && rawValue?.includes(table.profile_key)
    ) || [];

  const selectedCell = useDataTableStore().use.selectedCell();
  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();
  const currentTable = useCurrentTable();

  if (!selectedCell) throw new Error('No selected cell');
  const { moveSelectionHorizontal } = useSelectionStrategy();

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      size({
        padding: 500,
        apply({ availableHeight, elements }) {
          if (parseInt(elements.floating.style.height, 10) < availableHeight) {
            elements.floating.style.height = `${availableHeight}px`;
          }
        }
      }),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLButtonElement>) => {
    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (e.key === 'Tab' && selectedCell.isEditing) {
      moveSelectionHorizontal('right');
      setIsEditing(false);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      (refs.reference.current as HTMLTextAreaElement).focus();
    }, 0);
  }, [refs.reference]);

  // The selected option gets the focus when it changes or when you start editing
  // This way if you have a scrollbar the element will be scrolled into view
  useEffect(() => {
    if (selectedCell.isEditing) {
      clearCellErrors(rowId, fieldId);
    }
  }, [selectedCell.isEditing, clearCellErrors, rowId, fieldId]);

  return (
    <button
      ref={refs.setReference}
      data-testid={`edit-user-roles-input-${rowId}-${fieldId}`}
      type="button"
      className="flex size-full focus:outline-0"
      onKeyDown={handleKeyDown}
      onClick={() => {
        if (!selectedCell.isEditing) {
          setIsEditing(true);
        }
      }}
    >
      <UserRolesValues
        rawValue={rawValue}
        onDeleteOption={(option) => {
          if (typeof rawValue === 'object') {
            const newRawValue = rawValue?.filter((item) => item !== option);

            void saveCell(rowId, fieldId, newRawValue, {
              value: '',
              rawValue: newRawValue
            });
          }

          if (typeof rawValue === 'string') {
            const newRawValue = '';

            void saveCell(rowId, fieldId, newRawValue, {
              value: '',
              rawValue: newRawValue
            });
          }
        }}
      />
      <div ref={refs.setFloating} style={floatingStyles} className="w-full">
        {selectedCell.isEditing && (
          <MultiSelect
            id="new-menu-pages"
            shouldRenderInline
            options={
              userRolesTables?.map((item) => ({
                label: item.name,
                key: item.profile_key || ''
              })) || []
            }
            selectedOptions={selectedUserRoles.map((item) => ({
              label: item.name,
              key: item.profile_key || ''
            }))}
            isSearchEnabled
            contentClassName="z-50"
            onSelectOptions={(selectedOptions) => {
              const newRawValue = selectedOptions?.map((item) => item.key);

              // You cannot remove the current user role, so we add it back if it's not in the list
              if (!newRawValue.includes(currentTable?.profile_key || '')) {
                newRawValue.push(currentTable?.profile_key || '');
              }

              void saveCell(rowId, fieldId, newRawValue, {
                value: '',
                rawValue: newRawValue
              });
            }}
          />
        )}
      </div>

      <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="user-roles-edit-error" />
    </button>
  );
}
