import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Label, Select } from '@knack/asterisk-react';

import { CONNECTION_RELATIONSHIP_OPTIONS, type ConnectionField } from '@/types/schema/fields';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { hasTableValidDisplayFields } from '@/utils/tables';
import { ConnectionTable } from '@/components/field-settings/connection/ConnectionTable';
import { RelationshipsInfoModal } from '@/components/field-settings/connection/RelationshipsInfoModal';

type RelationshipsProps = {
  field: ConnectionField;
  isNewField: boolean;
  objectKey: string;
};

type ConnectionToUpdate = 'has' | 'belongs_to';

export function Relationships({ field, isNewField, objectKey }: RelationshipsProps) {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  const [shouldRenderRelationshipsInfoModal, setShouldRenderRelationshipsInfoModal] =
    useState(false);

  const { data: app } = useApplicationQuery();

  const firstAvailableValidTable = app?.objects.find((object) =>
    hasTableValidDisplayFields(object)
  );

  const connectionTable = app?.objects.find((object) => object.key === objectKey);
  const connectedTableObjectKey = isNewField
    ? getValues('relationship.object') || firstAvailableValidTable?.key
    : field?.relationship?.object;

  const connectedTable =
    app?.objects.find((object) => object.key === connectedTableObjectKey) ||
    firstAvailableValidTable;

  const isRadioButtonsInputSelected = getValues('format.input') === 'radio';
  const isCheckboxInputSelected = getValues('format.input') === 'checkbox';

  const relationship = (connectionToUpdate: ConnectionToUpdate) =>
    connectedTable &&
    connectionTable && (
      <div className="flex flex-col gap-1 rounded-lg bg-subtle px-1 py-2">
        <div className="flex items-center gap-2 truncate px-1">
          <Trans
            i18nKey="components.data_table.attributes.field_settings.connection.relationship.connection_table_connects_with"
            components={[
              <ConnectionTable
                key="0"
                table={connectionToUpdate === 'has' ? connectionTable : connectedTable}
              />
            ]}
          />
        </div>
        <div className="flex items-center gap-2 truncate p-1">
          <Select
            onValueChange={(value) => {
              setValue(`relationship.${connectionToUpdate}`, value);
              if (
                connectionToUpdate === 'has' &&
                (isCheckboxInputSelected || isRadioButtonsInputSelected)
              ) {
                setValue('format.input', 'chosen');
              }
            }}
            defaultValue={
              getValues(`relationship.${connectionToUpdate}`) ||
              (connectionToUpdate === 'has' ? 'one' : 'many')
            }
          >
            <Select.Trigger
              id={`${connectionToUpdate}-select`}
              placeholder={t('actions.select')}
              className="w-24"
              data-testid={`connection-form-settings-${connectionToUpdate}-select`}
            />
            <Select.Content>
              {CONNECTION_RELATIONSHIP_OPTIONS.map((option) => (
                <Select.Item
                  key={option}
                  value={option}
                  data-testid={`connection-field-settings-${connectionToUpdate}-${option}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.connection.relationship.options.${option}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
          <Trans
            i18nKey="components.data_table.attributes.field_settings.connection.relationship.connected_table_connects_with"
            components={[
              <ConnectionTable
                key="0"
                table={connectionToUpdate === 'has' ? connectedTable : connectionTable}
              />
            ]}
            values={{
              records: t(
                'components.data_table.attributes.field_settings.connection.relationship.cardinality',
                {
                  count:
                    getValues(`relationship.${connectionToUpdate}`) === 'one' ||
                    (!getValues('relationship') && connectionToUpdate === 'has')
                      ? 1
                      : 2
                }
              )
            }}
          />
        </div>
      </div>
    );

  // Initialize the relationship field on the Add new field form
  useEffect(() => {
    if (!getValues('relationship') && isNewField && connectedTable?.key) {
      setValue('relationship', {
        has: 'one',
        belongs_to: 'many',
        object: connectedTable?.key
      });
    }
  }, [setValue, getValues, isNewField, connectedTable?.key]);

  const validTablesToConnect =
    app?.objects.filter((object) => hasTableValidDisplayFields(object)) || [];

  if (!connectionTable) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <Label className="font-medium">
          {t('components.data_table.attributes.field_settings.connection.relationship.label')}
        </Label>
        <button
          type="button"
          onClick={() => setShouldRenderRelationshipsInfoModal(true)}
          aria-label={t(
            'components.data_table.attributes.field_settings.connection.relationship.label'
          )}
        >
          <InfoIcon size={16} />
        </button>
      </div>
      {isNewField &&
        (connectedTable ? (
          <div className="flex flex-col gap-1 rounded-lg bg-muted px-1 py-2">
            <div className="flex flex-wrap items-center gap-1 px-1">
              <Trans
                i18nKey="components.data_table.attributes.field_settings.connection.relationship.new_field_connects_with"
                components={[<ConnectionTable key="0" table={connectionTable} />]}
              />
              <div className="flex items-center truncate py-1">
                <Select
                  onValueChange={(value) => {
                    setValue('relationship.object', value);
                  }}
                  defaultValue={connectedTable?.key}
                >
                  <Select.Trigger
                    id="connected-object-select"
                    placeholder={t('actions.select')}
                    data-testid="connection-form-settings-connected-object-select"
                    className="min-w-52"
                  />
                  <Select.Content>
                    {validTablesToConnect.map((object) => (
                      <Select.Item
                        key={object.key}
                        value={object.key}
                        data-testid={`connection-field-settings-${object?.key}`}
                      >
                        {object.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex rounded-lg bg-muted px-1 py-2">
            {t(
              'components.data_table.attributes.field_settings.connection.relationship.no_tables_available'
            )}
          </div>
        ))}
      {relationship('has')}
      {relationship('belongs_to')}
      <RelationshipsInfoModal
        shouldRenderRelationshipsInfoModal={shouldRenderRelationshipsInfoModal}
        setShouldRenderRelationshipsInfoModal={setShouldRenderRelationshipsInfoModal}
      />
    </div>
  );
}
