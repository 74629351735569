import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Form, Input } from '@knack/asterisk-react';
import { z } from 'zod';

import { useCreateEmptyObjectMutation } from '@/hooks/api/mutations/useCreateEmptyObjectMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TABLE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { generateUniqueStringInList, isStringUniqueInList } from '@/utils/uniqueStrings';
import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';

export function CreateRoleDialog({
  setCreateTableDialogOpen
}: {
  setCreateTableDialogOpen: (open: boolean) => void;
}) {
  const [t] = useTranslation();
  const { data: app } = useApplicationQuery();
  const createEmptyObject = useCreateEmptyObjectMutation({ forceRedirectToV4: true });

  const existingRoleNames =
    app?.objects?.filter((o) => o.type === 'UserObject').map((o) => o.name) || [];

  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    tableName: z
      .string()
      .min(1, t('components.dialogs.tables.settings.table_name_required'))
      .max(
        TABLE_NAME_MAX_CHAR_LENGTH,
        t('components.dialogs.tables.settings.table_name_max_length', {
          max: TABLE_NAME_MAX_CHAR_LENGTH
        })
      )
      .refine(
        (val) => isStringUniqueInList(val, existingRoleNames),
        (val) => ({
          message: t('errors.table_name_unique', {
            fieldName: val
          })
        })
      )
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tableName: generateUniqueStringInList(existingRoleNames, t('components.roles_list.user_role'))
    },
    mode: 'onChange'
  });

  const onSubmit = ({ tableName }: FormSchema) =>
    createEmptyObject.mutate(
      {
        objectName: tableName,
        user: true
      },
      {
        onSuccess: () => {
          setCreateTableDialogOpen(false);
        }
      }
    );

  return (
    <>
      <Dialog.Header className="mb-6">
        <Dialog.Title className="font-medium">{t('components.roles_list.new_role')}</Dialog.Title>
        <ApiErrorBanner
          translationPrefix="components.add_table.errors"
          error={createEmptyObject?.error}
          data-testid="error-banner"
          fallbackKey="errors.generic_error"
        />
      </Dialog.Header>
      <Form data-testid="create-table-form" onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <Form.Section>
          <Form.Label htmlFor="new-table-name-input">
            {t('components.roles_list.role_name')}
          </Form.Label>
          <Input.Container>
            <Input
              data-testid="new-table-name-input"
              id="new-table-name-input"
              title={t('components.dialogs.tables.table_name')}
              intent={errors.tableName && 'destructive'}
              {...register('tableName', {
                onChange: async () => {
                  if (createEmptyObject?.error) {
                    createEmptyObject.reset();
                  }
                },
                onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.trim();
                }
              })}
            />
          </Input.Container>
          {errors.tableName && (
            <p className="mt-1 text-destructive" data-testid="new-table-name-error">
              {errors.tableName.message}
            </p>
          )}
        </Form.Section>
        <div className="rounded-xl bg-brand-muted p-4">
          <p className="text-sm font-light text-default">
            {t('components.roles_list.description')}
          </p>
        </div>
        <div className="flex justify-end gap-2" data-testid="create-table-form-dialog-footer">
          <Button
            data-testid="create-table-form-button-save"
            type="submit"
            isLoading={createEmptyObject?.isPending}
            disabled={!!errors.tableName}
          >
            {t('actions.save')}
          </Button>
        </div>
      </Form>
    </>
  );
}
