import { useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TABLE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { ConfirmCopyStep } from '@/pages/tables/tables-tree/table-dropdown-menu/duplicate-table-form/ConfirmCopyStep';
import { SourceTargetSelectStep } from './SourceTargetSelectStep';

interface DuplicateFormProps {
  table: KnackObject;
  isUser?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onStepChange?: (step: number) => void;
}

export enum CopyTableOptions {
  NewTable = 'new-table',
  ExistingTable = 'existing-table'
}

export enum FormSteps {
  SourceTargetSelect = 1,
  ConfirmCopy = 2
}

export const INITIAL_STEP = FormSteps.SourceTargetSelect;
export const FINAL_STEP = FormSteps.ConfirmCopy;

export function DuplicateTableForm({
  table,
  isUser,
  onSave,
  onCancel,
  onStepChange
}: DuplicateFormProps) {
  const [t] = useTranslation();
  type FormSchema = z.infer<typeof formSchema>;
  const { data: app } = useApplicationQuery();
  const { copyTableMutation, copyFieldsMutation } = useTableMutation();
  const [currentStep, setCurrentStep] = useState<FormSteps>(INITIAL_STEP);

  const baseSchema = z.object({
    copyTableOption: z.nativeEnum(CopyTableOptions),
    fieldsToCopy: z
      .string()
      .array()
      .min(1, t('components.dialogs.tables.duplicate.at_least_one_field'))
  });

  const formSchema = baseSchema
    .extend({
      tableName: z.string().nullable(),
      selectedTableKey: z.string().nullable(),
      network: z.string().optional()
    })
    .superRefine((data, context) => {
      if (data.copyTableOption !== CopyTableOptions.NewTable) {
        return;
      }

      if (!data.tableName || data.tableName.length === 0) {
        context.addIssue({
          code: 'custom',
          path: ['tableName'],
          message: t('components.dialogs.tables.settings.table_name_required')
        });
      } else if (data.tableName.length > TABLE_NAME_MAX_CHAR_LENGTH) {
        context.addIssue({
          code: 'custom',
          path: ['tableName'],
          message: t('components.dialogs.tables.settings.table_name_max_length', {
            max: TABLE_NAME_MAX_CHAR_LENGTH
          })
        });
      }
    });
  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tableName: `${table.name} Copy`,
      copyTableOption: CopyTableOptions.NewTable,
      selectedTableKey: app?.objects[0].key,
      fieldsToCopy: table.fields.map((field) => field.key)
    }
  });

  const { handleSubmit, watch, setValue, clearErrors, setError, getValues } = formMethods;

  if (!app) return null;

  const copyTableOption = watch('copyTableOption');
  const selectedTableKey = watch('selectedTableKey');

  const handleSelectChange = (newValue: string) => {
    setValue('selectedTableKey', newValue, { shouldValidate: true });
  };

  const handleNext = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);

    if (onStepChange) {
      onStepChange(newStep);
    }
  };

  const handleBack = () => {
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
    clearErrors();

    if (onStepChange) {
      onStepChange(newStep);
    }
  };

  const selectAllFields = () => {
    setValue(
      'fieldsToCopy',
      table.fields.map((field) => field.key)
    );
  };

  const mutationCallbacks = {
    onSuccess: () => {
      setCurrentStep(INITIAL_STEP);

      if (onSave) {
        onSave();
      }
    },
    onError: (error) => {
      setError('network', {
        type: 'manual',
        message: t(`errors.${error.response.data.errorCode}`)
      });
    }
  };

  const selectNoneFields = () => {
    const selectedFields = getValues('fieldsToCopy');

    const fieldsToRetain = selectedFields.filter((field: string) => {
      const targetField = table.fields.find((f) => f.key === field);
      return targetField && targetField.user === true;
    });

    setValue('fieldsToCopy', fieldsToRetain);
  };

  const onSubmitHandler: SubmitHandler<FormSchema> = (values) => {
    if (values.copyTableOption === CopyTableOptions.NewTable) {
      if (!values.tableName) return;

      copyTableMutation.mutate(
        {
          object_key: table.key,
          object_name: values.tableName,
          fields: values.fieldsToCopy,
          isUser
        },
        mutationCallbacks
      );
    } else {
      if (!values.selectedTableKey) return;

      copyFieldsMutation.mutate(
        {
          object_key: table.key,
          target_object_id: values.selectedTableKey,
          fields: values.fieldsToCopy,
          object_name: table.name
        },
        mutationCallbacks
      );
    }
  };

  const getButtonText = () => {
    if (isUser) {
      return t('components.dialogs.roles.copy.create_role');
    }
    if (copyTableOption === CopyTableOptions.NewTable) {
      return t('components.dialogs.tables.duplicate.create_table');
    }
    return t('components.dialogs.tables.duplicate.copy_fields');
  };

  return (
    <FormProvider {...formMethods}>
      <Form
        data-testid="duplicate-table-form"
        onSubmit={handleSubmit(onSubmitHandler)}
        className="flex min-h-56 flex-col justify-between"
      >
        {currentStep === FormSteps.SourceTargetSelect && (
          <SourceTargetSelectStep
            copyTableOption={copyTableOption}
            handleSelectChange={handleSelectChange}
            table={table}
            app={app}
            isUser={isUser}
          />
        )}
        {currentStep === FormSteps.ConfirmCopy && (
          <ConfirmCopyStep
            table={table}
            copyTableOption={copyTableOption}
            selectedTableKey={selectedTableKey}
            app={app}
            selectAllFields={selectAllFields}
            selectNoneFields={selectNoneFields}
            handleBack={handleBack}
            isUser={isUser}
          />
        )}

        <div className="mt-auto flex justify-end gap-2" data-testid="duplicate-table-dialog-footer">
          {currentStep === FormSteps.SourceTargetSelect ? (
            <Button onClick={onCancel} intent="minimal" data-testid="duplicate-table-button-cancel">
              {t('actions.cancel')}
            </Button>
          ) : (
            <Button intent="minimal" onClick={handleBack} data-testid="duplicate-table-button-back">
              {t('actions.back')}
            </Button>
          )}
          {currentStep < FINAL_STEP && (
            <Button onClick={handleNext} data-testid="duplicate-table-button-next">
              {t('actions.next')}
            </Button>
          )}
          {currentStep === FINAL_STEP && (
            <Button
              type="submit"
              data-testid="duplicate-table-button-create"
              isLoading={copyTableMutation.isPending || copyFieldsMutation.isPending}
            >
              {getButtonText()}
            </Button>
          )}
        </div>
      </Form>
    </FormProvider>
  );
}
