import { type MultipleChoiceField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { MultipleChoiceEdit } from '@/components/data-table/display/fields/multiple-choice/MultipleChoiceEdit';
import { MultipleChoiceValues } from '@/components/data-table/display/fields/multiple-choice/MultipleChoiceValues';

export function MultipleChoiceRender(props: FieldRenderProps<MultipleChoiceField>) {
  const { isFloating, rawValue } = props;

  if (isFloating) {
    return <MultipleChoiceEdit {...props} />;
  }

  return <MultipleChoiceValues rawValue={rawValue} />;
}
