import { cn } from '@/utils/tailwind';

interface ViewSourceBannerProps {
  children: React.ReactNode;
  className?: string;
}

export function ViewSourceBanner({ className, children }: ViewSourceBannerProps) {
  return <div className={cn('rounded-lg bg-subtle p-2 text-xs', className)}>{children}</div>;
}
