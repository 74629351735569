import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { FieldSelect } from '@/components/FieldSelect';
import { getCompatibleFields } from '@/pages/tables/toolkit-sidebar/rules/utils';
import { getDisabledValidationRuleActionFields } from '@/pages/tables/toolkit-sidebar/rules/validation-rules/utils';

type FormHeaderProps = {
  fields: KnackField[];
  fieldKey?: string;
};

export function FormTargetField(props: FormHeaderProps) {
  const { fields, fieldKey } = props;

  const { control } = useFormContext();
  const [t] = useTranslation();

  return (
    <Form.Section>
      <Form.Label htmlFor="validation-rule-field-select">
        {t('components.data_table.right_sidebar.validation_rules.select_field_label')}
      </Form.Label>
      <Controller
        control={control}
        name="targetFieldKey"
        render={({ field: formField }) => (
          <FieldSelect
            id="validation-rule-field-select"
            data-testid="validation-rule-field-select"
            fields={getCompatibleFields(fields)}
            disabledFields={getDisabledValidationRuleActionFields()}
            onFieldChange={formField.onChange}
            defaultValue={fieldKey}
            {...formField}
          />
        )}
      />
    </Form.Section>
  );
}
