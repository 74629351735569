import { useTranslation } from 'react-i18next';
import { Input, Label, RichTextEditor } from '@knack/asterisk-react';

import { type FormView } from '@/types/schema/views/FormView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function FormGeneralSettingsCategory() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<FormView>();
  const updateViewSchema = useUpdateView<FormView>();

  const i18nParams = { viewName: t(`views.view_names.${view.type}`) };

  return (
    <BuilderAccordion>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.general.title_and_description')}
      >
        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-title`}>
            {t('pages.element_settings.general.view_title', i18nParams)}
          </Label>
          <Input
            id={`${view.key}-view-title`}
            className="text-sm"
            value={view.title ?? ''}
            onChange={(e) => {
              updateViewSchema({
                title: e.target.value
              });
            }}
          />
          <p className="mt-2 text-xs text-subtle">
            {t('pages.element_settings.general.title_hint', i18nParams)}
          </p>
        </div>
        <div>
          <span className="mb-2 block text-sm font-medium">
            {t('pages.element_settings.general.view_description', i18nParams)}
          </span>
          <RichTextEditor
            onUpdate={({ editor }) => {
              updateViewSchema({
                description: editor.getText() ? editor.getHTML() : ''
              });
            }}
            content={view.description || ''}
          />
          <p className="mt-2 text-xs text-subtle">
            {t('pages.element_settings.general.description_hint', i18nParams)}
          </p>
        </div>
      </BuilderAccordion.Item>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.form.categories.general_settings.button_settings')}
      >
        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-submit-button-text`}>
            {t('pages.element_settings.form.categories.general_settings.submit_button_text')}
          </Label>
          <Input
            id={`${view.key}-submit-button-text`}
            className="text-sm"
            value={view.submitButtonText ?? t('views.new_view_defaults.form.submit_button_text')}
            onChange={(e) => {
              updateViewSchema({
                submitButtonText: e.target.value
              });
            }}
          />
        </div>
        <div>
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-reload-form-text`}>
            {t('pages.element_settings.form.categories.general_settings.reload_form_text')}
          </Label>
          <Input
            id={`${view.key}-reload-form-text`}
            className="text-sm"
            value={view.reload_form_text ?? t('views.new_view_defaults.form.reload_form_text')}
            onChange={(e) => {
              updateViewSchema({
                reload_form_text: e.target.value
              });
            }}
          />
        </div>
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
