import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiDocument as DocumentIcon,
  HiFolderOpen as FolderIcon,
  HiLockClosed as LockIcon
} from 'react-icons/hi2';
import { DropdownMenu } from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { NewPageDialog } from '@/pages/pages/page-tree/new-page-dropdown-menu/NewPageDialog';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';

export interface PageDropdownMenuProps {
  triggerElement: React.ReactNode;
  onDropdownMenuOpenChange?: (isOpen: boolean) => void;
}

export function NewPageDropdownMenu({
  triggerElement,
  onDropdownMenuOpenChange
}: PageDropdownMenuProps) {
  const [t] = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { isAddNewPageDialogOpen, setIsAddNewPageDialogOpen, newPageType, setNewPageType } =
    usePagesPageContext();
  const { data: app } = useApplicationQuery();

  return (
    <>
      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={(isOpen) => {
          if (onDropdownMenuOpenChange) {
            onDropdownMenuOpenChange(isOpen);
          }
          setDropdownOpen(isOpen);
        }}
        data-testid="new-page-dropdown-menu"
        modal={false}
      >
        <DropdownMenu.Trigger data-testid="new-page-dropdown-trigger" asChild>
          {triggerElement}
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="text-sm" data-testid="new-page-dropdown-content">
          <DropdownMenu.Label>{t('pages.page_tree.add_a_new')}</DropdownMenu.Label>
          <DropdownMenu.Item
            onClick={() => {
              setNewPageType('page');
              setIsAddNewPageDialogOpen(true);
            }}
            data-testid="new-page-dropdown-public-item"
          >
            <DocumentIcon size={16} className="mr-2" />
            {t('pages.page_tree.page')}
          </DropdownMenu.Item>
          {app?.users.scope === 'scene' && (
            <DropdownMenu.Item
              onClick={() => {
                setNewPageType('authentication');
                setIsAddNewPageDialogOpen(true);
              }}
              data-testid="new-page-dropdown-private-item"
            >
              <LockIcon className="mr-2" size={16} />
              {t('pages.page_tree.login_page')}
            </DropdownMenu.Item>
          )}
          <DropdownMenu.Item
            onClick={() => {
              setNewPageType('menu');
              setIsAddNewPageDialogOpen(true);
            }}
            data-testid="new-page-dropdown-dropdown-menu-item"
          >
            <FolderIcon className="mr-2" size={16} />
            {t('pages.page_tree.dropdown_menu')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>

      <NewPageDialog
        isDialogOpen={isAddNewPageDialogOpen}
        setIsDialogOpen={setIsAddNewPageDialogOpen}
        newPageType={newPageType}
      />
    </>
  );
}
