import { useTranslation } from 'react-i18next';
import { Tabs, Tooltip, type TabsListItem } from '@knack/asterisk-react';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { PageAccessSettings } from '@/pages/pages/settings-panel/page-settings/page-access/PageAccessSettings';
import { PageSettingsGeneral } from '@/pages/pages/settings-panel/page-settings/PageSettingsGeneral';

export function PageSettings() {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();

  const panelTitle =
    page.type === 'authentication'
      ? t('pages.login_page_settings.heading')
      : t('pages.settings.heading');

  const items: TabsListItem[] = [
    {
      children: t('pages.settings.tabs.general'),
      value: 'general'
    },
    {
      children: (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <span>{t('pages.settings.tabs.rules')}</span>
          </Tooltip.Trigger>
          <Tooltip.Content>{t('keywords.coming_soon')}</Tooltip.Content>
        </Tooltip>
      ),
      value: 'rules',
      isDisabled: true
    },
    {
      children: t('pages.settings.tabs.access'),
      value: 'access'
    }
  ];

  return (
    <CollapsiblePanel.Content title={panelTitle}>
      <Tabs defaultValue="general">
        <Tabs.List items={items} shouldDisableResponsive />
        <Tabs.Content value="general" className="p-0 text-default">
          <PageSettingsGeneral />
        </Tabs.Content>
        <Tabs.Content value="access" className="p-0 text-default">
          {import.meta.env.PUBLIC_IS_PRODUCTION === 'true' ? (
            <div className="mt-4">{t('keywords.coming_soon')}</div>
          ) : (
            <PageAccessSettings />
          )}
        </Tabs.Content>
      </Tabs>
    </CollapsiblePanel.Content>
  );
}
