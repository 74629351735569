import { useMemo, useState, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';
import { type editor } from 'monaco-editor';

const equationNumericFunctionList = [
  'abs',
  'cbrt',
  'ceil',
  'cos',
  'factorial',
  'floor',
  'gcd',
  'log',
  'log10',
  'max',
  'mean',
  'median',
  'min',
  'pow',
  'random',
  'randomInt',
  'round',
  'sign',
  'sin',
  'std',
  'sqrt',
  'sum',
  'tan',
  'var'
];

const equationDateFunctionList = [
  'currentTime',
  'currentDate',
  'getDateDayOfWeek',
  'getDateDayOfMonth',
  'getDateYear',
  'getDateWeek',
  'getDateMonth',
  'getDateLastDateOfMonth',
  'netWorkDays',
  'max',
  'mean',
  'median',
  'min',
  'randomInt',
  'std',
  'var'
];

const concatenationDateFunctionList = [
  'left',
  'right',
  'mid',
  'length',
  'replace',
  'regexReplace',
  'extractRegex',
  'lower',
  'upper',
  'capitalize',
  'trim',
  'trimLeft',
  'trimRight',
  'random',
  'numberToWords',
  'formatDate'
];

export function FormulaSelector({
  editorRef,
  equationType
}: {
  editorRef: MutableRefObject<editor.IStandaloneCodeEditor | undefined>;
  equationType: 'numeric' | 'date' | 'strings';
}) {
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const equationFunctionList = useMemo(() => {
    if (equationType === 'numeric') {
      return equationNumericFunctionList;
    }
    if (equationType === 'date') {
      return equationDateFunctionList;
    }
    if (equationType === 'strings') {
      return concatenationDateFunctionList;
    }
    return [];
  }, [equationType]);

  const filteredList = useMemo(
    () =>
      equationFunctionList.filter(
        (func) =>
          func.toLowerCase().includes(searchTerm.toLowerCase()) ||
          t(
            `components.data_table.attributes.field_settings.rich_text_formula.functions.${func}.title`
          )
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      ),
    [equationFunctionList, searchTerm, t]
  );

  const translationPrefix =
    equationType === 'strings' ? 'text_formula_functions' : 'equation_functions';

  return (
    <>
      <div className="p-2">
        <p className="p-2 text-xs">
          {t('components.data_table.attributes.field_settings.rich_text_formula.insert_function')}
        </p>
        <Input
          className="m-0 w-full min-w-0 rounded-none border-0 border-b-2 border-b-subtle text-sm focus:outline-0"
          placeholder={t('components.data_table.select.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="max-h-[200px] max-w-[320px] overflow-auto p-2 pr-0 pt-0 ">
        <div className="flex flex-col">
          {filteredList.length === 0 && (
            <p className="p-2 text-sm text-default">
              {t(
                'components.data_table.attributes.field_settings.rich_text_formula.function_not_found'
              )}
            </p>
          )}
          {filteredList.map((func) => {
            const snakeCasedFunction = snakeCase(func);

            return (
              <Button
                key={func}
                intent="minimal"
                className="mr-2 flex h-auto flex-col p-2 text-left font-normal"
                onClick={(e) => {
                  e.preventDefault();

                  const range = editorRef.current?.getSelection() || {
                    startLineNumber: 1,
                    startColumn: 1,
                    endLineNumber: 1,
                    endColumn: 1
                  };

                  // Insert the function with the selected content as the argument
                  const previousSelectedContent =
                    editorRef.current?.getModel()?.getValueInRange(range) || '';

                  editorRef.current?.executeEdits('my-source', [
                    {
                      range,
                      text: `${func}(${previousSelectedContent})`,
                      forceMoveMarkers: true
                    }
                  ]);
                  editorRef.current?.focus();
                }}
              >
                <div className="mb-0.5 flex w-full justify-between text-brand">
                  <p>
                    {t(
                      `components.data_table.attributes.field_settings.rich_text_formula.${translationPrefix}.${snakeCasedFunction}.title`
                    )}
                  </p>
                  <p className="mt-0.5 text-xs">{func}</p>
                </div>
                <p className="w-full text-xs text-default">
                  {t(
                    `components.data_table.attributes.field_settings.rich_text_formula.${translationPrefix}.${snakeCasedFunction}.description`
                  )}
                  <br />
                  {t(
                    `components.data_table.attributes.field_settings.rich_text_formula.${translationPrefix}.${snakeCasedFunction}.usage`
                  )}
                </p>
              </Button>
            );
          })}
        </div>
      </div>
    </>
  );
}
