import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type TableView } from '@/types/schema/views/TableView';
import { InlineKnackField } from '@/components/InlineKnackField';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

function FilterGroup({ index, field, filterRule }) {
  const [t] = useTranslation();

  const firstItem = index === 0;

  return (
    <div>
      <span>{firstItem ? t('components.rules.where') : t('components.rules.and_uppercase')}</span>
      <InlineKnackField
        fieldName={field.name}
        fieldType={field.type}
        className="mx-1 bg-action text-default"
      />
      {filterRule.operator} <span className="rounded-lg bg-action p-1">{filterRule.value}</span>
    </div>
  );
}

export function SourceFiltersGroupsCard() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();

  const firstSourceFiltersGroup = view.source.criteria.rules;
  const restSourceFiltersGroups = view.source.criteria.groups;
  const hasSourceFilters = firstSourceFiltersGroup.length > 0;

  const getKnackField = (fieldKey: KnackFieldKey) =>
    sourceObject.fields.find((f) => f.key === fieldKey);

  if (!hasSourceFilters) {
    return (
      <p>
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.all_records_are_shown'
        )}
      </p>
    );
  }

  return (
    <div>
      <p className="text-xs">
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.show_records_where'
        )}
      </p>
      <div className="rounded-lg bg-subtle p-2">
        {firstSourceFiltersGroup.map((rule, ruleIndex) => {
          const knackField = getKnackField(rule.field);

          if (!knackField) {
            return null;
          }

          return (
            <FilterGroup
              // eslint-disable-next-line react/no-array-index-key
              key={`${rule.field}-${ruleIndex}-group`}
              index={ruleIndex}
              field={knackField}
              filterRule={rule}
            />
          );
        })}
      </div>
      {restSourceFiltersGroups.map((groupRule, groupRuleIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={groupRuleIndex}>
          <p className="my-2">{t('components.rules.or_uppercase')}</p>
          <div className="rounded-lg bg-subtle p-2">
            {groupRule.map((rule, ruleIndex) => {
              const knackField = getKnackField(rule.field);

              if (!knackField) {
                return null;
              }

              return (
                <FilterGroup
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${rule.field}-${ruleIndex}-group`}
                  index={ruleIndex}
                  field={knackField}
                  filterRule={rule}
                />
              );
            })}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
