/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { Input, Label, RichTextEditor, Switch } from '@knack/asterisk-react';

import type { ChartType, ReportView } from '@/types/schema/views/ReportView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { AreaChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/AreaChartAppearanceSettings';
import { BarChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/BarChartAppearanceSettings';
import { LineChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/LineChartAppearanceSettings';
import { PieChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/PieChartAppearanceSettings';
import { ReportTableInfo } from '@/pages/pages/settings-panel/view-settings/report/ReportTableInfo';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ReportGeneral() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));
  const chartType: ChartType = view.rows[0].reports[0].type;

  return (
    <>
      <ReportTableInfo view={view} />
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.report.categories.general.title_and_description')}
        >
          <div className="mb-4">
            <div className="mb-2">{t('keywords.title')}</div>
            <Input
              value={view.title ?? ''}
              className="text-sm"
              onChange={(e) => {
                updateViewSchema({
                  title: e.target.value
                });
              }}
            />
            <p className="mt-2 text-xs text-subtle">
              {t('pages.element_settings.report.categories.general.leave_this_field_blank_title')}
            </p>
          </div>
          <div>
            <div className="mb-2">{t('keywords.description')}</div>
            <RichTextEditor
              onUpdate={({ editor }) => {
                updateViewSchema({
                  description: editor.getText() ? editor.getHTML() : ''
                });
              }}
              content={view.description || ''}
            />
            <p className="mt-2 text-xs text-subtle">
              {t(
                'pages.element_settings.report.categories.general.leave_this_field_blank_description'
              )}
            </p>
          </div>
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.report.categories.general.appearance')}
        >
          {chartType === 'bar' && <BarChartAppearanceSettings />}
          {chartType === 'pie' && <PieChartAppearanceSettings />}
          {chartType === 'area' && <AreaChartAppearanceSettings />}
          {chartType === 'line' && <LineChartAppearanceSettings />}
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          isDefaultOpen
          label={`${t('pages.element_settings.report.categories.general.options')} (${t('keywords.coming_soon')})`}
        >
          <div className="mb-2 flex items-center gap-2">
            <Switch
              id="show-hide-data-table"
              checked
              disabled={!hasAccessToNonPublicFeatures}
              onCheckedChange={(state) => console.log('change show/hide data table', state)}
            />
            <Label htmlFor="show-hide-data-table">
              {t('pages.element_settings.report.categories.general.show_hide_data_table')}
            </Label>
          </div>
          <div className="mb-2 flex items-center gap-2">
            <Switch
              id="hide-empty-groups"
              checked
              disabled={!hasAccessToNonPublicFeatures}
              onCheckedChange={(state) => console.log('change hide empty groups', state)}
            />
            <Label htmlFor="hide-empty-groups">
              {t('pages.element_settings.report.categories.general.hide_empty_groups')}
            </Label>
          </div>
          <div className="mb-2 flex items-center gap-2">
            <Switch
              id="hide-negative-ranges"
              checked
              disabled={!hasAccessToNonPublicFeatures}
              onCheckedChange={(state) => console.log('change hide negative ranges', state)}
            />
            <Label htmlFor="hide-negative-ranges">
              {t('pages.element_settings.report.categories.general.hide_negative_ranges')}
            </Label>
          </div>
          <div className="mb-2 flex items-center gap-2">
            <Switch
              id="expand-details-in-popup"
              checked
              disabled={!hasAccessToNonPublicFeatures}
              onCheckedChange={(state) => console.log('change expand details in popup', state)}
            />
            <Label htmlFor="expand-details-in-popup">
              {t('pages.element_settings.report.categories.general.expand_details_in_popup')}
            </Label>
          </div>
          <div className="flex items-center gap-2">
            <Switch
              id="allow-exporting"
              checked
              disabled={!hasAccessToNonPublicFeatures}
              onCheckedChange={(state) => console.log('change allow exporting', state)}
            />
            <Label htmlFor="allow-exporting">
              {t('pages.element_settings.report.categories.general.allow_exporting')}
            </Label>
          </div>
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </>
  );
}
