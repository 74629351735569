import { getApplicationBasePathSegments } from '@/utils/application';
import { cn } from '@/utils/tailwind';
import { type ScreenSize } from '@/components/LiveAppPreview';

interface LiveAppFrameProps {
  pagePath: string;
  registerIFrame: (iFrameElement: HTMLIFrameElement) => void;
  screenSize?: ScreenSize;
  isPreviewMode?: boolean;
}

export function LiveAppFrame({
  pagePath,
  registerIFrame,
  screenSize,
  isPreviewMode = false
}: LiveAppFrameProps) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return (
    <div
      className={cn('h-full w-full bg-subtle', {
        'py-8': screenSize && screenSize.type !== 'desktop',
        'h-[calc(100%-theme(spacing.16))]': isPreviewMode
      })}
    >
      <iframe
        ref={(iFrameElement) => {
          if (!iFrameElement) return;
          registerIFrame(iFrameElement);
        }}
        src={`${import.meta.env.PUBLIC_LIVE_APP_URL}/${accountSlug}/${appSlug}/${pagePath}${isPreviewMode ? '?preview=true' : ''}`}
        title="Live App"
        className={cn('mx-auto size-full', {
          'rounded-md': screenSize && screenSize.type !== 'desktop'
        })}
        style={{ maxWidth: screenSize?.maxWidth ?? 'none' }}
      />
    </div>
  );
}
