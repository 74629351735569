import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiBolt as TasksIcon } from 'react-icons/hi2';
import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Button, useToast } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type Task } from '@/types/schema/tasks/KnackTask';
import { useTaskMutation } from '@/hooks/api/mutations/useTaskMutation';
import { useDndUtils } from '@/hooks/useDndUtils';
import { EmptyState } from '@/components/EmptyState';
import { SidePanel } from '@/components/SidePanel';
import { TaskCardWrapper } from '@/pages/tables/toolkit-sidebar/tasks/TaskCardWrapper';
import { TaskForm } from './TaskForm';

type TasksPanelProps = {
  table: KnackObject;
  tasks: Task[];
};

export function TasksPanel({ table, tasks }: TasksPanelProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { addMutation, sortMutation } = useTaskMutation();

  const [tasksList, setTasksList] = useState(tasks);

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [shouldRenderAddTaskForm, setShouldRenderAddTaskForm] = useState(false);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const draggedTask = tasksList.find((f) => f.key === active.id);

      if (!draggedTask) return;

      const oldIndex = tasksList.findIndex((v) => v.key === active.id);
      const newIndex = tasksList.findIndex((v) => v.key === over.id);
      const updatedTasksList = arrayMove(tasksList, oldIndex, newIndex);

      setTasksList(updatedTasksList);

      sortMutation.mutate(
        {
          tableKey: table.key,
          taskOrder: updatedTasksList.map((task: Task) => task.key)
        },
        {
          onError: () => {
            presentToast({
              title: t('components.data_table.right_sidebar.tasks.errors.sort_error')
            });
          }
        }
      );
    }
  };

  const onTaskSave = (taskData: Task) => {
    const isNewTask = !taskData.key;

    if (isNewTask) {
      addMutation.mutate(
        {
          tableKey: table.key,
          taskData
        },
        {
          onSuccess: () => {
            presentToast({
              title: t('components.data_table.right_sidebar.tasks.task_save_success')
            });
            setShouldRenderAddTaskForm(false);
          },
          onError: () => {
            presentToast({
              title: t('components.data_table.right_sidebar.tasks.errors.task_save_error')
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    setTasksList(tasks);
  }, [tasks]);

  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title className="text-xl font-medium leading-6" data-testid="tasks-rules-title">
          {t('components.data_table.right_sidebar.tasks.title')}
        </SidePanel.Title>
        <SidePanel.Description className="text-xs">
          {t('components.data_table.right_sidebar.tasks.subtitle')}
        </SidePanel.Description>
      </SidePanel.Header>

      {tasks.length === 0 && !shouldRenderAddTaskForm && (
        <div className="mt-2">
          <EmptyState>
            <EmptyState.Icon icon={<TasksIcon />} />
            <EmptyState.Description
              className="text-emphasis"
              data-testid="tasks-rules-empty-state-text"
            >
              {t('components.data_table.right_sidebar.tasks.description')}
            </EmptyState.Description>
          </EmptyState>
        </div>
      )}

      {shouldRenderAddTaskForm ? (
        <div className="-mx-2 mt-2 h-[calc(100%-64px)] overflow-auto px-2">
          <TaskForm
            table={table}
            onCancel={() => {
              setShouldRenderAddTaskForm(false);
            }}
            onTaskSave={onTaskSave}
          />
        </div>
      ) : (
        <Button
          intent="secondary"
          className="mt-4 w-full"
          onClick={() => setShouldRenderAddTaskForm(true)}
          data-testid="tasks-rule-add-button"
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {t('components.data_table.right_sidebar.tasks.task')}
        </Button>
      )}

      <DndContext
        sensors={optimizedSensors}
        collisionDetection={verticalListCollisionDetection}
        onDragEnd={(e) => handleDragEnd(e)}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={tasksList.map((task: Task) => task.key)}>
          <div className="space-y-4">
            {tasksList.map((task: Task) => (
              <TaskCardWrapper
                key={task.key}
                task={task}
                table={table}
                hasTableMoreThanOneTask={tasks.length > 1}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
}
