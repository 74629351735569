import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Chip } from '@knack/asterisk-react';

import { sanitizeHTMLTags } from '@/utils/formatters';
import { cn } from '@/utils/tailwind';
import { type MultipleChoiceField } from '@/components/data-table/display/fields/Field';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function MultipleChoiceValues(props: {
  rawValue: MultipleChoiceField['rawValue'];
  onDeleteOption?: (option: string) => void;
}) {
  const { rawValue, onDeleteOption } = props;
  const selectedCell = useDataTableStore().use.selectedCell();

  return (
    <div
      className="group size-full overflow-auto bg-base pr-6"
      data-testid="multiple-choice-values"
    >
      <div
        className={cn(
          'right-1 top-1 hidden h-6 items-center rounded bg-default group-hover:absolute group-hover:flex',
          {
            'bg-emphasis': selectedCell?.isEditing
          }
        )}
      >
        <ChevronDownIcon className="mx-0.5" size={16} />
      </div>

      <div className="flex flex-wrap">
        {typeof rawValue === 'string' && rawValue && (
          <Chip
            text={sanitizeHTMLTags(rawValue)}
            key={sanitizeHTMLTags(rawValue)}
            className="m-1 mb-0"
            {...(onDeleteOption && {
              onClose: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteOption(rawValue);
              }
            })}
          />
        )}
        {typeof rawValue === 'object' &&
          rawValue.length > 0 &&
          rawValue.map((item) => (
            <Chip
              text={sanitizeHTMLTags(item)}
              key={sanitizeHTMLTags(item)}
              className="m-1 mb-0"
              {...(onDeleteOption && {
                onClose: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteOption(item);
                }
              })}
            />
          ))}
      </div>
    </div>
  );
}
