import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiPencil as PencilIcon,
  HiOutlineCheck as SaveIcon,
  HiMiniEllipsisHorizontal as ThreeDotsIcon,
  HiTrash as TrashIcon
} from 'react-icons/hi2';
import { MdOutlineHistory as HistoryIcon } from 'react-icons/md';
import { TbArrowBackUp as DiscardIcon } from 'react-icons/tb';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { Button, Dialog, DropdownMenu, Spinner, Table } from '@knack/asterisk-react';

import { useDeleteRecordsMutation } from '@/hooks/api/mutations/useDeleteRecordsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { cn } from '@/utils/tailwind';
import { type EditRecordModalOutletContext } from '@/components/data-table/display/edit-record/EditRecordModal';
import { type PartialField } from '@/components/data-table/display/fields/Field';
import { FieldRender } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { ROUTES } from '@/Router';

const NUMBER_OF_COLUMNS_TO_DISPLAY = 3;

export function RowActions({ rowId }: { rowId: string }) {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { data: application } = useApplicationQuery();
  const [t] = useTranslation();
  const recordsDeleteMutation = useDeleteRecordsMutation();
  const tableKey = useDataTableStore().use.objectKey();
  const { deleteRow, saveDraftRow, cancelRowEdit } = useDataTableStore().use.actions();
  const tableMetadata = application?.objects.find((obj) => obj.key === tableKey);

  const navigate = useNavigate();

  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));
  const rowValues = useDataTableStore().use.getCellValues(rowId);
  const unsavedRows = useDataTableStore().use.draftRows();
  const currentLoadingRows = useDataTableStore().use.currentLoadingRows();
  const rowHasFilesPending = useDataTableStore().use.rowHasFilesPending(rowId);

  const rowErrors = useDataTableStore().use.rowErrors(rowId);

  const RevertIcon = useMemo(
    () => (rowId.startsWith('new_row') ? TrashIcon : DiscardIcon),
    [rowId]
  );

  if (recordsDeleteMutation.isPending || currentLoadingRows[rowId] || rowHasFilesPending) {
    return (
      <div className="mt-1 flex justify-center px-4 py-2">
        <Spinner className="size-4" />
      </div>
    );
  }

  if (!tableMetadata) {
    return null;
  }

  return (
    <div
      className={cn('flex h-full items-start space-x-3 border-l-4 border-l-transparent p-2', {
        'border-l-4 border-success': unsavedRows[rowId],
        'border-l-4 border-destructive': rowErrors && Object.keys(rowErrors).length > 0
      })}
    >
      {unsavedRows[rowId] ? (
        <>
          <RevertIcon
            className="mt-1 cursor-pointer"
            title="Cancel"
            size={18}
            onClick={async () => {
              await cancelRowEdit(rowId);
            }}
          />
          <SaveIcon
            className="mt-1 cursor-pointer"
            title="Save row"
            size={18}
            onClick={async () => {
              await saveDraftRow(rowId);
            }}
          />
        </>
      ) : (
        <>
          {/* 
          // TODO: FE-616 - Bulk actions will be implemented in the future
          <Checkbox /> 
          */}

          <DropdownMenu modal={false}>
            <DropdownMenu.Trigger
              className="py-1.5"
              data-testid={`data-table-header-${rowId}-contextual-menu`}
            >
              <ThreeDotsIcon />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="start" alignOffset={-20}>
              <DropdownMenu.Item
                data-testid={`data-table-${rowId}-contextual-menu-edit-record`}
                onSelect={() =>
                  navigate(
                    generatePath(ROUTES.TABLES_ID_RECORD_ID_EDIT, { id: tableKey, recordId: rowId })
                  )
                }
              >
                <PencilIcon size={18} className="mr-2" />
                {t('components.data_table.row_actions.edit_record')}
              </DropdownMenu.Item>
              <DropdownMenu.Item
                data-testid={`data-table-${rowId}-contextual-menu-record-history`}
                disabled={!hasAccessToNonPublicFeatures}
              >
                <HistoryIcon size={18} className="mr-2" />
                {t('components.data_table.row_actions.record_history')}
              </DropdownMenu.Item>
              <DropdownMenu.Separator />

              <DropdownMenu.Item
                data-testid={`data-table-${rowId}-contextual-menu-delete-record`}
                className="text-destructive"
                onSelect={() => {
                  setIsConfirmDialogOpen(true);
                }}
              >
                <DeleteIcon size={18} className="mr-2" />
                {t('components.data_table.row_actions.delete_record')}
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu>
          <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
            <Dialog.Content data-testid={`delete-record-confirmation-${rowId}`}>
              <Dialog.MainContent>
                <Dialog.Header>
                  <Dialog.Title>
                    {t('components.data_table.row_actions.delete_record_confirmation.title')}
                  </Dialog.Title>
                </Dialog.Header>
                <div className="mt-6">
                  <Trans i18nKey="components.data_table.row_actions.delete_record_confirmation.text">
                    <span className="font-semibold">everywhere</span>
                    <span className="font-semibold">This can`t be undone</span>
                  </Trans>

                  {/* All the styles are the same as in the Preview Table but the table don't look the same */}
                  <div className="mt-4 w-full overflow-auto pb-3">
                    <Table className="border-separate border-spacing-0 bg-base">
                      <Table.Header className="border-0 bg-base">
                        <Table.Row className="[&>:first-child]:rounded-tl-lg [&>:last-child]:rounded-tr-lg">
                          {tableMetadata?.fields.map(
                            (cell, index) =>
                              index < NUMBER_OF_COLUMNS_TO_DISPLAY && (
                                <Table.Head
                                  key={cell.key}
                                  className="border-separate overflow-hidden text-ellipsis whitespace-nowrap border border-b-0 bg-muted"
                                >
                                  {cell.name}
                                </Table.Head>
                              )
                          )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className=":last-child:border-0">
                        <Table.Row className="[&>:first-child]:rounded-bl-lg [&>:last-child]:rounded-br-lg">
                          {rowValues &&
                            tableMetadata?.fields.map(
                              (field, index) =>
                                index < NUMBER_OF_COLUMNS_TO_DISPLAY && (
                                  <Table.Cell
                                    key={`${field.key}value`}
                                    className="overflow-hidden text-ellipsis whitespace-nowrap border"
                                  >
                                    <div className="max-w-[300px]">
                                      <FieldRender
                                        {...({
                                          type: field.type,
                                          fieldId: field.key,
                                          ...rowValues[field.key]
                                        } as PartialField)}
                                        isFloating={false}
                                        isEditable={false}
                                      />
                                    </div>
                                  </Table.Cell>
                                )
                            )}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                  <p className="mt-4 text-sm text-default">
                    {t('components.data_table.row_actions.delete_record_confirmation.preview_text')}
                  </p>
                </div>
              </Dialog.MainContent>
              <Dialog.Footer>
                <Dialog.Close asChild>
                  <Button
                    intent="minimal"
                    data-testid={`delete-record-confirmation-${rowId}-cancel`}
                  >
                    {t('components.data_table.row_actions.delete_record_confirmation.cancel')}
                  </Button>
                </Dialog.Close>
                <Button
                  intent="destructive"
                  onClick={() => {
                    recordsDeleteMutation.mutate(
                      { objectKey: tableKey, deleteIds: [rowId] },
                      {
                        onSuccess: (response) => {
                          if (response.success === true) {
                            deleteRow(rowId);
                          }
                        }
                      }
                    );
                  }}
                  data-testid={`delete-record-confirmation-${rowId}-delete`}
                >
                  {t('components.data_table.row_actions.delete_record_confirmation.delete')}
                </Button>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog>

          {/* Renders the EditRecordModal via a router's outlet */}
          <Outlet
            context={
              {
                rowId,
                fields: tableMetadata.fields
              } satisfies EditRecordModalOutletContext
            }
          />
        </>
      )}
    </div>
  );
}
