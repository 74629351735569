import { useEffect, useMemo, useRef, type CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Button, Dialog, Spinner } from '@knack/asterisk-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type AccessorFnColumnDef,
  type Column,
  type HeaderContext
} from '@tanstack/react-table';
import { useVirtualizer, type Virtualizer } from '@tanstack/react-virtual';
import debounce from 'lodash.debounce';

import { type KnackField } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { TableFooter } from '@/components/data-table/display/footer/TableFooter';
import { FilterToast } from '@/components/data-table/display/header/filters/FilterToast';
import { RowActions } from '@/components/data-table/display/RowActions';
import { usePersistUrlParams } from '@/components/data-table/display/usePersistUrlParams';
import {
  COLUMNS_WIDTH,
  DATA_TABLE_FLOATING_ID,
  DATA_TABLE_MARGIN_BOTTOM,
  DATA_TABLE_PAGINATION_DEBOUNCE_TIME,
  DEFAULT_WIDTH,
  ID_COLUMN_WIDTH,
  NUMBER_OF_OVERSCAN_ROWS
} from '@/components/data-table/helpers/constants';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';
import { MemoizedCellRender } from './CellRender';
import { MemoizedFieldHeader } from './fields/FieldHeader';
import { MemoizedFloatingCell } from './FloatingCell';

const getColumnWidth = (column: KnackField) => {
  const isDateTimeWithCalendar = column.type === 'date_time' && column?.format?.calendar;
  if (isDateTimeWithCalendar) {
    return COLUMNS_WIDTH.date_time_calendar;
  }

  return COLUMNS_WIDTH[column.type] ? COLUMNS_WIDTH[column.type] : DEFAULT_WIDTH;
};

const renderCell = ({ column, rowId }: { column: KnackField; rowId: string }) => (
  <MemoizedCellRender column={column} rowId={rowId} isFloating={false} />
);

const getColumnStyles = (column: Column<string, unknown>): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 2 : 'auto'
  };
};

const renderHeader = (props: { header: HeaderContext<string, string>; field: KnackField }) => (
  <MemoizedFieldHeader {...props} />
);

export function DataTable() {
  const [t] = useTranslation();

  const pagesInView = useRef<number[]>([0]);
  // The scrollable element for your list
  const tableContainerRef = useRef<HTMLDivElement>(null);
  // We need to keep track of the sticky header height for calculations
  const tableHeaderRef = useRef<HTMLDivElement>(null);

  const objectKey = useDataTableStore().use.objectKey();
  const dataOrder = useDataTableStore().use.dataOrder();
  const fields = useDataTableStore().use.fields();
  const rowHeight = useDataTableStore().use.rowHeight();

  const draftRowsKeys = useDataTableStore().use.draftRowsKeys();

  const totalPages = useDataTableStore().use.totalPages();
  const { setScroll } = useDataTableStorePersist().use.actions();

  const scroll = useDataTableStorePersist().use.scroll();
  const filters = useDataTableStorePersist().use.filters();
  const isInitialLoad = useDataTableStore().use.isInitialLoad();
  const isFetchingPages = useDataTableStore().use.isFetchingPages();
  const dataNavigationMode = useDataTableStore().use.dataNavigationMode();

  const { loadPage, setPagesInViewport, setRowVirtualizer, getRowsPerPage } =
    useDataTableStore().use.actions();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<string>();
    const columnsMemo = fields.map((column: KnackField) =>
      columnHelper.accessor((originalRow) => originalRow, {
        id: column.key,
        header: (header) => renderHeader({ header, field: column }),
        cell: (props) => renderCell({ column, rowId: props.row.id }),
        size: getColumnWidth(column)
      })
    );

    // ID column
    columnsMemo.unshift(
      columnHelper.display({
        id: `actions`,
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => <div className="border-r-1 h-full border border-subtle bg-muted " />,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (props) => <RowActions rowId={props.row.id} />,
        size: ID_COLUMN_WIDTH
      }) as AccessorFnColumnDef<string, string>
    );
    return columnsMemo;
  }, [fields]);

  const columnsWidths = useMemo(
    () => JSON.parse(localStorage.getItem(`data-table-column-widths-${objectKey}`) || '{}'),
    [objectKey]
  );

  const table = useReactTable({
    data: dataOrder,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
    defaultColumn: {
      minSize: 100,
      maxSize: 800
    },
    columnResizeMode: 'onChange',

    getRowId: (originalRow) => originalRow,
    initialState: {
      columnSizing: columnsWidths,
      columnPinning: {
        left: ['actions']
      }
    }
  });

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   */
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    const localStorageColumnSizes: Record<string, number> = {};

    for (let i = 0; i < headers.length; i += 1) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
      if (header.id !== 'actions') {
        localStorageColumnSizes[header.id] = header.getSize();
      }
    }

    localStorage.setItem(
      `data-table-column-widths-${objectKey}`,
      JSON.stringify(localStorageColumnSizes)
    );
    return colSizes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnSizingInfo, table, objectKey, fields]);

  const { rows } = table.getRowModel();

  const calculatePagesInView = useMemo<(instance: Virtualizer<HTMLDivElement, Element>) => void>(
    () =>
      debounce((element: Virtualizer<HTMLDivElement, Element>) => {
        if (dataNavigationMode === 'pagination' || totalPages <= 0) return;
        if (element.scrollOffset === null) return;
        if (element.scrollOffset === 0) {
          setScroll(null);
        } else {
          setScroll(element.scrollOffset);
        }

        const currentStartElement =
          Math.floor(element.scrollOffset / rowHeight) - NUMBER_OF_OVERSCAN_ROWS;
        const currentBottomElement =
          Math.floor(
            (element.scrollOffset + (element.scrollElement?.clientHeight || 0)) / rowHeight
          ) + NUMBER_OF_OVERSCAN_ROWS;

        const rowsPerPage = getRowsPerPage();
        const currentStartElementPage = Math.max(0, Math.floor(currentStartElement / rowsPerPage));
        const currentBottomElementPage = Math.min(
          totalPages - 1,
          Math.floor(currentBottomElement / rowsPerPage)
        );

        const currentPagesInView = new Array(currentBottomElementPage - currentStartElementPage + 1)
          .fill(0)
          .map((_value, index) => currentStartElementPage + index);

        const newPages = currentPagesInView.filter(
          (currentPage) => !pagesInView.current.includes(currentPage)
        );
        newPages.forEach((newPage) => {
          void loadPage(newPage);
        });

        pagesInView.current = currentPagesInView;

        setPagesInViewport(currentPagesInView);
      }, DATA_TABLE_PAGINATION_DEBOUNCE_TIME),
    [
      dataNavigationMode,
      totalPages,
      rowHeight,
      getRowsPerPage,
      setPagesInViewport,
      setScroll,
      loadPage
    ]
  );

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: dataOrder.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => rowHeight,
    onChange: calculatePagesInView,
    getItemKey: (index) => rows[index].id,
    // Add the header height to the padding end so the last row doesn't get cut off and a margin bottom
    paddingEnd:
      (tableHeaderRef.current?.getBoundingClientRect().height || 0) + DATA_TABLE_MARGIN_BOTTOM
  });

  useEffect(() => {
    setRowVirtualizer(rowVirtualizer);
  }, [rowVirtualizer, setRowVirtualizer]);

  useEffect(() => {
    if (!isInitialLoad && tableContainerRef.current && scroll) {
      tableContainerRef.current?.scrollTo({ top: scroll });
    }
    // We don't want to load this effect when each scroll change, only on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoad]);

  // Block navigating elsewhere when we have rows in draft mode
  const routerBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      draftRowsKeys.length > 0 && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    const confirmExit = (event) => {
      if (draftRowsKeys.length > 0) {
        event.preventDefault();
        return true;
      }
      return null;
    };
    window.addEventListener('beforeunload', confirmExit);

    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [draftRowsKeys]);

  // URL params like sorting will persist in local storage
  usePersistUrlParams();

  return (
    <div className="flex h-full flex-1 flex-col pl-6 text-sm">
      <div
        ref={tableContainerRef}
        className="relative flex-1 overflow-auto"
        data-testid="data-table"
        // This id is necessary so the floating portals know where to render. It allows the portals to use this element's boundaries instead of the ones from the document body
        id={DATA_TABLE_FLOATING_ID}
        style={columnSizeVars}
        data-virtualizer-container
      >
        <div className="w-max" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          <div
            data-testid="data-table-header"
            className="sticky top-0 z-10 bg-default pr-6"
            ref={tableHeaderRef}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <div
                className="flex [&>:first-child>*]:rounded-tl-lg [&>:last-child>*]:rounded-tr-lg"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => (
                  <div
                    key={header?.id}
                    className="h-8 bg-base font-medium"
                    style={{
                      ...getColumnStyles(header.column),
                      width: `calc(var(--header-${header?.id}-size) * 1px)`
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>{flexRender(header.column.columnDef.header, header.getContext())} </>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <MemoizedFloatingCell
            tableHeaderRef={tableHeaderRef}
            table={table}
            rowVirtualizer={rowVirtualizer}
          />

          <div data-testid="data-table-rows" role="grid" className="relative pr-6">
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <div
                  className="absolute flex [&>*:first-child>*]:border-l"
                  key={virtualRow.index}
                  data-testid={`data-table-row-${row.id}`}
                  style={{
                    height: `${virtualRow.size}px`,
                    top: `${virtualRow.start}px`
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <div
                      // IMPORTANT: If css changes the height of this element, the virtualiser calculations and the inline edit position may break
                      className={cn('flex', {
                        // Add the opacity to children instead of the element so the sticky column don't show things behind
                        '[&>*>*]:opacity-60 ':
                          draftRowsKeys.length > 0 && !draftRowsKeys.includes(row.id)
                      })}
                      key={cell.id}
                      style={{
                        height: virtualRow.size,
                        width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
                        ...getColumnStyles(cell.column)
                      }}
                    >
                      <div className="min-w-0 flex-1 border border-l-0 border-t-0 border-subtle bg-base">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          <FilterToast />
        </div>
        {filters && isFetchingPages && (
          <div className="sticky flex w-full items-center justify-center p-4">
            <Spinner />
          </div>
        )}
        {filters && !isFetchingPages && dataOrder.length === 0 && (
          <p className="sticky left-0 right-0 p-4 text-center">
            {t('components.data_table.filtering.no_records_match_filter')}
          </p>
        )}
      </div>
      <TableFooter />
      {routerBlocker.state === 'blocked' ? (
        <Dialog open onOpenChange={() => routerBlocker.reset()}>
          <Dialog.Content data-testid="unsaved-changes-modal">
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {t('components.data_table.unsaved_changes_modal.title')}
                </Dialog.Title>
              </Dialog.Header>
              <div className="mt-6">
                <div>
                  <p>{t('components.data_table.unsaved_changes_modal.description')}</p>
                </div>
              </div>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Button
                intent="minimal"
                onClick={() => routerBlocker.proceed()}
                data-testid="unsaved-changes-dont-save"
              >
                {t('components.data_table.unsaved_changes_modal.dont_save')}
              </Button>
              <Button onClick={() => routerBlocker.reset()} data-testid="unsaved-changes-continue">
                {t('components.data_table.unsaved_changes_modal.continue')}
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      ) : null}
    </div>
  );
}
