import { Trans } from 'react-i18next';

import { type ReportView } from '@/types/schema/views/ReportView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function ReportTableInfo({ view }: { view: ReportView }) {
  const { data: app } = useApplicationQuery();
  const knackTable = app?.objects.find((object) => object.key === view.source.object);

  return (
    <div className="mb-6 rounded-lg bg-subtle p-2">
      <Trans
        i18nKey="pages.element_settings.report.categories.general.table"
        values={{
          tableName: knackTable?.name
        }}
      >
        <span className="font-semibold">tableName</span>
      </Trans>
    </div>
  );
}
