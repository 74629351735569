import { useMemo, useState, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@knack/asterisk-react';
import { type editor } from 'monaco-editor';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { FieldIcon } from '@/components/FieldIcon';

// Create a new type for fields with a modified key (e.g. to allow keys like field_123.field_456) to be used in equations
type KnackFieldForEquation = Omit<KnackField, 'key'> & {
  key: `field_${string}` | `field_${string}.field_${string}`;
};

// Equation allowed fields, converted to numeric values to make the calculations
const isFieldTypeValidForEquation = (fieldType: KnackFieldType) =>
  fieldType === 'number' ||
  fieldType === 'currency' ||
  fieldType === 'rating' ||
  fieldType === 'auto_increment' ||
  fieldType === 'multiple_choice' ||
  fieldType === 'boolean' ||
  fieldType === 'date_time' ||
  fieldType === 'sum' ||
  fieldType === 'min' ||
  fieldType === 'max' ||
  fieldType === 'average' ||
  fieldType === 'count';

const isFieldTypeValidForTextFormula = (fieldType: KnackFieldType) =>
  fieldType === 'address' ||
  fieldType === 'boolean' ||
  fieldType === 'concatenation' ||
  fieldType === 'date_time' ||
  fieldType === 'email' ||
  fieldType === 'equation' ||
  fieldType === 'link' ||
  fieldType === 'multiple_choice' ||
  fieldType === 'name' ||
  fieldType === 'paragraph_text' ||
  fieldType === 'phone' ||
  fieldType === 'rating' ||
  fieldType === 'rich_text' ||
  fieldType === 'short_text' ||
  fieldType === 'timer';

export function FieldSelector({
  editorRef,
  field
}: {
  editorRef: MutableRefObject<editor.IStandaloneCodeEditor | undefined>;
  field: KnackField;
}) {
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const { data: app } = useApplicationQuery();

  const objectKey = useDataTableStore().use.objectKey();

  const table = app?.objects?.find((obj) => obj?.key === objectKey);

  const isFieldTypeValid = useMemo(() => {
    if (field.type === 'equation') {
      return isFieldTypeValidForEquation;
    }

    if (field.type === 'concatenation') {
      return isFieldTypeValidForTextFormula;
    }

    return () => false;
  }, [field.type]);

  if (!table) {
    throw new Error('Table not found');
  }

  // We want to merge the fields of this table with the fields of the connected tables
  // We change the key and name in the way these are displayed in the equation and save in the database
  // {Connection FieldConnector > FieldName}
  // {field_1.field_20}
  const fieldList = useMemo(() => {
    const fields = table.fields.filter(
      (currentField) => isFieldTypeValid(currentField.type) && currentField.key !== field.key
    );

    const connections = [
      ...(table?.connections.outbound.filter((connection) => connection.has !== 'many') || []),
      ...(table?.connections.inbound || [])
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const connection of connections) {
      const connectedTable = app?.objects?.find((obj) => obj?.key === connection.object);
      if (!connectedTable) {
        break;
      }
      fields.push(
        ...connectedTable.fields
          .filter((currentField) => isFieldTypeValid(currentField.type))
          .map((currentField) => {
            const fieldKey: KnackFieldForEquation['key'] = `${connection.key}.${currentField.key}`;

            return {
              ...currentField,
              name: `${t('components.data_table.right_sidebar.connections.connection')} ${connection.name} > ${currentField.name}`,
              key: fieldKey
            };
          })
      );
    }

    return fields;
  }, [
    app?.objects,
    field.key,
    t,
    table?.connections.inbound,
    table?.connections.outbound,
    table.fields,
    isFieldTypeValid
  ]);

  const filteredList = useMemo(
    () =>
      fieldList.filter(
        (currentField) =>
          currentField.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          currentField.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          currentField.key.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [fieldList, searchTerm]
  );

  return (
    <>
      <div className="p-2">
        <p className="p-2 text-xs">
          {t('components.data_table.attributes.field_settings.rich_text_fields.insert_template')}
        </p>
        <Input
          className="m-0 w-full min-w-0 rounded-none border-0 border-b-2 border-b-subtle text-sm focus:outline-0"
          placeholder={t('components.data_table.select.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="max-h-[200px] max-w-[320px] overflow-auto p-2 pr-0 pt-0 ">
        <div className="flex flex-col">
          {filteredList.length === 0 && (
            <p className="p-2 text-sm text-default">
              {t(
                'components.data_table.attributes.field_settings.rich_text_fields.template_not_found'
              )}
            </p>
          )}
          {filteredList.map((currentfield) => (
            <Button
              key={currentfield.key}
              intent="minimal"
              className="mr-2 flex h-auto flex-col p-2 text-left font-normal"
              onClick={(e) => {
                e.preventDefault();

                const selection = editorRef.current?.getSelection();
                const id = { major: 1, minor: 1 };
                const op = {
                  identifier: id,
                  range: {
                    startLineNumber: selection?.selectionStartLineNumber || 1,
                    startColumn: selection?.selectionStartColumn || 1,
                    endLineNumber: selection?.endLineNumber || 1,
                    endColumn: selection?.endColumn || 1
                  },
                  text: `{${currentfield.name}}`,
                  forceMoveMarkers: true
                };
                editorRef.current?.executeEdits('my-source', [op]);
                editorRef.current?.focus();
              }}
            >
              <div className="mb-0.5 flex w-full justify-between">
                <p>{currentfield.name}</p>
                <p className="flex text-sm text-subtle">
                  <FieldIcon name={currentfield.type} size={12} className="m-1" />
                  {t(`attributes.field_types.${currentfield.type}`)}
                </p>
              </div>
            </Button>
          ))}
        </div>
      </div>
    </>
  );
}
