import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import {
  blankOperators,
  containsOperators,
  dateTimeOperators,
  defaultOperators,
  isAnyOperators,
  isOperators,
  numberOperators,
  stringOperators,
  timeOperators
} from '@/types/schema/KnackOperator';

export function useFieldHelpers() {
  // Check if the field can store values that can be used as dates
  const canFieldStoreDateValues = (field: KnackField) => {
    if (field.type === 'date_time') {
      return true;
    }

    // Check if the field is equation, and it's formatted to store dates
    if (
      field.type === 'equation' &&
      field.format.equation_type === 'date' &&
      field.format.date_result === 'date'
    ) {
      return true;
    }

    return false;
  };

  const getBaseFieldOperators = (field: KnackField) => {
    if (canFieldStoreDateValues(field)) {
      // For date/time fields that just store times, only show time operators
      if (field.type === 'date_time' && field.format.date_format === 'Ignore Date') {
        return timeOperators;
      }

      // Otherwise show date/time operators
      return dateTimeOperators;
    }

    if (field.type === 'address') {
      // TODO: if app has geocoding feature enabled, include geocoding operators. Implement once https://github.com/knackhq/builder/pull/966 is merged
      // const appHasSupportForGeocoding = application.settings.geo || application.account.productPlan.level > 1 || application.account.productPlan === 'trial';
      // const fieldHasGeocodingEnabled = !!field.format.enable_geocoding;
      // if (appHasSupportForGeocoding && fieldHasGeocodingEnabled) {
      //   return [...addressOperators, ...geocodingOperators];
      // }

      return defaultOperators;
    }

    switch (field.type) {
      case 'short_text':
      case 'paragraph_text':
      case 'rich_text':
      case 'concatenation':
      case 'name':
      case 'email':
      case 'phone':
        return defaultOperators;

      case 'number':
      case 'currency':
      case 'equation':
      case 'auto_increment':
      case 'sum':
      case 'min':
      case 'max':
      case 'average':
      case 'count':
      case 'timer':
      case 'rating':
        return numberOperators;

      case 'boolean':
        return [...isOperators, ...blankOperators];

      case 'multiple_choice':
      case 'connection':
      case 'user_roles':
        return [...isOperators, ...containsOperators, ...isAnyOperators, ...blankOperators];

      case 'file':
      case 'image':
      case 'signature':
        return [...blankOperators];

      case 'link':
        return [...blankOperators, ...isOperators];

      case 'password':
        return [...containsOperators, ...stringOperators];

      default:
        return [];
    }
  };

  const getOperatorsForField = (fieldKey: KnackFieldKey, fields: KnackField[]) => {
    if (fields.length === 0) {
      return [];
    }

    const selectedField = fields.find((field) => field.key === fieldKey);
    return selectedField ? getBaseFieldOperators(selectedField) : [];
  };

  return { getBaseFieldOperators, getOperatorsForField };
}
