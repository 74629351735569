import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type TableView } from '@/types/schema/views/TableView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ColumnSummaryDialogItem } from '@/pages/pages/settings-panel/view-settings/table/data-display/ColumnSummaryDialogItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

function ColumnSummariesDialogContent({
  setIsDialogOpen
}: {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [beingDraggedSummaryId, setBeingDraggedSummaryId] = useState<string | null>(null);

  const columnSummariesSchema = z.object({
    totals: z.array(
      z.object({
        label: z.string().min(1, t('errors.value_required')),
        calc: z.string().min(1, t('errors.value_required'))
      })
    )
  });
  type ColumnSummariesSchemaType = z.infer<typeof columnSummariesSchema>;

  const form = useForm<ColumnSummariesSchemaType>({
    resolver: zodResolver(columnSummariesSchema),
    defaultValues: {
      totals: view.totals || []
    },
    mode: 'onChange'
  });

  const {
    fields: columnSummaries,
    append: addNewSummary,
    move: moveSummary,
    remove: removeSummary
  } = useFieldArray({
    control: form.control,
    name: 'totals'
  });

  const shouldDisableAddButton = columnSummaries.length >= 4;

  const onApplyColumnSummaries = (data: { totals: TableView['totals'] }) => {
    updateViewSchema({
      totals: data.totals
    });

    setIsDialogOpen(false);
  };

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedSummaryId(event.active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!columnSummaries) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columnSummaries.findIndex((sum) => sum.id === active.id) ?? -1;
      const newIndex = columnSummaries.findIndex((sum) => sum.id === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      moveSummary(oldIndex, newIndex);

      setBeingDraggedSummaryId(null);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onApplyColumnSummaries)}>
        <Dialog.MainContent>
          <div className="mb-4">
            <Dialog.Title className="font-normal">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.column_summaries'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.add_summary_description'
              )}
            </Dialog.Description>
          </div>
          <DndContext
            sensors={optimizedSensors}
            collisionDetection={verticalListCollisionDetection}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={columnSummaries.map((sum) => sum.id)}
              strategy={verticalListSortingStrategy}
            >
              <div className="mt-4 space-y-2">
                {columnSummaries.map((summary, index) => (
                  <VerticalListSortableItem key={summary.id} id={summary.id}>
                    <ColumnSummaryDialogItem
                      summary={summary}
                      columnSummaryIndex={index}
                      enabledOptions={columnSummaries.map((s) => s.calc)}
                      removeColumnSummary={removeSummary}
                    />
                  </VerticalListSortableItem>
                ))}
              </div>
            </SortableContext>
            <DragOverlay>
              {columnSummaries.map((columnSummary, summaryIndex) => {
                if (columnSummary.id !== beingDraggedSummaryId) {
                  return null;
                }

                return (
                  <ColumnSummaryDialogItem
                    key={columnSummary.id}
                    summary={columnSummary}
                    columnSummaryIndex={summaryIndex}
                    enabledOptions={columnSummaries.map((s) => s.calc)}
                    removeColumnSummary={removeSummary}
                  />
                );
              })}
            </DragOverlay>
          </DndContext>
          <Button
            intent="minimal"
            className="gap-1"
            disabled={shouldDisableAddButton}
            onClick={() => addNewSummary({ label: '', calc: '' })}
          >
            <Button.Icon icon={AddIcon} />
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.column_summary'
            )}
          </Button>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="secondary" className="gap-1">
              {t('actions.cancel')}
            </Button>
          </Dialog.Close>
          <Button intent="primary" className="gap-1" type="submit">
            {t('actions.apply')}
          </Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function ColumnSummariesDialog() {
  const [t] = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary" className="gap-1" size="sm">
          <Button.Icon icon={EditIcon} />
          {t(
            'pages.element_settings.common.categories.data_display.general_settings.column_summaries'
          )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <ColumnSummariesDialogContent setIsDialogOpen={setIsDialogOpen} />
      </Dialog.Content>
    </Dialog>
  );
}
